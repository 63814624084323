import UserState from "../users/UserState";
import resultContext from "./ExamResultsContext";
import { useState } from "react";
import axios from "axios";

const ExamResultState = (props) => {

  const [ExamResultsData, setExamResultsData] = useState([]);
  //const [resultSendMessage, setResultSendMessage] = useState([]);
  const [ExamName, setExamName] = useState([]);

  const [Data, setData] = useState([]);

  // Get examresult details

  const getexamwiseresultdetails = async (e) => {
    const response = await fetch(`/api/examwiseresultsdata/fetchallexamtypesresults/${e}`,
      {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "auth-token": sessionStorage.getItem("token"),
        },
      }
    );
    const json = await response.json();
    setExamName({ name: json.examName, total: json.totalResults, fail: json.totalFail, pass: json.totalPass })
    setExamResultsData(json.data);
  };


  // Get examresult details
  const getexportdataexam = async (e) => {
    const response = await fetch(`/api/examwiseresultsdata/fetchallexamtypesresults/${e}`,

      {

        method: "GET",

        headers: {

          "Cache-Control": "no-cache",

          "Content-Type": "application/json",

          "auth-token": sessionStorage.getItem("token"),

        },

      }

    );

    const json = await response.json();



    setData(json.data);



  };







  return (

    <resultContext.Provider

      value={{

        getexamwiseresultdetails,
        ExamResultsData,
        Data,
        ExamName,
        getexportdataexam,
        // sendResultToCandidates,
        //resultSendMessage
      }}

    >

      {props.children}

    </resultContext.Provider>

  );

};

export default ExamResultState;

