import examContext from "./ExamContext";
import { useState } from "react";
import axios from 'axios';
const ExamState = (props) => {

	const examInitial = [];

	const [activeExams, setActiveExams] = useState(examInitial);
	const [inActiveExams, setInactiveExams] = useState(examInitial);
	const [pendingExamsList, setPendingExamsData] = useState([]);
	const [QuestionReportList, setQuestionReportList] = useState([]);
	const [exam, setExam] = useState([]);

	const [StudentData, setStudentData] = useState({ name: '', phone: '', email: '', pass: '', fail: '', total_result: '' })

	const [StudentResult, setStudentresult] = useState(examInitial);

	// Get getResult

	const getExams = async () => {

		// API Call

		const response = await fetch(`/api/exam/fetchallexamtype`, {

			method: "GET",

			headers: {

				"Cache-Control": "no-cache",

				"Content-Type": "application/json",

				"auth-token": sessionStorage.getItem("token"),

			},

		});

		const json = await response.json();
		setActiveExams(json.active)
		setInactiveExams(json.inActive)

	};



	const getStudentresult = async (e) => {
		const response = await fetch(`/api/examdetails/stuexamdetails/${e}`, {
			method: "GET",
			headers: {
				"Cache-Control": "no-cache",
				"Content-Type": "application/json",
				"auth-token": sessionStorage.getItem("token"),

			},
		});
		const json = await response.json();
		setStudentresult(json.result[0].responseanswer);
		setStudentData({ ...StudentData, name: json.result[0].name, phone: json.result[0].phone, email: json.result[0].email, pass: json.result[0].pass, fail: json.result[0].fail, total_result: json.result[0].pass + json.result[0].fail })


	}



	// Delete a Note

	const deleteExam = async (id) => {

		// API Call

		const response = await fetch(`/api/exam/deleteExam/${id}`, {

			method: "DELETE",

			headers: {

				"Content-Type": "application/json",

				"auth-token": sessionStorage.getItem("token"),

			},

		});

		const json = await response.json();

		if (json) {

			getExams();

		}





	};



	const getReportList = async (answer_id) => {

		const Result = await axios.get(`/api/examdetails/exportuseranswerdata/${answer_id}`)

		setQuestionReportList(Result.data)

		//setExamQuestionReport(Result.data.result);

		//setModeldataResultScore(Result.data.marksobtained);

		//setModeldataResultPercentage(Result.data.percentage);

		// setModeldataResultD(Result.data.resultexam);

	}

	const getPendingExamsList = async () => {
		axios
			.get("/api/exam/getallpendingexams")
			.then(function (response) {
				console.log(response.data.data)
				setPendingExamsData(response.data.data)

			});

	}


	return (

		<examContext.Provider

			value={{

				activeExams,
				inActiveExams,
				getExams,
				deleteExam,
				getStudentresult,
				getReportList,
				QuestionReportList,
				StudentResult,
				StudentData,
				getPendingExamsList,
				pendingExamsList,

			}}

		>

			{props.children}

		</examContext.Provider>

	);

};

export default ExamState;

