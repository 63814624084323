import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist"
import axios from "axios";
const EditAdminPassword = (props) => {
  let navigate = useNavigate();
  const [adminCredential, setadminCredential] = useState({ old_password: "", new_password: "", confirm_password: "" });

  // const getAdminDetails = (Id) => {
  const [passwordValidate, setPasswordValidate] = useState(false)
  //   axios.get(`api/auth/getAdminDataById/${Id}`)
  //     .then(function (response) {
  //       // Handle successful response
  //       setadminCredential({ ...adminCredential, email: response.data.data.email, password: '' });
  //       //console.log('User data:', adminCredential);
  //     })
  //     .catch(function (error) {
  //       // Handle error
  //       console.error('Error fetching user data:', error);
  //     });
  // }


  useEffect(() => {

    if (

      sessionStorage.getItem("token") === null ||

      sessionStorage.getItem("user-type") === "admin"

    ) {

      const adminId = sessionStorage.getItem("admin-id");


    } else {

      navigate("/");

    }


  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(passwordValidate)
    if (adminCredential.old_password == '') {
      props.showAlert('Please enter your old password first', 'danger');
    }
    else if (passwordValidate === false) {
      props.showAlert('Please validate your new password', 'danger');
    } else {
      const Id = sessionStorage.getItem("admin-id");
      // Send a POST request
      axios({
        method: 'post',
        url: `api/auth/updateAdminPassword/${Id}`,
        data: {
          old_password: adminCredential.old_password,
          new_password: adminCredential.new_password,
          confirm_password: adminCredential.confirm_password,
        }
      })
        .then(function (response) {
          //console.log(response.data.success)

          if (response.data.success === true) {
            props.showAlert(response.data.message, 'suceess');
          } else {

            props.showAlert(response.data.message, 'danger');
          }

        })
        .catch(function (error) {
          // Handle error
          console.error('Error fetching user data:', error);
        });
    }
  };

  const onChange = (e) => {

    setadminCredential({ ...adminCredential, [e.target.name]: e.target.value });

  };

  const passWordCheck = (e) => {
    //console.log(e)
    if (e === true) {
      setPasswordValidate(true);
    } else {
      setPasswordValidate(false);
    }
  }

  return (


    <>
      <div className="admin_profile_wraper py-5">
        <div className="main_wraper_pages p-15">
          <form onSubmit={handleSubmit}>
            <div className="form_fields mb-4">
              <label className="form_label">Old Password</label>
              <input type="password" id="old_password" value={adminCredential.old_password} onChange={onChange} name="old_password" />
            </div>
            <div className="form_fields mb-4">
              <label className="form_label">New Password</label>
              <input type="password" id="new_password" value={adminCredential.new_password} onChange={onChange} name="new_password" />
            </div>
            <div className="form_fields mb-4 confirm_pass">
              <label className="form_label">Confirm Password</label>
              <input type="password" id="confirm_password" className="mb-2" value={adminCredential.confirm_password} onChange={onChange} name="confirm_password" />
              <PasswordChecklist rules={["minLength", "specialChar", "number", "capital", "match"]} minLength={5} value={adminCredential.new_password} valueAgain={adminCredential.confirm_password} onChange={passWordCheck} />
            </div>
            <div className="form_fields">
              <button type="submit" className="submit_btn btn_cs sky_bg_btn w-100 justify-content-center">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );

};



export default EditAdminPassword;

