import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import userContext from "../context/users/UserContext";
import { Table, Input, Modal, DatePicker, Popconfirm } from "antd";
import filter_icon from "../more-filters.png";
import * as moment from "moment";

const { RangePicker } = DatePicker;

const UsersList = (props) => {
  let navigate = useNavigate();
  const context = useContext(userContext);
  var selecteddates = [];
  const { Users, UsersInactive, getUsers, editUsers, deleteUser } = context;
  const [DataInfoEdit, setDataInfoEdit] = useState("");
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [filterTable, setFilterTable] = useState(null);
  const [filterTab, setFilterTab] = useState("active");
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (
      sessionStorage.getItem("token") === null ||
      sessionStorage.getItem("user-type") === "admin"
    ) {
      getUsers();
    } else {
      navigate("/");
    }

    // eslint-disable-next-line
  }, []);

  const [userss, setUserss] = useState({
    id: "",
    name: "",
    status: "",
    email: "",
    phone: "",
  });

  const onTableSearch = (e) => {
    console.log(e);
    let filterTable;
    if (filterTab === "active") {
      filterTable = Users.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      filterTable = UsersInactive.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }

    setFilterTable(filterTable);
  };

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChangetable = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "age",
    });
  };


  const confirmdelete = (id) => {

    deleteUser(id);
    props.showAlert("Deleted successfully", "success");

  };
  const canceldelete = (e) => {

  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "no",
      key: "no",
      width: "6%",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "15%",
      filteredValue: filteredInfo.phone || null,
      onFilter: (value, record) => record.phone.includes(value),
      sorter: (a, b) => a.phone.length - b.phone.length,
      sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
      ellipsis: true,
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      filteredValue: filteredInfo.email || null,
      onFilter: (value, record) => record.email.includes(value),
      sorter: (a, b) => a.email.length - b.email.length,
      sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
      ellipsis: true,
    },

    {
      title: "Exam Given",
      dataIndex: "exam_total",
      key: "exam_total",
      width: "10%",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "10%",
      filteredValue: filteredInfo.date || null,
      onFilter: (value, record) => record.date.includes(value),
      sorter: (a, b) => moment(a.date, 'DD-MM-YYYY').unix() - moment(b.date, 'DD-MM-YYYY').unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      ellipsis: true,
    },

    {
      title: "Action",
      button: true,
      align: "-webkit-center",
      render: (data) => (
        <>
          <div className="action_dropdown dropdown dropstart ">
            <a
              className="action_toggle dropdown-toggle "
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </a>
            <ul className="dropdown-menu">
              <li>
                <a onClick={() => editdatahandler(data)}>
                  <i className="fa-regular fa-pen-to-square"></i>Edit
                </a>
              </li>
              <li>


                <Popconfirm
                  title="Do you really want to delete this exam?"
                  description={data.name}
                  onConfirm={() => { confirmdelete(data.id) }}
                  onCancel={canceldelete}
                  okText="Confirm Delete"
                  cancelText="Don't Delete"
                >
                  <li><a ><i className="fa-solid fa-trash-can"></i>Delete</a></li>
                </Popconfirm>

              </li>
            </ul>
          </div>
        </>
      ),

      width: "10%",
    },
  ];

  const editdatahandler = (data) => {
    setDataInfoEdit(data);
    setIsModalOpenEdit(true);
    setUserss({
      ...userss,
      id: data.id,
      name: data.name,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      status: data.cStatus,
      location: data.location,
      center: data.center,
    });
  };

  const handleOkEdit = () => {
    editUsers(
      userss.id,
      userss.name,
      userss.firstname,
      userss.lastname,
      userss.email,
      userss.location,
      userss.center,
      userss.status
    );

    setIsModalOpenEdit(false);
    props.showAlert("Updated Successfully", "success");
    //setIsModalOpenEdit(false);
  };

  const handleCancelEdit = () => {
    setIsModalOpenEdit(false);
    //setDataInfoEdit('Abhi');
  };

  const onChange = (e) => {
    setUserss({ ...userss, [e.target.name]: e.target.value }); //whatever value inside the ques object will exist as it is but jo properties aage likhi ja rhi hai inko add ya overwrite kar dena
  };

  const handleChange = (e) => {
    setUserss({ ...userss, status: e.target.value }); //whatever value inside the ques object will exist as it is but jo properties aage likhi ja rhi hai inko add ya overwrite kar dena
  };

  const filterHander = (e) => {
    setFilterTab(e);
    let filterTable;
    if (e === "active") {
      filterTable = Users;
    } else {
      filterTable = UsersInactive;
    }

    setFilterTable(filterTable);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,

    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>User List</h2>
        </div>

        <div className="board_form_filter d-flex gap-3 align-items-center">
          <div className="mobile_all_filters d-block d-sm-none">
            <div className="borad_more_filters dropdown remove_cret">
              <a
                className="more_filters dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={filter_icon} alt="filters" />
              </a>
              <ul className="dropdown-menu">
                <li className="mobile_serch">
                  <div className="borad_srch_form">
                    <Input.Search
                      placeholder="Search"
                      enterButton
                      onChange={onTableSearch}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wraper_pages p-15 pt-0 mb-5">
        <div className="tabs_srch_form d-flex justify-content-between">
          <div className="data_tabs_pagination d-flex justify-content-between align-items-center border_bt_gray w-100">
            <nav>
              <div className="data_ac_tabs" id="data_ac_tabs" role="tablist">
                <a
                  className="tab_item active"
                  data-bs-toggle="tab"
                  data-bs-target="#active_data"
                  aria-controls="nav-home"
                  aria-selected="true"
                  onClick={() => filterHander("active")}
                >
                  Active
                </a>
                <a
                  className="tab_item"
                  data-bs-toggle="tab"
                  onClick={() => filterHander("inactive")}
                >
                  Inactive
                </a>
              </div>
            </nav>
            <div className="record_search_fm_totl m-0">
              <span className="data_total total_record">
                {" "}
                {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
              </span>
              <span className="data_total total_record">
                Total: {filterTable ? filterTable.length : Users.length}
              </span>
              <div className="borad_srch_form d-none d-sm-block center">
                <Input.Search
                  placeholder="Search"
                  enterButton
                  onChange={onTableSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tab-content filter_custom" id="data-tabContent">
          <div
            className="tab-pane show active"
            id="active_data"
            role="tabpanel"
          >
            <div className="data_table same_table_als mt-3"></div>
          </div>
          <div className="tab-pane" id="inactive_data" role="tabpanel">
            <div className="data_table same_table_als mt-3"></div>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filterTable ? filterTable : Users}
            pagination={{
              onChange(current) {
                setPage(current);
              },
            }}
            onChange={handleChangetable}
          />
        </div>
      </div>

      {/* Model edit */}

      <Modal
        title={"Edit User Details"}
        open={isModalOpenEdit}
        onOk={handleOkEdit}
        cancelText="Cancel"
        okText="Submit"
        onCancel={handleCancelEdit}
        width={600}
      >
        <div className="signup_form add_question_form popup_form">
          <div className="qs_form row">
            <div className="form_fields col-md-6 mb-3">
              <label htmlFor="title" className="form_label">
                First name
              </label>
              <input
                type="text"
                name="firstname"
                defaultValue={DataInfoEdit.firstname}
                onChange={onChange}
                minLength={2}
                key={DataInfoEdit.firstname}
                required
                placeholder="Enter the first name"
              />
            </div>
            <div className="form_fields col-md-6 mb-3">
              <label htmlFor="title" className="form_label">
                last name
              </label>
              <input
                type="text"
                name="lastname"
                defaultValue={DataInfoEdit.lastname}
                onChange={onChange}
                minLength={2}
                key={DataInfoEdit.last}
                required
                placeholder="Enter the first name"
              />
            </div>
            <div className="form_fields col-md-6 mb-3">
              <label htmlFor="tag" className="form_label">
                Phone
              </label>
              <input
                type="text"
                key={DataInfoEdit.phone}
                name="phone"
                defaultValue={DataInfoEdit.phone}
                onChange={onChange}
                disabled="true"
                minLength={10}
                required
                placeholder="Enter the phone"
              />
            </div>
            <div className="form_fields col-md-6 mb-3">
              <label htmlFor="tag" className="form_label">
                Email
              </label>
              <input
                type="text"
                key={DataInfoEdit.email}
                name="email"
                defaultValue={DataInfoEdit.email}
                onChange={onChange}
                minLength={5}
                required
                placeholder="Enter the email"
              />
            </div>

            <div className="form_fields mb-3">
              <label htmlFor="title" className="form_label">
                Status
              </label>
              <select name="status" onChange={handleChange}>
                <option value="">--Please choose an Status--</option>
                <option
                  selected={DataInfoEdit.cStatus === 1 ? true : false}
                  value="1"
                >
                  Active
                </option>
                <option
                  selected={DataInfoEdit.cStatus === 0 ? true : false}
                  value="0"
                >
                  Inactive
                </option>
              </select>
            </div>
            <div className="form_fields mb-3">
              <label htmlFor="User_id" className="form_label">
                User id
              </label>
              <input
                type="text"
                key={DataInfoEdit.id}
                defaultValue={DataInfoEdit.id}
                disabled
                required
                placeholder="Enter the email"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UsersList;
