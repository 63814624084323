import React, { useEffect, useRef, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import quesContext from "../context/ques/QuesContext";
import CatesContext from "../context/cats/CatesContext";
import filter_icon from "../more-filters.png";
import { Table, Input, Popconfirm } from 'antd';
import moment from 'moment';

const QuestionsList = (props) => {
	let currentPackage = 0;
	let navigate = useNavigate();
	const context = useContext(quesContext);
	const Contextcategories = useContext(CatesContext);
	const { quess, quessInactive, getQuess } = context;
	const { catesActive, catesInActive, getCates } = Contextcategories;
	const [currentTab, setCurrentTab] = useState('active');
	const { deleteQues } = context;
	const [filterTable, setFilterTable] = useState(null);
	const [page, setPage] = useState(1);
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		if (
			sessionStorage.getItem("token") === null ||
			sessionStorage.getItem("user-type") === "admin"
		) {
			getQuess();
			getCates();
		} else {
			navigate("/");
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setTableData(quess);
	}, [quess]);

	currentPackage = parseInt(sessionStorage.getItem('adminPlanId'));
	const form = useRef(null);

	const submit = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		const fileInput = form.current.uploadfile;
		if (fileInput.files.length === 0) {
			props.showAlert("Please select a file.", "danger");
			return;
		}
		formData.append('uploadfile', fileInput.files[0]);
		formData.append('currentPackage', currentPackage);
		const response = await fetch('/api/ques/uploadExcelFile', {
			method: 'POST',
			headers: { "Cache-Control": "no-cache" },
			body: formData
		});
		const json = await response.json();
		if (json.success === true) {
			props.showAlert("Data imported successfully.", "success");
			getQuess();
		} else {
			getQuess();
			props.showAlert("There are some issues, please check your data.", "danger");
		}
	};

	let CategoryArray;
	if (currentTab === 'active') {
		CategoryArray = catesActive.map((val) => ({ text: val['name'], value: val['name'] }));
	} else {
		CategoryArray = catesInActive.map((val) => ({ text: val['name'], value: val['name'] }));
	}


	const onTableSearch = (e) => {
		let filterTable = tableData.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
			)
		);
		setFilterTable(filterTable);

	};

	const [filteredInfo, setFilteredInfo] = useState({});
	const [sortedInfo, setSortedInfo] = useState({});
	const handleChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
	};

	const confirmdelete = (id) => {
		deleteQues(id);
		props.showAlert("Deleted successfully", "success");
	};

	const canceldelete = (id) => { };

	function getFirstFiveWords(input) {
		if (!input) return '';
		const words = input.split(' ');
		return words.slice(0, 5).join(' ');
	}

	const columns = [
		{
			title: 'S.No',
			dataIndex: 'no',
			key: 'no',
			width: '5%',
			render: (value, item, index) => (page - 1) * 10 + index + 1
		},
		{
			title: 'Questions',
			dataIndex: 'title',
			key: 'title',
			width: '34%',
			filteredValue: filteredInfo.title || null,
			onFilter: (value, record) => record.title.includes(value),
			sorter: (a, b) => a.title.length - b.title.length,
			sortOrder: sortedInfo.columnKey === 'title' ? sortedInfo.order : null,
			ellipsis: true,
		},
		{
			title: 'Answer',
			dataIndex: 'show_ans',
			key: 'show_ans',
			width: '20%',
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category',
			width: '15%',
			filterSearch: true,
			filters: CategoryArray,
			onFilter: (value, record) => record.category.indexOf(value) === 0,
			filteredValue: filteredInfo.category || null,
		},
		{
			title: 'Created Date',
			dataIndex: 'date',
			key: 'date',
			filteredValue: filteredInfo.date || null,
			onFilter: (value, record) => record.date.includes(value),
			sorter: (a, b) => moment(a.date, 'DD-MM-YYYY').unix() - moment(b.date, 'DD-MM-YYYY').unix(),
			sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
			ellipsis: true,
			width: '15%',
		},
		{
			title: 'Action',
			button: true,
			align: '-webkit-center',
			render: (data) => (
				<>
					<div className="action_dropdown dropdown dropstart">
						<a className="action_toggle dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<i className="fa-solid fa-ellipsis-vertical"></i>
						</a>
						<ul className="dropdown-menu">
							<li>
								<Link to={`/editquestions/${data.id}`}><i className="fa-regular fa-pen-to-square"></i>Edit</Link>
							</li>
							<li>
								<Popconfirm
									title="The question can be part of existing exam ( categories included). Ensure to add another question in same category if needed. Confirm below to delete this question? "
									onConfirm={() => { confirmdelete(data.id) }}
									onCancel={canceldelete}
									okText="Confirm Delete"
									cancelText="Don't Delete"
								>
									<a><i className="fa-solid fa-trash-can"></i>Delete</a>
								</Popconfirm>
							</li>
						</ul>
					</div>
				</>
			),
			width: '10%',
		},
	];

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const hasSelected = selectedRowKeys.length > 0;

	const inactiveTab = () => {
		setFilterTable();
		setCurrentTab('inactive')
		setTableData(quessInactive);
	};

	const activeTab = () => {
		setFilterTable();
		setCurrentTab('active')
		setTableData(quess);
	};

	const handleSubmit = (e) => {
		// You can now use formData to upload the file to a server or perform other actions.
	};

	return (
		<>
			<div className="board_title_data d-flex align-items-center justify-content-between gap-3">
				<div className="board_title d-flex align-items-center gap-3">
					<h2>Questions</h2>
				</div>
				<div className="board_form_filter d-flex gap-3 align-items-center">
					<div className="mobile_all_filters d-block d-sm-none">
						<div className="borad_more_filters dropdown remove_cret">
							<a className="more_filters dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<img src={filter_icon} alt="filters" />
							</a>
							<ul className="dropdown-menu">
								<li className="mobile_serch">
									<div className="borad_srch_form">
										<Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
									</div>
								</li>
								<li>
									<a className="btn_cs sky_border_btn justify-content-center" data-bs-toggle="modal" data-bs-target="#import_files">
										<i className='bx bx-plus-circle'></i>&nbsp;Import files
									</a>
								</li>
							</ul>
						</div>
					</div>
					{(currentPackage === 3 || currentPackage === 2) && (
						<div className="borad_import_btn dropdown remove_cret d-none d-sm-block">
							<a className="btn_cs sky_border_btn dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<i className='bx bx-plus-circle'></i>&nbsp;Import files
							</a>
							<ul className="dropdown-menu">
								<li>
									<div className="upload_files">
										<div className="form_fields">
											<label className="form_label">Upload your files:</label>
											<div className="signup_form3">
												<form ref={form} onSubmit={submit}>
													<div className="form_fields">
														<input type="file" name="uploadfile" className="custom_form_file_type" accept='.csv' />
													</div>
													<div className="form_fields d-flex justify-content-end mt-3">
														<input type="submit" name="Import" className="btn_cs sky_border_btn" value="Import file" />
													</div>
												</form>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					)}
					<Link className="btn_cs sky_bg_btn" to="/addquestions">
						<i className='bx bx-plus-circle'></i>&nbsp;Add New
					</Link>
				</div>
			</div>

			<div className="modal fade" id="import_files" tabIndex="-1" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title">Upload your files:</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit}>
								<div className="form_fields">
									<input
										type="file"
										name="uploadfile"
										className="custom_form_file_type"
										accept=".csv"
									/>
								</div>
								<div className="form_fields d-flex justify-content-end mt-3">
									<input type="submit" className="btn_cs sky_border_btn" value="Import file" />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<div className="main_wraper_pages p-15 pt-0 mb-5">
				<div className="">
					<div className="tabs_srch_form d-flex justify-content-between">
						<div className="data_tabs_pagination d-flex justify-content-between align-items-center border_bt_gray w-100">
							<nav>
								<div className="data_ac_tabs" id="data_ac_tabs" role="tablist">
									<a className="tab_item active" data-bs-toggle="tab" data-bs-target="#active_data" aria-controls="nav-home" aria-selected="true" onClick={activeTab}>Active categories</a>
									<a className="tab_item" data-bs-toggle="tab" data-bs-target="#inactive_data" onClick={inactiveTab}>Inactive categories</a>
								</div>
							</nav>
							<div className="record_search_fm_totl m-0">
								<span className="total_record">{hasSelected ? `Selected: ${selectedRowKeys.length} ` : ''}</span>
								<span className="total_record">Total: {(filterTable) ? filterTable.length : tableData.length}</span>
								<div className="borad_srch_form d-none d-sm-block float-right">
									<Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
								</div>
							</div>
						</div>
					</div>
					<div className="tab-content" id="data-tabContent">
						<div className="tab-pane show active" id="active_data" role="tabpanel"></div>
					</div>
					<div className="tab-content filter_custom" id="data-tabContent">
						<div className="tab-pane show active" id="active_data" role="tabpanel">
							<div className="data_table same_table_als exm_lst mt-3">
								<Table rowSelection={rowSelection} columns={columns} dataSource={(filterTable) ? filterTable : tableData} pagination={{
									onChange(current) {
										setPage(current);
									}
								}} onChange={handleChange} />
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
};

export default QuestionsList;
