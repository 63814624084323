import React, { useEffect, useRef, useContext, useState } from "react";
import { useNavigate, useParams, useHistory } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Table, Tag, DatePicker, Input, Space, Select } from 'antd';
import examContext from "../context/exam/ExamContext";
import userContext from "../context/users/UserContext";
import Highlighter from 'react-highlight-words';

const UserExamDetailedReport = () => {
    let navigate = useNavigate();
    const { Option } = Select;
    const params = useParams();
    const context = useContext(examContext);
    const contextUser = useContext(userContext);
    const { StudentResult, getStudentresult, QuestionReportList, getReportList } = context;
    const { userList, getUsersList, } = contextUser;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {

        if (
            sessionStorage.getItem("token") === null ||
            sessionStorage.getItem("user-type") === "admin"

        ) {

            getUsersList();

        } else {
            navigate("/login");
        }

        // eslint-disable-next-line

    }, []);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {

        confirm();

        setSearchText(selectedKeys[0]);

        setSearchedColumn(dataIndex);

    };

    const handleReset = (clearFilters) => {

        clearFilters();

        setSearchText('');

    };

    const getColumnSearchProps = (dataIndex, filter_type) => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (

            <div

                style={{

                    padding: 8,

                }}

                onKeyDown={(e) => e.stopPropagation()}

            >

                {(filter_type === 'date') ?

                    <DatePicker

                        format={"DD-MM-YYYY"}

                        onChange={(e) => {

                            setSelectedKeys([e.format("DD-MM-YYYY")]);

                        }}



                        allowClear={false}

                        style={{

                            marginBottom: 8,

                            display: 'block',

                        }}

                    />

                    :

                    <Input

                        ref={searchInput}

                        placeholder={`Search ${dataIndex}`}

                        value={selectedKeys[0]}

                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}

                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}

                        style={{

                            marginBottom: 8,

                            display: 'block',

                        }}

                    />

                }

                <Space>

                    <Button

                        type="primary"

                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}

                        icon={<SearchOutlined />}

                        size="small"

                        style={{

                            width: 90,

                        }}

                    >

                        Search

                    </Button>

                    <Button

                        onClick={() => clearFilters && handleReset(clearFilters)}

                        size="small"

                        style={{

                            width: 90,

                        }}

                    >

                        Reset

                    </Button>

                    <Button

                        type="link"

                        size="small"

                        onClick={() => {

                            confirm({

                                closeDropdown: false,

                            });

                            setSearchText(selectedKeys[0]);

                            setSearchedColumn(dataIndex);

                        }}

                    >

                        Filter

                    </Button>

                    <Button

                        type="link"

                        size="small"

                        onClick={() => {

                            close();

                        }}

                    >

                        close

                    </Button>

                </Space>

            </div>

        ),

        filterIcon: (filtered) => (

            <SearchOutlined

                style={{

                    color: filtered ? '#1677ff' : undefined,

                }}

            />

        ),

        onFilter: (value, record) =>

            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

        onFilterDropdownOpenChange: (visible) => {

            if (visible) {

                setTimeout(() => searchInput.current?.select(), 100);

            }

        },

        render: (text) =>

            searchedColumn === dataIndex ? (

                <Highlighter

                    highlightStyle={{

                        backgroundColor: '#ffc069',

                        padding: 0,

                    }}

                    searchWords={[searchText]}

                    autoEscape

                    textToHighlight={text ? text.toString() : ''}

                />

            ) : (

                text

            ),

    });

    const columns = [

        {

            title: 'Question',

            dataIndex: 'Question',

            width: '45%',

            ...getColumnSearchProps('Question'),

        },

        {

            title: 'Correct Answer',

            dataIndex: 'Answer',

            width: '20%',

        },

        {

            title: 'Answered',

            dataIndex: 'Answered',

            width: '20%',

        },

        {

            title: 'Answer Result',

            dataIndex: 'Answer_Result',

            filters: [

                {

                    text: 'Correct',

                    value: 'Correct',

                },

                {

                    text: 'Incorrect',

                    value: 'Incorrect',

                },

            ],

            onFilter: (value, record) => record.Answer_Result.indexOf(value) === 0,

            render: (result) => (<>

                <Tag className={result} color={result === 'Incorrect' ? 'volcano' : 'green'} key={result}>

                    {result}

                </Tag>

            </>

            ),

            width: '15%',

        },

    ];
    const selectUserListchange = (userId) => {
        getStudentresult(userId);
    }

    const selectExamListchange = (examId) => {
        getReportList(examId)
    }
    return (

        <>
            <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
                <div className="board_title d-flex align-items-center gap-3">
                    <h2>User Exam Detailed Report</h2>
                </div>
                <div className="board_form_filter d-flex gap-3 align-items-center">

                    <button className="btn_cs sky_bg_btn" onClick={() => navigate(-1)}>Back</button>
                </div>
            </div>
            <div className="data_wraper_pages mb-5">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 display_ruby"><div className="report_serchform"><strong>User:</strong></div>
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="label"
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            onChange={selectUserListchange}
                        >

                            {userList.map(user => (

                                <Option key={user.value} value={user.value}>{user.label}</Option>
                            ))}
                        </Select>

                        <div className="report_serchform"><strong>Exam:</strong></div>
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="label"
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            onChange={selectExamListchange}
                        >

                            {StudentResult.map(exam => (

                                <Option key={exam.answer_id} value={exam.answer_id}>{exam.exam_name}</Option>
                            ))}
                        </Select></div>
                    <div className="col-md-3"></div>

                </div>
                <div className="all_page_title cnt_info_title row se_details">
                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Score:</strong> {QuestionReportList.marksobtained}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Percentage:</strong> {QuestionReportList.percentage} %
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Result:</strong> {QuestionReportList.resultexam}
                        </div>
                    </div>
                </div>
                <Table columns={columns} dataSource={QuestionReportList.result} />
            </div>
        </>
    );

}



export default UserExamDetailedReport;





