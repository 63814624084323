import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space, Button } from 'antd';
import axios from 'axios';
const EditAdminProfile = (props) => {
  let navigate = useNavigate();
  const [loadings, setLoadings] = useState([]);
  const [hideSendOtpButtonDiv, setHideSendOtpButtonDiv] = useState(true);
  const [hideResendOtpButton, setHideResendOtpButton] = useState(false);
  const [email, setEmail] = useState();
  const [otpVerificationState, setOtpVerificationState] = useState(false);
  const [adminEmail, setAdminEmail] = useState();
  const [emailFieldDisabled, setEmailFieldDisabled] = useState(true);
  const [sendOtpFieldDisabled, setSendOtpFieldDisabled] = useState(true);
  const [adminCredential, setadminCredential] = useState({
    email: '',
    name: '',
  });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    otpcode: '',
  });
  const [errors, setErrors] = useState({});
  const [Otp, setOtp] = useState();

  const getAdminDetails = (Id) => {
    axios
      .get(`api/auth/getAdminDataById/${Id}`)
      .then(function (response) {
        // Handle successful response
        setadminCredential({
          ...adminCredential,
          email: response.data.data.email,
          name: response.data.data.name,
        });
        setAdminEmail(response.data.data.email);
        setFormData({
          ...formData,
          email: response.data.data.email,
          name: response.data.data.name,
        });
        const [name, domain] = response.data.data.email.split('@');
        const newEmail = `${name[0]}${new Array(name.length).join(
          '*'
        )}@${domain}`;
        setEmail(newEmail);
        //console.log('User data:', adminCredential);
      })
      .catch(function (error) {
        // Handle error
        console.error('Error fetching user data:', error);
      });
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('token') === null ||
      sessionStorage.getItem('user-type') === 'admin'
    ) {
      const adminId = sessionStorage.getItem('admin-id');
      getAdminDetails(adminId);
    } else {
      navigate('/');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = 'Name is required';
    }

    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Email is invalid';
    }

    // If there are validation errors, set them and prevent form submission
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const Id = sessionStorage.getItem('admin-id');
    // Send a POST request
    axios({
      method: 'post',
      url: `api/auth/updateAdminData/${Id}`,
      data: {
        email: adminCredential.email,
        name: adminCredential.name,
        otpState: otpVerificationState,
        //password: adminCredential.password
      },
    })
      .then(function (response) {
        //console.log(response.data.success)

        if (response.data.success === true) {
          window.location.reload(true);
          sessionStorage.setItem('user-name', adminCredential.name);
          sessionStorage.setItem('user-email', adminCredential.email);

          props.showAlert(response.data.message, 'suceess');
        } else {
          props.showAlert(response.data.message, 'danger');
        }
      })
      .catch(function (error) {
        // Handle error
        console.error('Error fetching user data:', error);
      });
  };

  const onChange = (e) => {
    setErrors('');
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setadminCredential({ ...adminCredential, [e.target.name]: e.target.value });

    if (e.target.name === 'email') {
      if (adminEmail != e.target.value) {
        setSendOtpFieldDisabled(false);
      } else {
        setSendOtpFieldDisabled(true);
      }
    }
  };

  const sendVerificationEmail = (e) => {
    const validationErrors = {};
    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Email is invalid';
    }

    // If there are validation errors, set them and prevent form submission
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[0] = true;
      return newLoadings;
    });
    e.preventDefault();
    const Id = sessionStorage.getItem('admin-id');
    // Send a POST request
    axios({
      method: 'post',
      url: `api/auth/sendAdminEmailVerification/${Id}`,
      data: {
        email: adminCredential.email,
      },
    })
      .then(function (response) {
        //console.log(response.data.success)
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[0] = false;
          return newLoadings;
        });
        if (response.data.success === true) {
          setHideResendOtpButton(true);
          props.showAlert(response.data.message, 'suceess');
        } else {
          props.showAlert(response.data.message, 'danger');
        }
      })
      .catch(function (error) {
        // Handle error
        console.error('Error sending email data:', error);
      });
  };

  const editEmail = () => {
    // console.log('Enable field')
    setEmailFieldDisabled(false);
    setHideSendOtpButtonDiv(false);
    //setEmail(adminCredential.email)
  };

  const onChangeOtp = (e) => {
    setErrors('');
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setOtp(e.target.value);
  };

  const otpVerification = (e) => {
    const validationErrors = {};

    if (!formData.otpcode.trim()) {
      validationErrors.otpcode = 'Otp is required';
    } else if (formData.otpcode.length != 6) {
      validationErrors.otpcode = 'Otp should be 6 digit';
    }

    // If there are validation errors, set them and prevent form submission
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[2] = true;
      return newLoadings;
    });
    e.preventDefault();
    const Id = sessionStorage.getItem('admin-id');
    // Send a POST request
    axios({
      method: 'post',
      url: `api/auth/adminEmailOtpVerification/${Id}`,
      data: {
        otp: Otp,
      },
    })
      .then(function (response) {
        //console.log(response.data.success)
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[2] = false;
          return newLoadings;
        });
        if (response.data.success === true) {
          props.showAlert(response.data.message, 'suceess');
          setOtpVerificationState(true);
          setHideSendOtpButtonDiv(true);
          setHideResendOtpButton(false);
        } else {
          props.showAlert(response.data.message, 'danger');
        }
      })
      .catch(function (error) {
        // Handle error
        console.error('Error sending email data:', error);
      });
  };

  return (
    <>
      <div className="admin_profile_wraper py-5">
        <div className="main_wraper_pages p-15 text-center mb-3">
          <Space size={16} wrap>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              style={{ backgroundColor: '#ffaa00' }}
              icon={<UserOutlined />}
            />
          </Space>
        </div>
        <div className="main_wraper_pages p-15">
          <form onSubmit={handleSubmit}>
            <div className="form_fields mb-4">
              <label for="title" className="form_label">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={adminCredential.name}
                onChange={onChange}
                aria-describedby="nameHelp"
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="form_fields mb-4">
              <label for="title" className="form_label">
                Email
              </label>
              <div className="d-flex gap-2 otp_btns">
                <input
                  type="email"
                  disabled={emailFieldDisabled}
                  className="form_input"
                  id="email"
                  name="email"
                  value={adminCredential.email}
                  onChange={onChange}
                  aria-describedby="emailHelp"
                />
                <div
                  className={hideSendOtpButtonDiv == true ? 'custom_hide' : ''}
                >
                  <Button
                    type="primary"
                    disabled={sendOtpFieldDisabled}
                    className={hideResendOtpButton == true ? 'custom_hide' : ''}
                    loading={loadings[0]}
                    onClick={sendVerificationEmail}
                  >
                    Send OTP
                  </Button>
                  {hideResendOtpButton == true ? (
                    <Button
                      type="primary"
                      loading={loadings[0]}
                      onClick={sendVerificationEmail}
                    >
                      Resend OTP
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {errors.email && <span className="error">{errors.email}</span>}
              <div className="edit_eml">
                <span onClick={editEmail}>Edit</span>
              </div>
            </div>
            <div className={hideResendOtpButton == false ? 'custom_hide' : ''}>
              <div className="form_fields mb-4">
                <label htmlFor="email" className="form_label">
                  OTP
                </label>
                <div className="d-flex gap-2 otp_btns">
                  <input
                    type="text"
                    id="otpcode"
                    name="otpcode"
                    value={Otp}
                    onChange={onChangeOtp}
                    aria-describedby="otpcodeHelp"
                  />
                  <Button
                    type="primary"
                    loading={loadings[2]}
                    onClick={otpVerification}
                  >
                    Verify OTP
                  </Button>
                </div>
                {errors.otpcode && (
                  <span className="error">{errors.otpcode}</span>
                )}
              </div>
            </div>
            <div className="form_fields">
              <button
                type="submit"
                className="submit_btn btn_cs sky_bg_btn w-100 justify-content-center"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditAdminProfile;
