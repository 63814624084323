import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CatesContext from "../context/cats/CatesContext";
import quesContext from "../context/ques/QuesContext";

const EditQuestions = (props) => {
  const [DataInfoEdit, setDataInfoEdit] = useState({});
  const { editQuess } = useContext(quesContext);
  const Contextcategories = useContext(CatesContext);
  const { ActiveCates, getActiveCates } = Contextcategories;
  const [editquesndata, seteditquesndata] = useState({
    title: '',
    options: [],
    answer: '',
    weightage: '',
    category_id: ''
  });
  const [formFields, setFormFields] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const params = useParams();
  let navigate = useNavigate();
  let currentPackage = parseInt(sessionStorage.getItem('adminPlanId'));

  useEffect(() => {
    if (!sessionStorage.getItem("token") || sessionStorage.getItem("user-type") !== "admin") {
      navigate("/login");
    } else {
      getQuestionById(params.qid);
      getActiveCates();
    }
  }, []);

  const getQuestionById = async (id) => {
    const response = await fetch(`/api/ques/fetchQuestionById/${id}`, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "auth-token": sessionStorage.getItem("token"),
      },
    });
    const json = await response.json();
    setDataInfoEdit(json.questions);
    setFormFields(json.questions.options);
    seteditquesndata(json.questions);
  }

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index] = event.target.value;
    setFormFields(data);
    seteditquesndata({ ...editquesndata, options: data });
  }

  const onChange = (e) => { seteditquesndata({ ...editquesndata, [e.target.name]: e.target.value }) }

  const addFields = () => {
    setFormFields([...formFields, '']);
  }

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
    seteditquesndata({ ...editquesndata, options: data });
  }

  const validate = () => {
    let errors = {};

    if (!editquesndata.question) {
      errors.question = 'Question is required';
    }

    if (editquesndata.options.length <= 1) {
      errors.options = 'At least two options are required';
    } else {
      editquesndata.options.forEach((option, index) => {
        if (!option) {
          errors.options = `Option ${index + 1} is required`;
        }
      });
    }

    if (!editquesndata.answer) {
      errors.answer = 'Answer is required';
    }

    if (!editquesndata.category) {
      errors.category = 'Category is required';
    }

    if (!editquesndata.weightage) {
      errors.weightage = 'Weightage is required';
    }

    return errors;
  };

  const handleOkEdit = () => {
    const errors = validate();
    console.log(errors)
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      props.showAlert('Please fill all the required fields.', 'danger');
      return;
    }

    editQuess(
      editquesndata._id,
      editquesndata.question,
      editquesndata.options,
      editquesndata.answer,
      editquesndata.category,
      editquesndata.weightage,
    );
    props.showAlert("Updated Successfully", "success");
    navigate("/home");
  }

  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Edit questions</h2>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div className="addexamtype">
            <div className="row">
              <div className="col-md-6">
                <div className="form_fields mb-4">
                  <label htmlFor="question" className="form_label">Question</label>
                  <textarea
                    name="question"
                    defaultValue={DataInfoEdit.question}
                    onChange={onChange}
                    minLength={2}
                    required
                    placeholder="Enter the question"
                    className={formErrors.question ? 'border-color-red-error' : ''}
                  />

                </div>
              </div>
              {currentPackage === 3 && (
                <div className="col-md-3">
                  <div className="form_fields">
                    <label htmlFor="weightage" className="form_label">Weightage</label>
                    <input
                      type="number"
                      name="weightage"
                      onChange={onChange}
                      defaultValue={DataInfoEdit.weightage}
                      className={formErrors.weightage ? 'border-color-red-error' : ''}
                    />

                  </div>
                </div>
              )}
              <div className={currentPackage !== 3 ? "col-md-6" : "col-md-3"}>
                <div className="form_fields">
                  <label htmlFor="category" className="form_label">Category</label>
                  <select
                    name="category"
                    defaultValue={DataInfoEdit.category}
                    onChange={onChange}
                    className={formErrors.category ? 'border-color-red-error' : ''}
                  >
                    <option value="" disabled>-- Select category --</option>
                    {ActiveCates?.map((cats) => (
                      <option key={cats.id} value={cats.id}>{cats.name}</option>
                    ))}
                  </select>

                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="options" className="form_label">Options</label>
                {formFields?.map((option, index) => (
                  <div className="col-md-12" key={index}>
                    <div className="form_fields remove_cat_wrap d-flex gap-2 mb-4 align-items-center">
                      <input
                        type="text"
                        placeholder={`Option ${index + 1}`}
                        value={option}
                        onChange={(event) => handleFormChange(event, index)}
                        minLength={2}
                        required
                        className={formErrors.options ? 'border-color-red-error' : ''}
                      />
                      {formFields.length - 1 === index && (
                        <button className="add_remv_btn btn_cs sky_bg_btn add_option" onClick={addFields}>
                          <i className="fa-solid fa-plus"></i>
                        </button>
                      )}
                      {index >= (formFields.length === 2 ? 2 : 0) && (
                        <button onClick={() => removeFields(index)} className="add_remv_btn remve">
                          <i className="fa-solid fa-minus"></i>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-6">
                <div className="form_fields">
                  <label htmlFor="answer" className="form_label">Correct answer</label>
                  <select
                    name="answer"
                    defaultValue={DataInfoEdit.answer}
                    onChange={onChange}
                    className={formErrors.answer ? 'border-color-red-error' : ''}
                  >
                    <option value="" disabled>-- Select the correct option --</option>
                    {formFields?.map((option, index) => (
                      <option key={index} value={index + 1}>
                        Option {index + 1}
                      </option>
                    ))}
                  </select>

                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 cancel_btn">
                <Link className="btn_cs sky_border_btn" to="/home">CANCEL</Link>
                <div className="add_qus_submit d-flex gap-3 justify-content-end">
                  <button type="submit" className="btn_cs sky_bg_btn" onClick={handleOkEdit}>UPDATE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditQuestions;
