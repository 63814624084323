import React, { useEffect, useRef, useContext, useState, ChangeEvent } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button, Table, Tag, Input, Space } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import resultContext from '../context/results/ResultsContext';
import filter_icon from "../more-filters.png";

const ExamWiseResult_List = () => {
  let navigate = useNavigate();
  const context = useContext(resultContext);
  const { ExamResults, getexamwiseresult } = context;
  const [page, setPage] = useState(1);
  const [filterTable, setFilterTable] = useState(null);
  useEffect(() => {

    if (

      sessionStorage.getItem("token") === null ||

      sessionStorage.getItem("user-type") == "admin"

    ) {

      getexamwiseresult();

    } else {

      navigate("/");

    }



    // eslint-disable-next-line

  }, []);


  const onTableSearch = (value) => {
    let filterTable = ExamResults.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())
      )
    );

    setFilterTable(filterTable);

  };

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChangetable = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };

  const columns = [



    {

      title: 'S.No',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },

    {
      title: 'Exam Name',
      render: (data) => <Link to={`/examwiseresultdetails/${data.id}`}>{data.name}</Link>,
      key: 'name',
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      width: '30%',
    },

    {

      title: 'Total Student',
      dataIndex: 'totalresult',
      key: 'totalresult',
      align: '-webkit-center',
      filteredValue: filteredInfo.totalresult || null,
      onFilter: (value, record) => record.totalresult.includes(value),
      sorter: (a, b) => a.totalresult.length - b.totalresult.length,
      sortOrder: sortedInfo.columnKey === 'totalresult' ? sortedInfo.order : null,
      ellipsis: true,

    },

    {

      title: 'Total Pass',
      dataIndex: 'countpass',
      key: 'countpass',
      align: '-webkit-center',
      filteredValue: filteredInfo.countpass || null,
      onFilter: (value, record) => record.countpass.includes(value),
      sorter: (a, b) => a.countpass.length - b.countpass.length,
      sortOrder: sortedInfo.columnKey === 'countpass' ? sortedInfo.order : null,
      ellipsis: true,
      render: (countpass) => <div className='status_btns btn_active'><span>{countpass}</span></div>,



    },

    {

      title: 'Total Fail',

      key: 'countfail',

      dataIndex: 'countfail',
      align: '-webkit-center',
      filteredValue: filteredInfo.countfail || null,
      onFilter: (value, record) => record.countfail.includes(value),
      sorter: (a, b) => a.countfail.length - b.countfail.length,
      sortOrder: sortedInfo.columnKey === 'countfail' ? sortedInfo.order : null,
      ellipsis: true,
      render: (countfail) => <div className='status_btns btn_inactive'><span>{countfail}</span></div>,

    },

    {

      title: 'Action',

      key: 'action',
      align: '-webkit-center',
      render: (data) => (<>

        <div className="action_dropdown dropdown dropstart ">
          <a className="action_toggle dropdown-toggle " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </a>
          <ul className="dropdown-menu">
            <li> <Link to={`/examwiseresultdetails/${data.id}`}><i class="fa-regular fa-eye" aria-hidden="true"></i>View</Link></li>

          </ul>
        </div>

      </>),



    },

  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {

    console.log('selectedRowKeys changed: ', newSelectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);

  };

  const rowSelection = {

    selectedRowKeys,

    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (

    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Exam Wise Result</h2>
        </div>
        <div className="board_form_filter d-flex gap-3 align-items-center">
          <div className="mobile_all_filters d-block d-sm-none">
            <div class="borad_more_filters dropdown remove_cret">
              <a class="more_filters dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={filter_icon} alt="filters" />
              </a>
              <ul class="dropdown-menu">
                <li class="mobile_serch">
                  <div className="borad_srch_form">
                    <Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div className="record_search_fm_totl justify-content-end">
            <span className='total_record'>	{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>

            <span className="total_record">Total: {filterTable ? filterTable.length : ExamResults.length}</span>
            <div className="borad_srch_form d-none d-sm-block">
              <Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
            </div>
          </div>
          <div className="data_table same_table_als">
            <Table pagination={{
              onChange(current) {
                setPage(current);
              }
            }} rowSelection={rowSelection} columns={columns} dataSource={(filterTable) ? filterTable : ExamResults} onChange={handleChangetable} />
          </div>
        </div>
      </div>

    </>

  )

}



export default ExamWiseResult_List

