import React, { useEffect, useRef, useContext, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button, Table, Select, DatePicker, Input } from 'antd';
import examContext from "../context/exam/ExamContext";
import userContext from "../context/users/UserContext";
import axios from 'axios';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as moment from "moment";
import filter_icon from "../more-filters.png";

const { RangePicker } = DatePicker;

const UserWiseAllExamReport = () => {
    const { Option } = Select;
    const params = useParams();
    const [selectedResult, setSelectedResult] = useState('');
    const context = useContext(examContext);
    const contextUser = useContext(userContext);

    const { StudentResult, getStudentresult, StudentData } = context;
    const { userList, getUsersList, } = contextUser;
    const [filterStudentData, setfilterStudentData] = useState();
    const [page, setPage] = useState(1);
    const [filterResultData, setFilterResultData] = useState({ resultData: '', filterFail: '', filterPass: "", filterPassFail: "" })
    const dateFormat = 'DD-MM-YYYY';
    let navigate = useNavigate();
    var selecteddates = [];
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const [filterData, setFilterData] = useState({
        name: '',
        startDate: '',
        endDate: '',
        result: '',
    });

    const fileType =

        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";
    const toggleDropdown = () => {

        setDropdownOpen(!isDropdownOpen);
    };

    // const handleClickOutside = (event) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //         setDropdownOpen(false);
    //     }
    // };
    const onTableSearch = (value) => {
        let filterTable = '';
        (filterResultData.resultData) ?
            filterTable = filterResultData.resultData.filter((o) =>
                Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())
                )
            ) :
            filterTable = StudentResult.filter((o) =>
                Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())
                )
            );

        setfilterStudentData(filterTable);

    };



    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const handleChangetable = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const clearFilters = () => {
        setFilteredInfo({});
    };
    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };
    const setAgeSort = () => {
        setSortedInfo({
            order: 'descend',
            columnKey: 'age',
        });
    };


    const columns = [
        {
            title: 'S.No',
            dataIndex: 'no',
            key: 'no',
            width: '5%',
            render: (value, item, index) => (page - 1) * 10 + index + 1,
        },
        {
            title: 'Name',
            render: (data) => <Link to={`/exam_question_report/${data.answer_id}`}>{data.exam_name}</Link>,
            key: 'exam_name',
            width: '25%',
            filteredValue: filteredInfo.exam_name || null,
            onFilter: (value, record) => record.exam_name.includes(value),
            sorter: (a, b) => a.exam_name.localeCompare(b.exam_name),
            sortOrder: sortedInfo.columnKey === 'exam_name' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Date',
            dataIndex: 'exam_date',
            key: 'exam_date',
            filteredValue: filteredInfo.exam_date || null,
            onFilter: (value, record) => record.exam_date.includes(value),
            sorter: (a, b) => moment(a.exam_date, 'DD-MM-YYYY').unix() - moment(b.exam_date, 'DD-MM-YYYY').unix(),
            sortOrder: sortedInfo.columnKey === 'exam_date' ? sortedInfo.order : null,
            ellipsis: true,
            width: '10%',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            className: 'custom_firstletter_caps',
            width: '10%',
            filteredValue: filteredInfo.result || null,
            onFilter: (value, record) => record.result.includes(value),
            sorter: (a, b) => a.result.localeCompare(b.result),
            sortOrder: sortedInfo.columnKey === 'result' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Percentage',
            dataIndex: 'user_percentage',
            key: 'user_percentage',
            width: '10%',
            filteredValue: filteredInfo.user_percentage || null,
            onFilter: (value, record) => record.user_percentage.includes(value),
            sorter: (a, b) => parseFloat(a.user_percentage) - parseFloat(b.user_percentage),
            sortOrder: sortedInfo.columnKey === 'user_percentage' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Max marks',
            dataIndex: 'max_marks',
            key: 'max_marks',
            width: '15%',
            filteredValue: filteredInfo.max_marks || null,
            onFilter: (value, record) => record.max_marks.includes(value),
            sorter: (a, b) => parseFloat(a.max_marks) - parseFloat(b.max_marks),
            sortOrder: sortedInfo.columnKey === 'max_marks' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Marks obtain',
            dataIndex: 'marks_Obtained',
            key: 'marks_Obtained',
            width: '15%',
            filteredValue: filteredInfo.marks_Obtained || null,
            onFilter: (value, record) => record.marks_Obtained.includes(value),
            sorter: (a, b) => parseFloat(a.marks_Obtained) - parseFloat(b.marks_Obtained),
            sortOrder: sortedInfo.columnKey === 'marks_Obtained' ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: 'Action',
            button: true,
            align: '-webkit-center',
            render: (data) => (
                <>
                    <div className="action_dropdown dropdown dropstart">
                        <a
                            className="action_toggle dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <Link to={`/exam_question_report/${data.answer_id}`}>
                                    <i className="fa-regular fa-eye"></i>View
                                </Link>
                            </li>
                            <li>
                                <a onClick={() => exportToCSV(data.answer_id)}>
                                    <i className="fa-solid fa-download"></i>Export
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
            ),
            width: '10%',
        },
    ];




    const exportToCSV = async (answer_id) => {

        const response = await axios.get(`/api/examdetails/exportuseranswerdata/${answer_id}`)
        const ws = XLSX.utils.json_to_sheet(response.data.result);
        const wb = { Sheets: { Result: ws }, SheetNames: ["Result"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Result-' + response.data.exam_name + fileExtension);

    };



    useEffect(() => {
        if (
            sessionStorage.getItem("token") === null ||
            sessionStorage.getItem("user-type") === "admin"
        ) {

            getUsersList()

            // document.addEventListener('mousedown', handleClickOutside);
            // return () => {
            //     document.removeEventListener('mousedown', handleClickOutside);
            // };

        } else {
            navigate("/login");
        }
        // eslint-disable-next-line


    }, []);


    const datePickerChange = (value, mode) => {
        setFilterData({ ...filterData, startDate: mode[0], endDate: mode[1] })
    }

    const onChangeHandler = (e) => {
        setFilterData({ ...filterData, name: e.target.value })
    }

    const getFilteredDataRest = () => {
        setFilterData({
            name: '',
            startDate: '',
            endDate: '',
            result: '',
        });
        setFilterResultData('')

    }

    const getFilteredData = () => {
        axios({
            method: 'post',
            url: `/api/examdetails/stuexamdetailsdataviewwithfilter/`,
            data: {
                name: filterData.name,
                userId: params.sid,
                startDate: filterData.startDate,
                endDate: filterData.endDate,
                result: selectedResult
            }
        })
            .then(function (response) {

                if (response.data.success === true) {

                    setFilterResultData({ ...filterResultData, resultData: response.data.resultData, filterFail: response.data.fail, filterPass: response.data.pass, filterPassFail: response.data.total_exam }
                    )
                }
            })
            .catch(function (error) {
                // Handle error
                console.error('Error sending email data:', error);
            });
    }

    const selectResultOnChange = (e) => {

        const value = e.target.value;
        setSelectedResult(value);
        console.log("Selected Result:", value);

    }

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = (newSelectedRowKeys) => {

        console.log('selectedRowKeys changed: ', newSelectedRowKeys);

        setSelectedRowKeys(newSelectedRowKeys);

    };

    const rowSelection = {

        selectedRowKeys,

        onChange: onSelectChange,

    };

    const hasSelected = selectedRowKeys.length > 0;

    const selectListchange = (value) => {

        getStudentresult(value);

    }

    if (userList[0] && StudentData == '') {
        getStudentresult(userList[0].value)
    }


    return (


        <>


            <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
                <div className="board_title d-flex align-items-center gap-3">
                    <h2>UserWise- AllExamsReport </h2>
                </div>
                <div className="dropdowna_start board_form_filter d-flex gap-3 align-items-center">

                    {/* <div className="mobile_all_filters">
                        <div className={`borad_more_filters dropdown remove_cret ${isDropdownOpen ? 'show' : ''}`} ref={dropdownRef}>
                            <a
                                className="more_filters dropdown-toggle"
                                href="#"
                                role="button"
                                aria-expanded={isDropdownOpen}
                                onClick={toggleDropdown}
                            >
                                <img src={filter_icon} alt="filters" />
                            </a>
                            <ul className={`dropdown-menu ie_dropdown ${isDropdownOpen ? 'show' : ''}`} style={{
                                position: 'absolute',
                                inset: 'auto auto auto auto',
                                margin: '0px',
                                transform: 'translate3d(-398.4px, 21.6px, 0px)'
                            }}>
                                <span onClick={() => setDropdownOpen(false)} className="close-icon">
                                    <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                                </span>
                                <li className="mobile_serch d-block d-sm-none">
                                    <div className="borad_srch_form">
                                        <Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
                                    </div>
                                </li>
                                <li className="date_range_filter_li">
                                    <div className="date_range_filter_wrap">
                                        <label>Date range</label>
                                        <div className="date_range_filter">
                                            <RangePicker name="date" format={dateFormat} onChange={datePickerChange} />
                                            <div class="exmWise_res form_fields mt-3">
                                                <label className="form_label">Show result</label>
                                                <select className="form_fields" name="show_result" onChange={selectResultOnChange} >
                                                    <option value="">-- Select one --</option>
                                                    <option value="pass">Pass</option>
                                                    <option value="fail">Fail</option>
                                                </select>
                                            </div>
                                            <div className="date_range_filter_btn d-flex gap-2 mt-3 justify-content-end">
                                                <Button className="btn_cs sky_bg_btn" type="primary" onClick={getFilteredDataRest}>Reset</Button>
                                                <Button className="btn_cs sky_border_btn" type="primary" onClick={getFilteredData}>Search</Button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    <button className="btn_cs sky_bg_btn" onClick={() => navigate(-1)}>Back</button>
                </div>
            </div>

            <div className="data_wraper_pages mb-5">
                <div className="row">
                    <div className="col-md-4"></div>

                    <div className="col-md-4">
                        <div className="report_serchform">
                            <strong>Select user: </strong>
                            <Select
                                showSearch
                                style={{
                                    width: 200,
                                }}
                                placeholder="Select user"
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                onChange={selectListchange}
                            >

                                {userList.map(user => (

                                    <Option key={user.value} value={user.value}>{user.label}</Option>
                                ))}
                            </Select>
                        </div></div>
                    <div className="col-md-4"></div>
                </div>
                <div className="all_page_title cnt_info_title row se_details">

                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Name:</strong> {StudentData.name}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Email:</strong> {StudentData.email}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Phone:</strong> {StudentData.phone}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Pass:</strong> {(filterResultData.resultData) ? filterResultData.filterPass : StudentData.pass}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Fail:</strong> {(filterResultData.resultData) ? filterResultData.filterFail : StudentData.fail}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="title_details">
                            <strong>Total exams:</strong> {
                                (filterResultData.resultData) ?
                                    filterResultData.filterPassFail :
                                    StudentData.total_result
                            }
                        </div>
                    </div>
                </div>
                <div className="record_search_fm_totl justify-content-end">

                    <span className="total_record">	{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>
                    <div className="borad_srch_form d-none d-sm-block">
                        <Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
                    </div>
                </div>
                <div className="row">
                    {(filterResultData.resultData) ?
                        <Table pagination={{
                            onChange(current) {
                                setPage(current);
                            }
                        }} rowSelection={rowSelection} columns={columns} dataSource={(filterStudentData) ? filterStudentData : filterResultData.resultData} onChange={handleChangetable} />
                        :


                        <Table pagination={{
                            onChange(current) {
                                setPage(current);
                            }
                        }} rowSelection={rowSelection} columns={columns} dataSource={(filterStudentData) ? filterStudentData : StudentResult} onChange={handleChangetable} />}
                </div>
            </div >
        </>

    );

}



export default UserWiseAllExamReport;





