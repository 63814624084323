import React, { useEffect, useRef, useContext, useState, ChangeEvent } from 'react';

import { Link, useNavigate } from "react-router-dom";

import { Button, Table, Tag, Input, Space } from 'antd';

import { EyeOutlined } from '@ant-design/icons';

import Highlighter from 'react-highlight-words';

import { SearchOutlined } from '@ant-design/icons';

import resultContext from '../context/results/ResultsContext';



const AllExamsReportList = () => {

    let navigate = useNavigate();

    const context = useContext(resultContext);

    const { ExamResults, getexamwiseresult } = context;

    const [filterTable, setFilterTable] = useState(null);

    useEffect(() => {

        if (

            sessionStorage.getItem("token") === null ||

            sessionStorage.getItem("user-type") == "admin"

        ) {

            getexamwiseresult();

        } else {

            navigate("/");

        }



        // eslint-disable-next-line

    }, []);
    console.log(ExamResults)
    const [searchText, setSearchText] = useState('');

    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {

        confirm();

        setSearchText(selectedKeys[0]);

        setSearchedColumn(dataIndex);

    };

    const handleReset = (clearFilters) => {

        clearFilters();

        setSearchText('');

    };

    const getColumnSearchProps = (dataIndex) => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (

            <div

                style={{

                    padding: 8,

                }}

                onKeyDown={(e) => e.stopPropagation()}

            >

                <Input

                    ref={searchInput}

                    placeholder={`Search ${dataIndex}`}

                    value={selectedKeys[0]}

                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}

                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}

                    style={{

                        marginBottom: 8,

                        display: 'block',

                    }}

                />

                <Space>

                    <Button

                        type="primary"

                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}

                        icon={<SearchOutlined />}

                        size="small"

                        style={{

                            width: 90,

                        }}

                    >

                        Search

                    </Button>

                    <Button

                        onClick={() => clearFilters && handleReset(clearFilters)}

                        size="small"

                        style={{

                            width: 90,

                        }}

                    >

                        Reset

                    </Button>

                    <Button

                        type="link"

                        size="small"

                        onClick={() => {

                            confirm({

                                closeDropdown: false,

                            });

                            setSearchText(selectedKeys[0]);

                            setSearchedColumn(dataIndex);

                        }}

                    >

                        Filter

                    </Button>

                    <Button

                        type="link"

                        size="small"

                        onClick={() => {

                            close();

                        }}

                    >

                        close

                    </Button>

                </Space>

            </div>

        ),

        filterIcon: (filtered) => (

            <SearchOutlined

                style={{

                    color: filtered ? '#1677ff' : undefined,

                }}

            />

        ),

        onFilter: (value, record) =>

            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

        onFilterDropdownOpenChange: (visible) => {

            if (visible) {

                setTimeout(() => searchInput.current?.select(), 100);

            }

        },

        render: (text) =>

            searchedColumn === dataIndex ? (

                <Highlighter

                    highlightStyle={{

                        backgroundColor: '#ffc069',

                        padding: 0,

                    }}

                    searchWords={[searchText]}

                    autoEscape

                    textToHighlight={text ? text.toString() : ''}

                />

            ) : (

                text

            ),

    });





    const onTableSearch = (value) => {

        //console.log("PASS", { value });



        let filterTable = ExamResults.filter((o) =>

            Object.keys(o).some((k) =>

                String(o[k]).toLowerCase().includes(value.toLowerCase())

            )

        );



        setFilterTable(filterTable);

    };

    const columns = [



        {

            title: 'S,no',

            dataIndex: 'no',

            key: 'no',

        },

        {

            title: 'Exam Name',

            dataIndex: 'name',

            key: 'name',

            // ...getColumnSearchProps('name'),

        },

        {

            title: 'Total Exam',

            dataIndex: 'totalresult',

            key: 'totalresult',

        },

        {

            title: 'Total Pass',

            dataIndex: 'countpass',

            key: 'countpass',

            render: (countpass) => <Tag color='green'>{countpass}</Tag>,



        },

        {

            title: 'Total Fail',

            key: 'countfail',

            dataIndex: 'countfail',

            render: (countfail) => <Tag color='geekblue'>{countfail}</Tag>,

        },

        {

            title: 'Action',

            key: 'action',

            render: (data) => (<>

                <Link to={`/examwiseresultdetails/${data.id}`}><EyeOutlined className="btn custombtn_result_view" /></Link>

            </>),



        },

    ];



    return (

        <>

            <div className="all_page_title">

                <h2>All Exam Report</h2>



            </div>

            <div className="row">

                <div className="col-lg-9 col-md-5 col-sm-12"></div>

                <div className="col-lg-3 ">

                    <Input.Search

                        // style={{ border: "3px solid red", margin: "0 0 10px 0" }}

                        placeholder="Search "

                        enterButton

                        onSearch={onTableSearch}

                    />

                </div>

            </div>

            <Table columns={columns} dataSource={(filterTable) ? filterTable : ExamResults} />

        </>

    )

}



export default AllExamsReportList

