import React, { useEffect, useRef, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ContentState, EditorState } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import axios from 'axios';

const UserEmailLoginOtpTemplate = (props) => {
  let navigate = useNavigate();
  const [Settings, setSettings] = useState({});
  const [Content, setContent] = useState();
  const [DataInfoEdit, setDataInfoEdit] = useState();
  const [ContentView, setContentView] = useState(() =>
    EditorState.createEmpty()
  );
  const getEmailTemplateSettings = async (id) => {
    try {
      const response = await axios.get(
        `/api/emailsettings/emailtemplatesettingsdata/${id}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'auth-token': sessionStorage.getItem('token'),
          },
        }
      );

      const { settings } = response.data;
      setSettings(settings.data);
      console.log(settings.data);
      const htmlContent = settings.data.body;
      const contentBlock = htmlToDraft(htmlContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setContentView(EditorState.createWithContent(contentState));
    } catch (error) {
      console.error('Error fetching email template settings:', error);
    }
  };

  const editEmailTemplateSettings = async (id, subject, body, footer) => {
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('footer', footer);

    axios
      .put('/api/emailsettings/emailtemplateupdate/' + id, formData)
      .then((res) => {
        if (res.data.success === true) {
          props.showAlert(res.data.message, 'success');
        } else {
          props.showAlert(res.data.message, 'danger');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditorChange = (content) => {
    setContentView(content);
    let check_content = convertToHTML(content.getCurrentContent());
    setContent(check_content);
  };

  const handleClick = (e) => {
    console.log(Content);
    if (!Settings.subject || !Content) {
      props.showAlert('Please fill in all fields', 'danger');
    } else {
      editEmailTemplateSettings(
        (Settings['_id'] = 'user-login-otp-template'),
        Settings.subject,
        Content
        //Settings.footer
      );
    }
  };

  const onChange = (e) => {
    setSettings({ ...Settings, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('token') === null ||
      sessionStorage.getItem('user-type') === 'admin'
    ) {
    } else {
      navigate('/');
    }

    getEmailTemplateSettings('user-login-otp-template');

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Login Otp Email Template</h2>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div className="row">
            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="subject" className="form_label">
                  Subject:
                </label>
                <input
                  type="text"
                  key="subject"
                  name="subject"
                  onChange={onChange}
                  value={Settings.subject || ''}
                  required
                  placeholder="Subject"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form_fields mb-4">
                <label htmlFor="body" className="form_label">
                  Body:
                </label>
                <Editor
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                  editorState={ContentView}
                  defaultEditorState={DataInfoEdit}
                  onEditorStateChange={(e) => handleEditorChange(e)}
                />
              </div>
            </div>
            {/* <div className="col-md-12">
              <div className="form_fields mb-4">
                <label htmlFor="footer" className="form_label">
                  Footer
                </label>
                <textarea
                  key="footer"
                  name="footer"
                  onChange={onChange}
                  value={Settings.footer || ''}
                  rows="5"
                  required
                  placeholder="Email footer"
                />
              </div>
            </div> */}

            <div className="col-md-12 cancel_btn">
              <Link className="btn_cs sky_border_btn" to="/home">
                Cancel
              </Link>
              <div className="add_qus_submit d-flex gap-3 justify-content-end">
                <button
                  type="submit"
                  className="btn_cs sky_bg_btn"
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEmailLoginOtpTemplate;
