import React, { useEffect, useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ExamContext from "../context/exam/ExamContext";
import axios from 'axios';
import { Button, Table, DatePicker, Input } from 'antd';

import filter_icon from "../more-filters.png";

const { RangePicker } = DatePicker;

const PendingExamsList = (props) => {

	let navigate = useNavigate();
	const context = useContext(ExamContext);
	const [filterIncompleteData, setFilterIncompleteData] = useState();
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const dropdownRef = useRef(null);
	const [filterData, setFilterData] = useState({
		name: '',
		startDate: '',
		endDate: '',
		result: '',
	});
	const dateFormat = 'DD-MM-YYYY';
	const [page, setPage] = useState(1);
	const [filterTable, setFilterTable] = useState(null);
	const { pendingExamsList, getPendingExamsList } = context;
	useEffect(() => {

		if (sessionStorage.getItem("token") === null || sessionStorage.getItem("user-type") === "admin") { getPendingExamsList(); } else { navigate("/"); }

	}, []);

	const toggleDropdown = () => {
		console.log('s')
		setDropdownOpen(!isDropdownOpen);
	};
	const datePickerChange = (value, mode) => {

		setFilterData({ ...filterData, startDate: mode[0], endDate: mode[1] })
	}

	const onChangeHandler = (e) => {

		setFilterData({ ...filterData, name: e.target.value })
	}

	const getFilteredDataRest = () => {

		setFilterData({
			name: '',
			startDate: '',
			endDate: '',
			result: '',
		});
		setFilterIncompleteData('')

	}

	const getFilteredData = () => {


		axios({
			method: 'post',
			url: `/api/exam/getpendingexamswithfilter/`,
			data: {
				name: filterData.name,
				startDate: filterData.startDate,
				endDate: filterData.endDate,
				//result: filterData.result
			}

		})
			.then(function (response) {

				if (response.data.success === true) {
					console.log(response.data.data)
					setFilterIncompleteData(response.data.data)

				} else {


				}

			})
			.catch(function (error) {
				// Handle error
				console.error('Error sending email data:', error);
			});
	}


	const onTableSearch = (value) => {
		let filterTable = pendingExamsList.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())
			)
		);

		setFilterIncompleteData(filterTable);

	};

	const [filteredInfo, setFilteredInfo] = useState({});
	const [sortedInfo, setSortedInfo] = useState({});
	const handleChangetable = (pagination, filters, sorter) => {
		console.log('Various parameters', pagination, filters, sorter);
		setFilteredInfo(filters);
		setSortedInfo(sorter);
	};
	const clearFilters = () => {
		setFilteredInfo({});
	};
	const clearAll = () => {
		setFilteredInfo({});
		setSortedInfo({});
	};
	const setAgeSort = () => {
		setSortedInfo({
			order: 'descend',
			columnKey: 'age',
		});
	};


	const columns = [

		{
			title: 'S.No',
			dataIndex: 'key',
			key: 'no',
			width: '7%',
			render: (value, item, index) => (page - 1) * 10 + index + 1
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '20%',
			filteredValue: filteredInfo.name || null,
			onFilter: (value, record) => record.name.includes(value),
			sorter: (a, b) => a.name.length - b.name.length,
			sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
			ellipsis: true,
		},

		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			width: '15%',
			filteredValue: filteredInfo.phone || null,
			onFilter: (value, record) => record.phone.includes(value),
			sorter: (a, b) => a.phone.length - b.phone.length,
			sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
			ellipsis: true,
		},

		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: '15%',
			filteredValue: filteredInfo.email || null,
			onFilter: (value, record) => record.email.includes(value),
			sorter: (a, b) => a.email.length - b.email.length,
			sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
			ellipsis: true,
		},

		{
			title: 'Exam name',
			dataIndex: 'exam_name',
			key: 'exam_name',
			width: '15%',
			filteredValue: filteredInfo.exam_name || null,
			onFilter: (value, record) => record.exam_name.includes(value),
			sorter: (a, b) => a.exam_name.length - b.exam_name.length,
			sortOrder: sortedInfo.columnKey === 'exam_name' ? sortedInfo.order : null,
			ellipsis: true,
		},
		{
			title: 'Discard',
			dataIndex: 'discard',
			key: 'discard',
			width: '10%'
		},
		{
			title: 'Time',
			dataIndex: 'time',
			key: 'time',
			width: '10%',
			onFilter: (value, record) => record.status.indexOf(value) === 0,
		},

		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			width: '10%',
			filteredValue: filteredInfo.date || null,
			onFilter: (value, record) => record.date.includes(value),
			sorter: (a, b) => moment(a.date, 'DD-MM-YYYY').unix() - moment(b.date, 'DD-MM-YYYY').unix(),
			sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
			ellipsis: true,
		},

	]


	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const onSelectChange = (newSelectedRowKeys) => {

		setSelectedRowKeys(newSelectedRowKeys);

	};

	const rowSelection = {

		selectedRowKeys,

		onChange: onSelectChange,
	};

	const hasSelected = selectedRowKeys.length > 0;



	return (

		<>
			<div className="board_title_data d-flex align-items-center justify-content-between gap-3">
				<div className="board_title d-flex align-items-center gap-3">
					<h2>Incomplete Exams</h2>
				</div>
				<div className="dropdowna_start board_form_filter d-flex gap-3 align-items-center">
					<div className="mobile_all_filters">
						<div className={`borad_more_filters dropdown remove_cret ${isDropdownOpen ? 'show' : ''}`} ref={dropdownRef}>
							<a
								className="more_filters dropdown-toggle"
								href="#"
								role="button"
								aria-expanded={isDropdownOpen}
								onClick={toggleDropdown}
							>
								<img src={filter_icon} alt="filters" />
							</a>
							<ul className={`dropdown-menu ie_dropdown ${isDropdownOpen ? 'show' : ''}`} style={{
								position: 'absolute',
								inset: 'auto auto auto auto',
								margin: '0px',
								transform: 'translate3d(-398.4px, 21.6px, 0px)'
							}}>
								<span class="close-icon"><i class="fa-solid fa-xmark" aria-hidden="true"></i></span>
								<li className="mobile_serch d-block d-sm-none">
									<div className="borad_srch_form">
										<Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
									</div>
								</li>
								<li className="date_range_filter_li">
									<div className="date_range_filter_wrap">
										<label>Date range</label>
										<div className="date_range_filter">
											<RangePicker name="date" format={dateFormat} onChange={datePickerChange} />
											<div className="date_range_filter_btn d-flex gap-2 mt-3 justify-content-end">
												<Button className="btn_cs sky_bg_btn" type="primary" onClick={getFilteredDataRest}>Reset</Button>
												<Button className="btn_cs sky_border_btn" type="primary" onClick={getFilteredData}>Search</Button>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>

				</div>
			</div>
			<div className="main_wraper_pages mb-5">
				<div className="data_wraper_pages">

					<div className="record_search_fm_totl justify-content-end">
						<span className='total_record'>	{hasSelected ? `Selected items ${selectedRowKeys.length} ` : ''}</span>

						<span className="total_record">Total: {filterIncompleteData ? filterIncompleteData.length : pendingExamsList.length}</span>
						<div className="borad_srch_form d-none d-sm-block">
							<Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
						</div>
					</div>
					<div className="data_table same_table_als">
						<Table columns={columns} pagination={{
							onChange(current) {
								setPage(current);
							}
						}} rowSelection={rowSelection} dataSource={(filterIncompleteData) ? filterIncompleteData : pendingExamsList} onChange={handleChangetable} />

					</div>
				</div>
			</div >

		</>

	)

}



export default PendingExamsList

