import React, { useEffect, useRef, useContext, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';

const ResultEmailTemplate = (props) => {
  let navigate = useNavigate();
  const [Settings, setSettings] = useState({});
  const [preview, setPreview] = useState(null);
  const getResultEmailSettings = async (resultemailtemplates) => {
    const response = await fetch(`/api/emailsettings/resultemailsettings`, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'auth-token': sessionStorage.getItem('token'),
      },
    });

    const json = await response.json();

    setSettings(json.settings);
  };

  const editresultEmailTemplateSettings = async (
    id,

    send_result_email,

    send_result_cc,

    email_logo_path
  ) => {
    // API Call

    const formData = new FormData();

    formData.append('send_result_email', send_result_email);

    formData.append('send_result_cc', send_result_cc);

    formData.append('email_logo_path', email_logo_path);

    axios
      .put('/api/emailsettings/resultemailsettingsupdate/' + id, formData)

      .then((res) => {
        console.log(res);

        if (res.data.success === true) {
          props.showAlert(res.data.message, 'success');
        } else {
          props.showAlert(res.data.message, 'danger');
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = (e) => {
    if (Object.values(Settings).length === 0) {
      props.showAlert('Please update some fields value', 'danger');
    } else if (Settings.send_result_cc == '') {
      props.showAlert('Email field is empty', 'danger');
    } else {
      editresultEmailTemplateSettings(
        Settings['_id'],

        Settings.send_result_email,

        Settings.send_result_cc,

        Settings.email_logo_path
      );
    }
  };

  const onChange = (e) => {
    setSettings({ ...Settings, [e.target.name]: e.target.value });
  };

  const onChangeSwitch = (e) => {
    setSettings({ ...Settings, send_result_email: e.target.value });
  };

  const handlePhoto = (e) => {
    setSettings({ ...Settings, email_logo_path: e.target.files[0] });

    const selectedImage = e.target.files[0];

    // Show image preview

    if (selectedImage) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(selectedImage);
    }
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('token') === null ||
      sessionStorage.getItem('user-type') === 'admin'
    ) {
    } else {
      navigate('/');
    }

    getResultEmailSettings('resultemailtemplates');

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div class="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div class="board_title d-flex align-items-center gap-3">
          <h2>Email Template Settings</h2>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div class="row">
            <div class="col-md-6">
              <div class="form_fields mb-4">
                <label for="title" class="form_label">
                  CC Email
                </label>
                <input
                  type="text"
                  key="send_result_cc"
                  name="send_result_cc"
                  onChange={onChange}
                  defaultValue={Settings['send_result_cc']}
                  required
                  placeholder="Email address"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form_fields mb-4">
                <label for="title" class="form_label">
                  Send Email
                </label>
                <select name="exam_time" onChange={onChangeSwitch}>
                  <option
                    value="true"
                    selected={
                      Settings.send_result_email === true ? true : false
                    }
                  >
                    ON
                  </option>
                  <option
                    value="false"
                    selected={
                      Settings.send_result_email === false ? true : false
                    }
                  >
                    OFF
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form_fields mb-4">
                <label for="title" class="form_label">
                  Logo
                </label>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  name="file"
                  onChange={handlePhoto}
                />
                <img
                  src={preview ? preview : Settings['email_logo_path']}
                  alt="Preview"
                  className="image-preview"
                />
              </div>
            </div>
            <div class="col-md-12 cancel_btn">
              <Link className="btn_cs sky_border_btn" to="/home">
                Cancel
              </Link>
              <div class="add_qus_submit d-flex gap-3 justify-content-end">
                <button
                  type="submit"
                  className="btn_cs sky_bg_btn"
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultEmailTemplate;
