import { EyeOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal, Button, Input, Table, DatePicker, Spin } from 'antd';
import resultwithexamdetail from '../context/examresults/ExamResultsContext';
import { useNavigate, useParams } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from 'axios';
import filter_icon from '../more-filters.png';
import moment from 'moment';
const ExamWiseResultDetails = (props) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';

  const fileName = 'Result Report'; // here enter filename for your excel file

  const contextexamresult = useContext(resultwithexamdetail);

  const { ExamResultsData, getexamwiseresultdetails, ExamName } =
    contextexamresult;
  const [filteredData, setFilteredData] = useState();
  const [filterDataTotalFail, setFilterDataTotalFail] = useState();
  const [filterDataTotalPass, setFilterDataTotalPass] = useState();
  const [filterDataTotalResult, setFilterDataTotalResult] = useState();
  const [filterTable, setFilterTable] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showsping, setshowsping] = useState(false);
  const [Modelcategorydata, setModelcategorydata] = useState({});
  const [dataInfo, setDataInfo] = useState({});
  const [page, setPage] = useState(1);
  let navigate = useNavigate();
  const params = useParams();
  const { RangePicker } = DatePicker;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dateFormat = 'DD-MM-YYYY';
  const dropdownRef = useRef(null);
  const [selectedResult, setSelectedResult] = useState('');
  const [filterData, setFilterData] = useState({
    startDate: '',
    endDate: '',
    result: '',
  });
  const viewdatahandler = (data) => {
    //console.log(data)
    setDataInfo(data);

    setIsModalOpen(true);

    setModelcategorydata(
      Object.entries(data.categoryResult)?.map(([key, value]) => (
        <>
          <tr>
            <td>
              <strong>{value.name} : </strong>
            </td>
            <td>
              {value.correctAnswer} / {value.totalweightage}
            </td>
          </tr>
        </>
      ))
    );
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChangetable = (pagination, filters, sorter) => {
    //console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'no',
      width: '5%',
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      key: 'name',
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      filteredValue: filteredInfo.email || null,
      onFilter: (value, record) => record.email.includes(value),
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      width: '10%',
      filteredValue: filteredInfo.phone || null,
      onFilter: (value, record) => record.phone.includes(value),
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Exam Date',
      dataIndex: 'date',
      width: '10%',
      filteredValue: filteredInfo.date || null,
      onFilter: (value, record) => record.date.includes(value),
      sorter: (a, b) =>
        moment(a.date, 'DD-MM-YYYY').unix() -
        moment(b.date, 'DD-MM-YYYY').unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Result',
      dataIndex: 'result',
      width: '10%',
      render: (result) => (
        <span className={result === 'fail' ? 'fail' : 'pass'}>{result}</span>
      ),
      filters: [
        { text: 'Pass', value: 'pass' },
        { text: 'Fail', value: 'fail' },
      ],
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.result.startsWith(value),
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      width: '10%',
      filteredValue: filteredInfo.percentage || null,
      onFilter: (value, record) => record.percentage.includes(value),
      sorter: (a, b) => parseFloat(a.percentage) - parseFloat(b.percentage),
      sortOrder:
        sortedInfo.columnKey === 'percentage' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      width: '10%',
      filteredValue: filteredInfo.score || null,
      onFilter: (value, record) => record.score.includes(value),
      sorter: (a, b) => parseFloat(a.score) - parseFloat(b.score),
      sortOrder: sortedInfo.columnKey === 'score' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Action',
      align: '-webkit-center',
      button: true,
      render: (data) => (
        <div className="action_dropdown dropdown dropstart">
          <a
            className="action_toggle dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </a>
          <ul className="dropdown-menu">
            <li>
              <a onClick={() => viewdatahandler(data)}>
                <i className="fa-regular fa-eye"></i>View
              </a>
            </li>
          </ul>
        </div>
      ),
      width: '10%',
    },
  ];

  useEffect(() => {
    if (
      sessionStorage.getItem('token') === null ||
      sessionStorage.getItem('user-type') == 'admin'
    ) {
      getexamwiseresultdetails(params.eid);
    } else {
      navigate('/');
    }
  }, [params.eid]);

  const onTableSearch = (value) => {
    let filterTable;
    if (filteredData) {
      filterTable = filteredData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())
        )
      );
    } else {
      filterTable = ExamResultsData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())
        )
      );
    }

    setFilterTable(filterTable);
  };

  const [selectedRowData, setSelectedRowData] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    console.log('Selected Row Keys changed: ', newSelectedRowKeys);

    console.log('New row selected', selectedRows);

    setSelectedRowData(selectedRows);

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowData,

    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const sendResultToCandidates = async (data) => {
    // Send a POST request
    axios({
      method: 'post',
      url: '/api/sendresultoveremail/sendresulttoselectedcondidate',
      data: {
        resultData: data,
      },
    })
      .then(function (response) {
        setshowsping(false);
        if (response.data.success === true) {
          props.showAlert(response.data.message, 'success');
        } else {
          props.showAlert(response.data.message, 'danger');
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error('Error:', error);
      });
  };

  const sendResultEmail = () => {
    setshowsping(true);
    sendResultToCandidates(selectedRowData);
    //console.log(resultSendMessage)
  };

  const datePickerChange = (value, mode) => {
    setFilterData({ ...filterData, startDate: mode[0], endDate: mode[1] });
  };

  const onChangeHandler = (e) => {
    setFilterData({ ...filterData, name: e.target.value });
  };

  const getFilteredDataRest = () => {
    setFilterData({
      name: '',
      startDate: '',
      endDate: '',
      result: '',
    });
  };

  const selectResultOnChange = (e) => {
    const value = e.target.value;
    setSelectedResult(value);
    //console.log("Selected Result:", value);
  };

  const getFilteredData = () => {
    axios({
      method: 'post',
      url: `/api/examwiseresultsdata/fetchallexamtypesresultswithfilter`,
      data: {
        examId: params.eid,
        startDate: filterData.startDate,
        endDate: filterData.endDate,
        result: selectedResult,
      },
    })
      .then(function (response) {
        if (response.data.success === true) {
          console.log(response.data);
          setFilteredData(response.data.data);
          setFilterDataTotalFail(response.data.totalFail);
          setFilterDataTotalPass(response.data.totalPass);
          setFilterDataTotalResult(response.data.totalResults);
        }
      })
      .catch(function (error) {
        // Handle error
        console.error('Error sending email data:', error);
      });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div class="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div class="board_title d-flex align-items-center gap-3">
          <h2>{ExamName.name}</h2>
        </div>
        <div className="board_form_filter d-flex gap-3 align-items-center">
          <div className="mobile_all_filters d-block">
            <div
              className={`borad_more_filters dropdown remove_cret ${
                isDropdownOpen ? 'show' : ''
              }`}
              ref={dropdownRef}
            >
              <a
                className="more_filters dropdown-toggle"
                href="#"
                role="button"
                aria-expanded={isDropdownOpen}
                onClick={toggleDropdown}
              >
                <img src={filter_icon} alt="filters" />
              </a>
              <ul
                className={`dropdown-menu ie_dropdown ${
                  isDropdownOpen ? 'show' : ''
                }`}
                style={{
                  position: 'absolute',
                  inset: 'auto auto auto auto',
                  margin: '0px',
                  transform: 'translate3d(-398.4px, 21.6px, 0px)',
                }}
              >
                <span
                  onClick={() => setDropdownOpen(false)}
                  className="close-icon"
                >
                  <i class="fa-solid fa-xmark"></i>
                </span>
                <li className="mobile_serch d-block d-sm-none">
                  <div className="borad_srch_form">
                    <Input.Search
                      placeholder="Search"
                      enterButton
                      onChange={onTableSearch}
                    />
                  </div>
                </li>
                <li className="date_range_filter_li">
                  <div className="date_range_filter_wrap">
                    <label>Date range</label>

                    <div className="date_range_filter">
                      <RangePicker
                        name="date"
                        format={dateFormat}
                        onChange={datePickerChange}
                      />
                      <div className="exmWise_res form_fields mt-3">
                        <label className="form_label">Show result</label>
                        <select
                          className="form_fields"
                          name="show_result"
                          onChange={selectResultOnChange}
                        >
                          <option value="">-- Select one --</option>
                          <option value="pass">Pass</option>
                          <option value="fail">Fail</option>
                        </select>
                      </div>
                      <div className="date_range_filter_btn d-flex gap-2 mt-3 justify-content-end">
                        <Button
                          className="btn_cs sky_bg_btn"
                          type="primary"
                          onClick={getFilteredDataRest}
                        >
                          Reset
                        </Button>
                        <Button
                          className="btn_cs sky_border_btn"
                          type="primary"
                          onClick={getFilteredData}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <button
            className="btn_cs sky_border_btn"
            onClick={(e) => exportToCSV(ExamResultsData, fileName)}
          >
            Export
          </button>
          <button
            className="btn_cs sky_bg_btn"
            type="primary"
            onClick={(e) => sendResultEmail()}
            disabled={showsping}
          >
            {showsping ? <Spin size="small" /> : 'Send Result'}
          </button>
        </div>
      </div>

      <div className="data_wraper_pages mb-5">
        <div className="se_details">
          <div className="row m-0">
            <div class="col-md-4">
              <div class="title_details">
                <strong>Total:</strong>
                {filteredData ? filterDataTotalResult : ExamName.total}
              </div>
            </div>
            <div class="col-md-4">
              <div class="title_details">
                <strong>Pass:</strong>
                {filteredData ? filterDataTotalPass : ExamName.pass}
              </div>
            </div>
            <div class="col-md-4">
              <div class="title_details">
                <strong>Fail:</strong>
                {filteredData ? filterDataTotalFail : ExamName.fail}
              </div>
            </div>
          </div>
        </div>
        <div className="record_search_fm_totl justify-content-end">
          <span className="total_record">
            {' '}
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
          <div className="borad_srch_form d-none d-sm-block">
            <Input.Search
              placeholder="Search"
              enterButton
              onChange={onTableSearch}
            />
          </div>
        </div>
        {filteredData ? (
          <Table
            pagination={{
              onChange(current) {
                setPage(current);
              },
            }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filterTable ? filterTable : filteredData}
            onChange={handleChangetable}
          />
        ) : (
          <Table
            pagination={{
              onChange(current) {
                setPage(current);
              },
            }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filterTable ? filterTable : ExamResultsData}
            onChange={handleChangetable}
          />
        )}
      </div>
      <Modal
        className="EWresult"
        width={600}
        title=" "
        open={isModalOpen}
        footer={null}
        onOk={handleOk}
        okText=""
        cancelText="Close"
        onCancel={handleCancel}
      >
        <table id="currentuserinfopdf" style={{ width: '100%' }}>
          <tr>
            <td>
              <div className="EWresult_data">
                <h3>Student details</h3>
                <table
                  class="mb-3"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  border="0"
                >
                  <tr>
                    <td bgcolor="#f9f9f9" colspan="2">
                      <strong>Name:</strong> {dataInfo.name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Email:</strong> {dataInfo.email}
                    </td>
                    <td>
                      <strong>Score:</strong> {dataInfo.score} /{' '}
                      {dataInfo.max_marks}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Phone:</strong> {dataInfo.phone}
                    </td>
                    <td>
                      <strong>Percentage:</strong> {dataInfo.percentage}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Location:</strong> {dataInfo.location}
                    </td>
                    <td>
                      <strong>Result:</strong>{' '}
                      <span
                        className={dataInfo.result === 'pass' ? 'pass' : 'fail'}
                      >
                        {dataInfo.result}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Center:</strong> {dataInfo.center}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="EWresult_data">
                <h3>Category Score</h3>
                <table
                  class="mb-3"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  border="0"
                >
                  {Modelcategorydata}
                </table>
              </div>
            </td>
          </tr>
        </table>
      </Modal>
    </>
  );
};

export default ExamWiseResultDetails;
