import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import examContext from '../context/exam/ExamContext';
import ImageUploading from 'react-images-uploading';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';
import { Button, Form, Input, Modal, Table, Tag } from 'antd';
import qusImg from '../question.png';
import markImg from '../check-mark.png';

const EditExamType = (props) => {
  let navigate = useNavigate();
  const context = useContext(examContext);
  const [DataInfo, setDataInfo] = useState({});
  const [DataInfoEdit, setDataInfoEdit] = useState();
  const [CategoriesList, setCategoriesList] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [inputFields, setInputFields] = useState([]);
  const [status, setStatus] = useState(DataInfo.status);
  const [Percentage, setPercentage] = useState(DataInfo.percentage);
  const [Image, setImage] = useState(DataInfo.image);
  const [Examtime, setExamtime] = useState(DataInfo.exam_time);
  const [ShowResult, setShowresult] = useState(DataInfo.result_show);
  const [category_id, setCategory_id] = useState('');
  const [Content, setContent] = useState();
  const [imageName, setImageName] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewName, setPreviewName] = useState(null);
  const [ContentView, setContentView] = useState(() =>
    EditorState.createEmpty()
  );

  const params = useParams();
  const percentageArr = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
    100,
  ];
  const [locations, setLocations] = useState([]);
  const [centers, setCenters] = useState([]);

  useEffect(() => {
    if (
      sessionStorage.getItem('token') === null ||
      sessionStorage.getItem('user-type') === 'admin'
    ) {
      getCategoryData();
      getExamsById(params.eid);
    } else {
      navigate('/');
    }
  }, []);

  const getExamsById = async (e) => {
    // API Call

    const response = await fetch(`/api/exam/fetchexambyid/${e}`, {
      method: 'GET',

      headers: {
        'Cache-Control': 'no-cache',

        'Content-Type': 'application/json',

        'auth-token': sessionStorage.getItem('token'),
      },
    });

    const json = await response.json();

    console.log(json.exam[0]);
    setDataInfoEdit(json.exam[0]);

    setTotalMarks(json.exam[0].total_marks);

    setTotalQuestions(json.exam[0].total_questions);
    setLocations(json.exam[0].locations);
    setCenters(json.exam[0].centers);

    setContent(json.exam[0].content);
    setExamtime(json.exam[0].exam_time);
    setPercentage(json.exam[0].percentage);
    setInputFields(json.exam[0].category);
    setImage(json.exam[0].image);
    let filename = json.exam[0].image.substring(
      json.exam[0].image.lastIndexOf('/') + 1
    );
    setImageName(filename);
    const htmlContent = json.exam[0].content;
    const contentBlock = htmlToDraft(htmlContent);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    setContentView(EditorState.createWithContent(contentState));
    setIsEditModalOpen(true);
  };

  const getCategoryData = async (e) => {
    const response = await fetch(
      '/api/categories/getCategoryListWithWeightage',
      {
        method: 'GET',

        headers: {
          'Cache-Control': 'no-cache',

          'Content-Type': 'application/json',

          'auth-token': sessionStorage.getItem('token'),
        },
      }
    );

    const json = await response.json();
    // console.log(json.data);
    setCategoriesList(json.data);
  };

  const handleChange2 = (e) => {};
  const handleChangeImage = (imageList) => {
    setImage(imageList[0]['data_url']);
  };

  const handleEditorChange = (content) => {
    setContentView(content);
    let check_content = convertToHTML(content.getCurrentContent());
    setContent(check_content);
  };

  //second function

  const handleChange = (i, e) => {
    const passNoOfQuestions = CategoriesList.find(
      (item) => item.id == inputFields[i]['category_id']
    );

    if (!inputFields[i]['cate_weightage']) {
      toast.error(`Add category and weightage first to add no. of questions.`);
    } else {
      if (passNoOfQuestions && passNoOfQuestions.categoryWeightage) {
        passNoOfQuestions.categoryWeightage.forEach((weighatage) => {
          if (weighatage.hasOwnProperty(inputFields[i]['cate_weightage'])) {
            if (
              e.target.value <= weighatage[inputFields[i]['cate_weightage']]
            ) {
              inputFields[i]['category_number'] = parseInt(e.target.value);
            } else {
              toast.error(
                `Please enter a value less than or equal to ${
                  weighatage[inputFields[i]['cate_weightage']]
                }`
              );
              inputFields[i]['category_number'] = null;
            }
          }
        });
      }
      let totalQuestions = 0;
      let totalMarks = 0;
      inputFields.forEach((item) => {
        totalQuestions += parseInt(item.category_number);
        totalMarks +=
          parseInt(item.category_number) * parseInt(item.cate_weightage);
      });
      setTotalQuestions(totalQuestions);
      setTotalMarks(totalMarks);
    }
  };

  //thirde function
  const handleChangeCats = (i, e) => {
    let MaxQuestion =
      e.target.options[e.target.selectedIndex].getAttribute('totalquestion');
    document.getElementById(i).setAttribute('max', MaxQuestion);
    inputFields[i][e.target.name] = e.target.value;
    inputFields[i]['category_number'] = 0;
    inputFields[i]['category_id'] = e.target.value;
    setCategory_id(e.target.value);
  };

  const validate = () => {
    const errors = {};
    const { formValues } = this.state;
    console.log(this.state.formValues, 'd');
    let check2 = true;
    this.state.formValues.map((element) => (
      <>
        {element.category_id === ''
          ? (check2 = false)
          : '' || element.number_of_questions === ''
          ? (check2 = false)
          : ''}
      </>
    ));

    const currentPackage = parseInt(sessionStorage.getItem('adminPlanId'));
    if (currentPackage === 1) {
      if (!formValues.exam) {
        errors.exam = 'Name is required';
      }

      if (!formValues.passcode) {
        errors.passcode = 'Passcode is required';
      }

      if (!formValues.content) {
        errors.content = 'Content is required';
      }

      if (!formValues.image) {
        errors.image = 'Image is required';
      }

      if (!formValues.examtime) {
        errors.examtime = 'Exam time is required';
      }

      if (!formValues.percentage) {
        errors.percentage = 'Percentage is required';
      }

      if (!formValues.status) {
        errors.status = 'Status is required';
      }

      if (check2 === false) {
        errors.category = 'Category is required';
      }
    } else {
      if (!formValues.exam) {
        errors.exam = 'Name is required';
      }

      if (!formValues.passcode) {
        errors.passcode = 'Passcode is required';
      }

      if (!formValues.content) {
        errors.content = 'Content is required';
      }

      if (!formValues.image) {
        errors.image = 'Image is required';
      }

      if (!formValues.examtime) {
        errors.examtime = 'Exam time is required';
      }

      if (!formValues.percentage) {
        errors.percentage = 'Percentage is required';
      }

      if (!formValues.status) {
        errors.status = 'Status is required';
      }

      if (formValues.check === false) {
        errors.category = 'Category is required';
      }

      if (!formValues.locations) {
        errors.percentage = 'Locations is required';
      }

      if (!formValues.centers) {
        errors.status = 'Centers is required';
      }
    }

    return errors;
  };

  const handleOkEdit = async (e) => {
    e.preventDefault();
    const formdata = new FormData(document.getElementById('editExamId'));
    console.log(formdata);
    formdata.append('content', Content);

    formdata.append('locations', locations);
    formdata.append('centers', centers);
    const response = await fetch('/api/exam/update-exam', {
      method: 'post',
      body: formdata,
    });

    let data = await response.json();
    if (data.status === 'success') {
      props.showAlert(data.message, 'success');
      setIsEditModalOpen(false);
      //getExams();
    } else {
      props.showAlert(data.message, 'danger');
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ firstName: '', lastName: '' });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    let totalQuestions = 0;
    let totalMarks = 0;
    values.forEach((item) => {
      totalQuestions += parseInt(item.category_number);
      totalMarks +=
        parseInt(item.category_number) * parseInt(item.cate_weightage);
    });
    setTotalQuestions(totalQuestions);
    setTotalMarks(totalMarks);
  };

  //fourth function
  const handleCategoryWeitage = (index, e) => {
    inputFields[index]['cate_weightage'] = parseInt(e.target.value);
  };
  const handleInputKeyDown = (e) => {
    const enteredText = e.target.value;
    // Check if the user has entered a separator (e.g., comma or space)
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const newLocation = enteredText.trim(); // Remove leading/trailing spaces
      if (newLocation !== '') {
        setLocations([...locations, newLocation]);
        e.target.value = ''; // Clear the input field
      }
    }
  };

  const handleInputKeyDownCenter = (e) => {
    const enteredText = e.target.value;
    // Check if the user has entered a separator (e.g., comma or space)
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const newCenter = enteredText.trim(); // Remove leading/trailing spaces
      if (newCenter !== '') {
        setCenters([...centers, newCenter]);
        e.target.value = ''; // Clear the input field
      }
    }
  };

  const handleCenterRemove = (centerToRemove) => {
    setCenters((prevCenters) =>
      prevCenters.filter((center) => center !== centerToRemove)
    );
  };
  const handleLocationRemove = (locationToRemove) => {
    setLocations((prevLocations) =>
      prevLocations.filter((location) => location !== locationToRemove)
    );
  };

  // On file select (from the pop up)
  const onFileChange = (event) => {
    setImage(event.target.files[0]);
    setPreviewName(event.target.files[0].name);

    setPreviewUrl(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Edit exam</h2>
        </div>
      </div>
      <div className="addExamType_main_wrap mb-5">
        <Form method="post" action="" id="editExamId">
          <div className="row">
            <div className="col-lg-12 col-md-12 ">
              {DataInfoEdit ? (
                <div className="">
                  <div className="data_wraper_pages_outer mb-4">
                    <div className="data_wraper_pages">
                      <div className="board_title ">
                        <h2>Exam details</h2>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="form_fields mb-4">
                            <label htmlFor="title" className="form_label">
                              Name
                            </label>

                            <input
                              type="text"
                              key={DataInfoEdit.name}
                              name="exam_name"
                              defaultValue={DataInfoEdit.name}
                              onChange={handleChange2}
                            />

                            <input
                              type="hidden"
                              name="exam_id"
                              defaultValue={DataInfoEdit.id}
                            />
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-12">
                          <div className="form_fields mb-4">
                            <label htmlFor="status" className="form_label">
                              {' '}
                              Status{' '}
                            </label>

                            <select
                              className="form-select"
                              name="status"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option
                                selected={
                                  DataInfoEdit.status === 'Active'
                                    ? true
                                    : false
                                }
                                value="Active"
                              >
                                Active
                              </option>

                              <option
                                selected={
                                  DataInfoEdit.status === 'Inactive'
                                    ? true
                                    : false
                                }
                                value="Inactive"
                              >
                                Inactive
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-12">
                          <div className="form_fields mb-4">
                            <label>Total marks</label>
                            <span className="fld_sm_hity">{totalMarks}</span>
                          </div>
                          <input
                            type="hidden"
                            name="total_marks"
                            value={totalMarks}
                          ></input>
                        </div>
                        <div className="col-lg-2 col-md-12">
                          <div className="form_fields mb-4">
                            <label>Total questions</label>
                            <span className="fld_sm_hity">
                              {totalQuestions}
                            </span>
                          </div>
                          <input
                            type="hidden"
                            name="total_questions"
                            value={totalQuestions}
                          ></input>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="form_fields mb-4">
                            <label htmlFor="title" className="form_label">
                              Passcode
                            </label>

                            <input
                              type="text"
                              key={DataInfoEdit.passcode}
                              name="exam_passcode"
                              defaultValue={DataInfoEdit.passcode}
                              onChange={handleChange2}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                          <div className="form_fields mb-4">
                            <label className="form_label">Show result</label>

                            <select
                              name="result_exam"
                              onChange={(e) => setShowresult(e.target.value)}
                            >
                              <option key="0" value="">
                                -- Please one --
                              </option>

                              <option
                                key="true"
                                value="true"
                                selected={
                                  DataInfoEdit.result_show === true
                                    ? true
                                    : false
                                }
                              >
                                Yes
                              </option>

                              <option
                                key="false"
                                value="false"
                                selected={
                                  DataInfoEdit.result_show === false
                                    ? true
                                    : false
                                }
                              >
                                No (Hide)
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-12">
                          <div className="form_fields mb-4">
                            <label htmlFor="title" className="form_label">
                              Exam time
                            </label>

                            <select
                              name="exam_time"
                              title={
                                DataInfoEdit.examresultdata != ''
                                  ? 'Edit Disabled - One Exam Already Submitted'
                                  : ''
                              }
                              className={
                                DataInfoEdit.examresultdata != ''
                                  ? 'disable_custom'
                                  : ''
                              }
                              //disabled={DataInfoEdit.examresultdata != "" ? true : false}
                              defaultValue={Examtime}
                              onChange={(e) => setExamtime(e.target.value)}
                            >
                              <option key="0" value="">
                                {' '}
                                -- Please one --
                              </option>

                              <option
                                key="10"
                                value="10"
                                selected={Examtime === 10 ? true : false}
                              >
                                10 minutes
                              </option>

                              <option
                                key="20"
                                value="20"
                                selected={Examtime === 20 ? true : false}
                              >
                                20 minutes
                              </option>

                              <option
                                key="30"
                                value="30"
                                selected={Examtime === 30 ? true : false}
                              >
                                30 minutes
                              </option>

                              <option
                                key="40"
                                value="40"
                                selected={Examtime === 40 ? true : false}
                              >
                                40 minutes
                              </option>

                              <option
                                key="50"
                                value="50"
                                selected={Examtime === 50 ? true : false}
                              >
                                50 minutes
                              </option>

                              <option
                                key="60"
                                value="60"
                                selected={Examtime === 60 ? true : false}
                              >
                                60 minutes
                              </option>

                              <option
                                key="70"
                                value="70"
                                selected={Examtime === 70 ? true : false}
                              >
                                70 minutes
                              </option>

                              <option
                                key="80"
                                value="80"
                                selected={Examtime === 80 ? true : false}
                              >
                                80 minutes
                              </option>

                              <option
                                key="90"
                                value="90"
                                selected={Examtime === 90 ? true : false}
                              >
                                90 minutes
                              </option>

                              <option
                                key="100"
                                value="100"
                                selected={Examtime === 100 ? true : false}
                              >
                                100 minutes
                              </option>

                              <option
                                key="110"
                                value="110"
                                selected={Examtime === 110 ? true : false}
                              >
                                110 minutes
                              </option>

                              <option
                                key="120"
                                value="120"
                                selected={Examtime === 120 ? true : false}
                              >
                                120 minutes
                              </option>

                              <option
                                key="130"
                                value="130"
                                selected={Examtime === 130 ? true : false}
                              >
                                130 minutes
                              </option>

                              <option
                                key="140"
                                value="140"
                                selected={Examtime === 140 ? true : false}
                              >
                                140 minutes
                              </option>

                              <option
                                key="150"
                                value="150"
                                selected={Examtime === 150 ? true : false}
                              >
                                150 minutes
                              </option>

                              <option
                                key="160"
                                value="160"
                                selected={Examtime === 160 ? true : false}
                              >
                                160 minutes
                              </option>

                              <option
                                key="170"
                                value="170"
                                selected={Examtime === 170 ? true : false}
                              >
                                170 minutes
                              </option>

                              <option
                                key="180"
                                value="180"
                                selected={Examtime === 180 ? true : false}
                              >
                                180 minutes
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12">
                          <div className="form_fields mb-4">
                            <label className="form_label">
                              Passing percentage(%)
                            </label>
                            <select
                              name="percentage"
                              defaultValue={Percentage}
                              onChange={(e) => setPercentage(e.target.value)}
                              title={
                                DataInfoEdit.examresultdata != ''
                                  ? 'Edit Disabled - One Exam Already Submitted'
                                  : ''
                              }
                              className={
                                DataInfoEdit.examresultdata != ''
                                  ? 'disable_custom'
                                  : ''
                              }
                            >
                              <option key="0" value="">
                                {' '}
                                -- Please one --
                              </option>
                              {percentageArr.map((item, index) => (
                                <option
                                  key={index}
                                  value={item}
                                  selected={Percentage === item ? true : false}
                                >
                                  {item}%
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="data_wraper_pages_outer mb-4">
                    <div className="data_wraper_pages">
                      <div className="board_title ">
                        <h2>Exam start page</h2>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form_fields mb-4">
                            <label className="form_label">Instructions :</label>

                            <Editor
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                              }}
                              editorState={ContentView}
                              defaultEditorState={DataInfoEdit.content}
                              onEditorStateChange={(e) => handleEditorChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="form_fields mb-4">
                            <label htmlFor="title" className="form_label">
                              Banner
                            </label>
                            <div className="banner_custom_div ">
                              <input
                                type="file"
                                className="noborder"
                                accept=".png, .jpg, .jpeg"
                                name="file"
                                onChange={(e) => onFileChange(e)}
                              />

                              <div className="periveimage">
                                <div className="preimage">
                                  {previewUrl ? (
                                    <>
                                      <span className="imagewithclose">
                                        <img
                                          src={previewUrl}
                                          alt=""
                                          width="100"
                                        />
                                      </span>
                                    </>
                                  ) : (
                                    <img src={Image} alt="" width="100" />
                                  )}
                                </div>
                                <div className="imagename">
                                  {previewName ? previewName : imageName}
                                </div>
                              </div>
                            </div>
                            <div>
                              <span className="span-note">
                                Note: Upload image with 1984*500. Image format
                                .PNG,.JPG,.JPEG
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12"></div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form_fields mb-4">
                            <label>Locations</label>
                            <input
                              type="text"
                              onKeyDown={handleInputKeyDown}
                              placeholder="Enter locations"
                              autoFocus // Optional: autofocus on the input field
                            />
                            <>
                              <div className="location_wraps">
                                {locations.map((location, index) => (
                                  <span key={index} className="location_result">
                                    {location}
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleLocationRemove(location)
                                      }
                                    >
                                      <i
                                        class="fa-solid fa-xmark"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </span>
                                ))}
                              </div>
                            </>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="form_fields mb-4">
                            <label>Centers</label>
                            <input
                              type="text"
                              onKeyDown={handleInputKeyDownCenter}
                              placeholder="Enter centers"
                              autoFocus // Optional: autofocus on the input field
                            />
                            <div>
                              <div className="location_wraps">
                                {centers?.map((center, index) => (
                                  <span key={index} className="location_result">
                                    {center}
                                    <button
                                      type="button"
                                      onClick={() => handleCenterRemove(center)}
                                    >
                                      <i
                                        class="fa-solid fa-xmark"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="data_wraper_pages_outer">
                    <div className="data_wraper_pages">
                      <div className="board_title ">
                        <h2>Set question types</h2>
                      </div>
                      <div className="row">
                        {
                          <>
                            <div className="fields_plus_minus">
                              {inputFields.map((inputField, index) => (
                                <div className="fields_add_remv">
                                  <Fragment key={`${inputField}~${index}`}>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form_fields mb-4">
                                          <label htmlFor="category_name">
                                            Category (Total Questions)
                                          </label>
                                          <select
                                            title={
                                              DataInfoEdit.examresultdata != ''
                                                ? 'Edit Disabled - One Exam Already Submitted'
                                                : ''
                                            }
                                            className={
                                              DataInfoEdit.examresultdata != ''
                                                ? 'disable_custom'
                                                : ''
                                            }
                                            id={inputField.category_id}
                                            name={`exam_categories[${index}][category_id]`}
                                            onChange={(e) =>
                                              handleChangeCats(index, e)
                                            }
                                          >
                                            <option value="">
                                              --Please choose an category--
                                            </option>

                                            {CategoriesList.map((element) => (
                                              <option
                                                totalquestion={
                                                  element.no_of_question
                                                }
                                                value={element.id}
                                                selected={
                                                  inputField.category_id ===
                                                  element.id
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {element.name} (
                                                {element.no_of_question})
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-12">
                                        <div className="form_fields mb-4">
                                          <label
                                            className="form_label"
                                            htmlFor="category_name"
                                          >
                                            Weightage wise questions available{' '}
                                          </label>

                                          <select
                                            title={
                                              DataInfoEdit.examresultdata != ''
                                                ? 'Edit Disabled - One Exam Already Submitted'
                                                : ''
                                            }
                                            className={
                                              DataInfoEdit.examresultdata != ''
                                                ? 'disable_custom'
                                                : ''
                                            }
                                            id={inputField.category_id}
                                            name={`exam_categories[${index}][question_weightage]`}
                                            onChange={(e) => {
                                              handleCategoryWeitage(index, e);
                                            }}
                                          >
                                            <option value="">
                                              Select Weightage
                                            </option>

                                            {CategoriesList.find(
                                              (c) =>
                                                c.id == inputField.category_id
                                            )?.categoryWeightage.map(
                                              (state, idx) => {
                                                const [key, value] =
                                                  Object.entries(state)[0];
                                                return (
                                                  //CategoriesList[index].states.map((element) => (
                                                  //countriesData.find((c) => c.name === country.country)?.states.map((state, idx) => (
                                                  <option
                                                    value={key}
                                                    selected={
                                                      inputField.cate_weightage ==
                                                      key
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {`${key} (${value} questions)`}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-12">
                                        <div className="form_fields mb-4">
                                          {' '}
                                          <label htmlFor="number_of_ques">
                                            No. of questions to add
                                          </label>
                                          <div className="remove_cat_wrap d-flex gap-2">
                                            <input
                                              type="number"
                                              title={
                                                DataInfoEdit.examresultdata !=
                                                ''
                                                  ? 'Edit Disabled - One Exam Already Submitted'
                                                  : ''
                                              }
                                              className={
                                                DataInfoEdit.examresultdata !=
                                                ''
                                                  ? 'disable_custom'
                                                  : ''
                                              }
                                              maxLength={2}
                                              id={index}
                                              name={`exam_categories[${index}][number_of_questions]`}
                                              defaultValue={
                                                inputField.category_number ==
                                                null
                                                  ? ''
                                                  : inputField.category_number
                                              }
                                              onChange={(e) =>
                                                handleChange(index, e)
                                              }
                                              min="1"
                                            />
                                            {inputFields.length - 1 ===
                                              index && (
                                              <button
                                                className="add-exam-option-button add_remv_btn btn_cs sky_bg_btn add_option"
                                                type="button"
                                                onClick={() =>
                                                  handleAddFields()
                                                }
                                              >
                                                {' '}
                                                <i className="fa-solid fa-plus"></i>
                                              </button>
                                            )}
                                            {inputFields.length > 1 && (
                                              <button
                                                type="button"
                                                className="add-exam-option-button add_remv_btn remve"
                                                onClick={() =>
                                                  handleRemoveFields(index)
                                                }
                                              >
                                                <i className="fa-solid fa-minus"></i>
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                </div>
                              ))}
                            </div>
                          </>
                        }

                        <div class="col-lg-12 col-md-12">
                          <div class="add_qus_submit d-flex gap-3 justify-content-end">
                            <button
                              className="btn_cs sky_bg_btn"
                              onClick={handleOkEdit}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default EditExamType;
