import { useLocation, useParams, useNavigate } from 'react-router-dom';
import React, {
  Fragment,
  useContext,
  useEffect,
  useState
} from "react";
import axios from 'axios';
import { Table, Input } from "antd";
const ExamSetReport = () => {
  const [examSetData, setExamSetData] = useState(null)
  const params = useParams();
  const [page, setPage] = useState(1);
  const [filterTable, setFilterTable] = useState(null);
  let navigate = useNavigate();



  const onTableSearch = (e) => {

    let filterTable;

    filterTable = examSetData.filter((o) =>

      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );



    setFilterTable(filterTable);

  };

  useEffect(() => {
    if (
      sessionStorage.getItem("token") === null ||
      sessionStorage.getItem("user-type") === "admin"
    ) {

      showExamReportHandler(params.eid);
    } else {
      navigate("/");
    }
  }, []);
  const showExamReportHandler = async (datar) => {
    const response = await axios.get(
      `/api/exam/getanswersbyexamid/${datar}`
    );
    setExamSetData(response.data.response);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'no',
      key: 'no',
      width: '6%',
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: 'Question',
      dataIndex: 'Question',
      key: 'Question',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Total Users Answered',
      dataIndex: 'Total',
      key: 'Total',
    },
    {
      title: ' % Users Answered Correctly',
      dataIndex: 'RightAnswerpercentage',
      key: 'RightAnswerpercentage',

    },
    {
      title: 'Users answered correctly',
      dataIndex: 'RightAnswer',
      key: 'RightAnswer',
    },
    {
      title: 'Users answered incorrectly',
      dataIndex: 'WrongAnswer',
      key: 'WrongAnswer',
    },


  ];
  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      tags: ['nice', 'developer'],
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      tags: ['loser'],
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sydney No. 1 Lake Park',
      tags: ['cool', 'teacher'],
    },
  ];
  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Exam Report</h2>
        </div>
      </div>
      <div className="main_wraper_pages pt-0">
        <div className="data_wraper_pages">
          <div className="record_search_fm_totl justify-content-end">
            <div className="borad_srch_form d-none d-sm-block">
              <Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
            </div>
          </div>
          <div className="data_table same_table_als">
            <Table columns={columns} dataSource={(filterTable) ? filterTable : examSetData} pagination={{
              onChange(current) {
                setPage(current);
              }
            }} />
          </div>
        </div>
      </div>
    </ >
  );
};
export default ExamSetReport