import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import quesContext from '../context/ques/QuesContext';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const AddQuestions = (props) => {
  const context = useContext(quesContext);
  const { addQues } = context;

  const [category, setCategory] = useState('');
  const [Answer, setAnswer] = useState('');
  const [CategoriesList, setCategoriesList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formFields, setFormFields] = useState(['', '']);
  const [ques, setQues] = useState({
    question: '',
    options: [],
    answer: '',
    category: '',
    weightage: 1,
  });

  let navigate = useNavigate();

  useEffect(() => {
    if (
      !sessionStorage.getItem('token') ||
      sessionStorage.getItem('user-type') !== 'admin'
    ) {
      navigate('/login');
    } else {
      getCategoryData();
    }
  }, []);

  const getCategoryData = async () => {
    const response = await fetch('/api/categories/fetchallcategoriesactive', {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'auth-token': sessionStorage.getItem('token'),
      },
    });
    const json = await response.json();
    setCategoriesList(json.categories);
  };

  const onChange = (e) => {
    setQues({ ...ques, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};

    if (!ques.question) {
      errors.question = 'Question is required';
    }

    if (ques.options.length < 2) {
      errors.options = 'At least two options are required';
    } else {
      ques.options.forEach((option, index) => {
        if (!option) {
          errors.options = `Option ${index + 1} is required`;
        }
      });
    }

    if (!Answer) {
      errors.answer = 'Answer is required';
    }

    if (!category) {
      errors.category = 'Category is required';
    }

    if (!ques.weightage) {
      errors.weightage = 'Weightage is required';
    }

    return errors;
  };

  const handleClick = (e) => {
    e.preventDefault();

    const errors = validate();
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      props.showAlert('Please fill all the required fields', 'danger');
      return;
    }

    addQues(ques.question, ques.options, Answer, category, ques.weightage);

    props.showAlert('Question Added Successfully', 'success');

    setQues({
      question: '',
      options: [],
      answer: '',
      category: '',
      weightage: 1,
    });
    setFormFields(['', '']);
    setAnswer('');
    setCategory('');

    if (e.target.id === 'more') {
      navigate('/addquestions');
    } else {
      navigate('/home');
    }
  };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index] = event.target.value;
    setQues({ ...ques, options: data });
    setFormFields(data);
  };

  const addFields = () => {
    setFormFields([...formFields, '']);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
    setQues({ ...ques, options: data });
  };

  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Add questions</h2>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div className="addexamtype">
            <div className="row">
              <div className="col-md-6">
                <div className="form_fields mb-4">
                  <label htmlFor="question" className="form_label">
                    Question
                  </label>
                  <textarea
                    id="question"
                    name="question"
                    onChange={onChange}
                    value={ques.question}
                    className={
                      formErrors.question ? 'border-color-red-error' : ''
                    }
                    minLength={5}
                    required
                    placeholder="Write your question here"
                  />
                </div>
              </div>
              <div
                className={
                  parseInt(sessionStorage.getItem('adminPlanId')) !== 3
                    ? 'col-md-6'
                    : 'col-md-3'
                }
              >
                <div className="form_fields mb-4">
                  <label htmlFor="category" className="form_label">
                    Category
                  </label>
                  <select
                    name="category"
                    className={
                      formErrors.category ? 'border-color-red-error' : ''
                    }
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">-- Select category --</option>
                    {CategoriesList.map((element) => (
                      <option key={element.id} value={element.id}>
                        {element.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {parseInt(sessionStorage.getItem('adminPlanId')) === 3 && (
                <div className="col-md-3">
                  <div className="form_fields mb-4">
                    <label htmlFor="weightage" className="form_label">
                      Weightage
                    </label>
                    <input
                      className={
                        formErrors.weightage ? 'border-color-red-error' : ''
                      }
                      type="number"
                      id="weightage"
                      name="weightage"
                      min={1}
                      onChange={onChange}
                      value={ques.weightage}
                      required
                      placeholder="Weightage"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form_fields mb-4">
                  <label htmlFor="options" className="form_label">
                    Options
                  </label>
                  {formFields.map((form, index) => (
                    <div className="col-md-12" key={index}>
                      <div className="form_fields remove_cat_wrap d-flex gap-2 mb-4 align-items-center">
                        <input
                          name="options"
                          className={
                            formErrors.options ? 'border-color-red-error' : ''
                          }
                          placeholder={`Option ${index + 1}`}
                          onChange={(event) => handleFormChange(event, index)}
                          value={form}
                        />
                        {formFields.length - 1 === index && (
                          <button
                            className="add_remv_btn btn_cs sky_bg_btn add_option"
                            onClick={addFields}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </button>
                        )}
                        {index >= 2 && (
                          <button
                            onClick={() => removeFields(index)}
                            className="add_remv_btn remve"
                          >
                            <i className="fa-solid fa-minus"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form_fields mb-4">
                  <label htmlFor="answer" className="form_label">
                    Correct answer
                  </label>
                  <select
                    name="answer"
                    className={
                      formErrors.answer ? 'border-color-red-error' : ''
                    }
                    value={Answer}
                    onChange={(e) => setAnswer(e.target.value)}
                  >
                    <option value="" disabled>
                      -- Select the correct option --
                    </option>
                    {formFields.map((form, index) => (
                      <option key={index} value={index + 1}>
                        Option {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 cancel_btn">
                <Link className="btn_cs sky_border_btn" to="/home">
                  CANCEL
                </Link>
                <div className="add_qus_submit">
                  <button
                    type="submit"
                    className="btn_cs sky_border_btn"
                    onClick={handleClick}
                  >
                    ADD
                  </button>
                </div>
                <div className="add_qus_submit">
                  <button
                    type="submit"
                    id="more"
                    className="btn_cs sky_bg_btn"
                    onClick={handleClick}
                  >
                    ADD MORE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQuestions;
