import React, {
	Fragment,
	useContext,
	useEffect,
	useState
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";
import {
	CloseOutlined,
	DownloadOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import examContext from "../context/exam/ExamContext";
import ImageUploading from "react-images-uploading";
import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import htmlToDraft from "html-to-draftjs";
import filter_icon from "../more-filters.png";
import moment from "moment";
import {
	PopconfirmProps,
	Form,
	Input,
	Modal,
	Table,
	Tag,
	Popconfirm
} from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const ExamTypeList = (props) => {
	let navigate = useNavigate();
	let currentPackage = 0;
	const context = useContext(examContext);
	const [DataInfo, setDataInfo] = useState({});
	const [DataInfoEdit, setDataInfoEdit] = useState({});
	const [CategoriesList, setCategoriesList] = useState({});
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [totalMarks, setTotalMarks] = useState(0);
	const [totalQuestions, setTotalQuestions] = useState(0);
	const { deleteExam, clonedata, ExamCloneStatus } = context;
	const [filterTab, setFilterTab] = useState('active')
	const [inputFields, setInputFields] = useState([]);
	const [status, setStatus] = useState(DataInfo.status);
	const [Percentage, setPercentage] = useState(DataInfo.percentage);
	const [Image, setImage] = useState(DataInfo.image);
	const [Examtime, setExamtime] = useState(DataInfo.exam_time);
	const [ShowResult, setShowresult] = useState(DataInfo.result_show);
	const [filterTable, setFilterTable] = useState(null);
	const [Content, setContent] = useState();

	const [activeExams, setActiveExams] = useState([]);
	const [inActiveExams, setInActiveExams] = useState([]);
	const [filteredActiveExams, setFilteredActiveExams] = useState([]);
	const [filteredInActiveExams, setFilteredInActiveExams] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');

	const [ContentView, setContentView] = useState(() =>
		EditorState.createEmpty()
	);
	const [page, setPage] = useState(1);
	const [ContentViewe, setContentViewe] = useState(() =>
		EditorState.createEmpty()
	);
	const [isModalOpenreport, setIsModalOpenReport] = useState(false);
	const [datatest, setdatatest] = useState();
	const [setexportId, setIsexportId] = useState();
	const [exportName, setIsexportName] = useState();
	const percentageArr = [
		5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
		100,
	];
	const [locations, setLocations] = useState([]);
	const [centers, setCenters] = useState([]);
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	useEffect(() => {
		if (
			sessionStorage.getItem("token") === null ||
			sessionStorage.getItem("user-type") === "admin"
		) {
			getExams();

			getCategoryData();
		} else {
			navigate("/");
		}
	}, []);





	const getExams = async () => {
		try {
			const response = await axios.get('/api/exam/fetchallexamtype');
			const data = response.data;

			setActiveExams(data.active);
			setInActiveExams(data.inActive);
			setFilteredActiveExams(data.active);
			setFilteredInActiveExams(data.inActive);
		} catch (error) {
			console.error('Error fetching exams:', error);
		}
	};


	const handleSearch = (event) => {
		const query = event.target.value.toLowerCase();
		setSearchQuery(query);

		if (query) {
			setFilteredActiveExams(activeExams.filter(exam => exam.name.toLowerCase().includes(query)));
			setFilteredInActiveExams(inActiveExams.filter(exam => exam.name.toLowerCase().includes(query)));
		} else {
			setFilteredActiveExams(activeExams);
			setFilteredInActiveExams(inActiveExams);
		}
	};

	const getCategoryData = async (e) => {

		const response = await fetch(
			"/api/categories/getCategoryListWithWeightage",
			{
				method: "GET",

				headers: {
					"Cache-Control": "no-cache",

					"Content-Type": "application/json",

					"auth-token": sessionStorage.getItem("token"),
				},
			}
		);

		const json = await response.json();
		// console.log(json.data);
		setCategoriesList(json.data);
	};


	currentPackage = parseInt(sessionStorage.getItem('adminPlanId'));
	const copydatahandler = async (data) => {
		//if (window.confirm("Are you sure you want to duplicate the data?")) {
		const response = await fetch(`/api/exam/cloneexamdata/${data.id}`, {
			method: "GET",
			headers: {
				"Cache-Control": "no-cache",
				"Content-Type": "application/json",
				"auth-token": sessionStorage.getItem("token"),
			},
		});

		const json = await response.json();
		getExams();
		handleSearch({ target: { value: searchQuery } });
		if (json.status == "success") {
			props.showAlert(json.message, "success");
		} else if (json.status == "error") {
			props.showAlert(json.message, "danger");
		} else {
			props.showAlert("Please try again", "danger");
		}

	};

	const deleteexamhandeler = (data) => {

		deleteExam(data.id);


	};

	const handleChange2 = (e) => { };
	const handleChangeImage = (imageList) => {
		setImage(imageList[0]["data_url"]);
	};

	const handleEditorChange = (content) => {
		setContentView(content);
		let check_content = convertToHTML(content.getCurrentContent());
		setContent(check_content);
	};

	//second function

	const handleChange = (i, e) => {
		const passNoOfQuestions = CategoriesList.find((item) => item.id == inputFields[i]['category_id']);
		passNoOfQuestions.categoryWeightage.forEach((weighatage) => {

			if (weighatage.hasOwnProperty(inputFields[i]['cate_weightage'])) {
				if (e.target.value <= weighatage[inputFields[i]['cate_weightage']]) {
					inputFields[i]['category_number'] = parseInt(e.target.value);
				} else {
					toast.error(`Not Allowed to enter more than ${weighatage[inputFields[i]['cate_weightage']]}`)
					inputFields[i]['category_number'] = null;
				}
			}
		})
		let totalQuestions = 0;
		let totalMarks = 0;
		inputFields.forEach(item => {
			totalQuestions += parseInt(item.category_number);
			totalMarks += parseInt(item.category_number) * parseInt(item.cate_weightage);
		});
		setTotalQuestions(totalQuestions);
		setTotalMarks(totalMarks);
	}

	//thirde function
	const handleChangeCats = (i, e) => {
		let MaxQuestion = e.target.options[e.target.selectedIndex].getAttribute('totalquestion');
		document.getElementById(i).setAttribute("max", MaxQuestion);
		inputFields[i][e.target.name] = e.target.value;
		inputFields[i]['category_number'] = 0;
		inputFields[i]['category_id'] = e.target.value;
	}
	const handleOkEdit = async (e) => {
		e.preventDefault();
		const formdata = new FormData(document.getElementById("editExamId"));
		formdata.append("content", Content);
		formdata.append("image", Image);
		formdata.append("locations", locations);
		formdata.append("centers", centers);
		const response = await fetch("/api/exam/update-exam", {
			method: "post",
			body: formdata,
		});

		let data = await response.json();
		if (data.status === "success") {
			props.showAlert(data.message, "success");
			setIsEditModalOpen(false);
			getExams();
			handleSearch({ target: { value: searchQuery } });
		} else {
			props.showAlert(data.message, "danger");
		}
	};

	const handleCancelEdit = () => {
		setIsEditModalOpen(false);
	};

	const handleAddFields = () => {
		const values = [...inputFields];
		values.push({ firstName: "", lastName: "" });
		setInputFields(values);
	};

	const handleRemoveFields = (index) => {
		const values = [...inputFields];
		values.splice(index, 1);
		setInputFields(values);
		let totalQuestions = 0;
		let totalMarks = 0;
		values.forEach(item => {
			totalQuestions += parseInt(item.category_number);
			totalMarks += parseInt(item.category_number) * parseInt(item.cate_weightage);
		});
		setTotalQuestions(totalQuestions);
		setTotalMarks(totalMarks);
	};

	const handleOkReport = () => {
		setIsModalOpenReport(false);
	};

	const handleCancelReport = () => {
		setIsModalOpenReport(false);
	};

	const showExamReportHandler = async (datar) => {
		setIsexportId(datar.id);
		setIsexportName(datar.name);
		const response = await axios.get(
			`/api/exam/getanswersbyexamid/${datar.id}`
		);
		setdatatest(response.data.response);
		setIsModalOpenReport(true);
	};

	const exportExamReportHandel = async (id, exportName) => {
		const response = await axios.get(`/api/exam/getanswersbyexamid/${id}`);
		const ws = XLSX.utils.json_to_sheet(response.data.response);
		const wb = { Sheets: { Result: ws }, SheetNames: ["Result"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, exportName + " Report" + fileExtension);
	};
	const [filteredInfo, setFilteredInfo] = useState({});
	const [sortedInfo, setSortedInfo] = useState({});
	const handleChangetable = (pagination, filters, sorter) => {
		console.log('Various parameters', pagination, filters, sorter);
		setFilteredInfo(filters);
		setSortedInfo(sorter);
	};
	const clearFilters = () => {
		setFilteredInfo({});
	};
	const clearAll = () => {
		setFilteredInfo({});
		setSortedInfo({});
	};
	const setAgeSort = () => {
		setSortedInfo({
			order: 'descend',
			columnKey: 'age',
		});
	};


	const onTableSearch = (e) => {
		let filterTable;
		if (filterTab === 'active') {
			filterTable = activeExams.filter((o) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
				)
			);
		} else {
			filterTable = inActiveExams.filter((o) =>
				Object.keys(o).some((k) =>
					String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
				)
			);
		}


		setFilterTable(filterTable);
	};

	// const confirm = (data) => {

	// 	copydatahandler(data)
	// };
	const cancel = (e) => {

	};
	const confirmdelete = (data) => {

		deleteexamhandeler(data);
		props.showAlert("Deleted successfully", "success");
	};
	const canceldelete = (e) => {

	};

	function getFirstFiveWords(input) {
		if (!input) return '';
		const words = input.split(' ');
		return words.slice(0, 5).join(' ');
	}

	const examStatusChangeHandler = async (id, status, examName) => {
		try {
			const response = await axios.post('/api/exam/updateexamstatusbyid', {
				examId: id,
				status: status,
				examName: examName
			});

			const returnResponse = response.data;

			if (returnResponse) {
				getExams();
				console.log('abhi')
				handleSearch({ target: { value: searchQuery } });
				//filterHander('active');
				//filterHander('inactive')
				if (returnResponse.status === "success") {
					props.showAlert(returnResponse.message, "success");
				} else {
					props.showAlert(returnResponse.message, "danger");
				}
			}
		} catch (error) {
			console.error('Error updating exam status:', error);
			props.showAlert('An error occurred while updating the exam status.', 'danger');
		}
	};


	const columns = [
		{
			title: "S.No",
			dataIndex: "no",
			key: "no",
			width: "5%",
			render: (value, item, index) => (page - 1) * 10 + index + 1
		},

		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: "45%",
			filteredValue: filteredInfo.name || null,
			onFilter: (value, record) => record.name.includes(value),
			sorter: (a, b) => a.name.length - b.name.length,
			sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
			ellipsis: true,
			//...getColumnSearchProps('name'),
		},



		{
			title: "Passing Percentage",
			dataIndex: "percentage",
			key: "percentage",
			width: "14%",
			sorter: (a, b) => a.percentage - b.percentage,
			sortOrder: sortedInfo.columnKey === 'percentage' ? sortedInfo.order : null,
			ellipsis: true,
		},

		{
			title: "Date Created",
			dataIndex: "date",
			key: "date",
			filteredValue: filteredInfo.date || null,
			onFilter: (value, record) => record.date.includes(value),
			sorter: (a, b) => moment(a.date, 'DD-MM-YYYY').unix() - moment(b.date, 'DD-MM-YYYY').unix(),
			sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
			ellipsis: true,
			width: "15%",
		},

		{
			title: "Exam Report",
			width: "10%",
			render: (data) => (
				(currentPackage !== 1) ?

					(data.examresultdata.length > 0) ?
						// <a
						// 	href="javascript:void(0)"
						// 	onClick={() => showExamReportHandler(data)}
						// >
						// 	<div className="status_btns btn_active"><span>VIEW</span></div>
						// </a >

						<Link className="data_view" to={`/examsetreport/${data.id}`}>VIEW</Link>

						: <span>NO DATA</span>
					: <span>UPGRADE PLAN</span>
			),
		},

		{
			title: "Action",
			button: true,
			align: '-webkit-center',
			render: (data) => (
				<>
					<div className="action_dropdown dropdown dropstart ">
						<a className="action_toggle dropdown-toggle " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<i className="fa-solid fa-ellipsis-vertical"></i>
						</a>
						<ul className="dropdown-menu">
							<li>

								<Link to={`/editexamtype/${data.id}`}><i className="fa-regular fa-pen-to-square" ></i>Edit</Link>
							</li>

							<li>

								<Popconfirm
									title="Clone exam"
									description="Please confirm if you would like to proceed with cloning this exam."
									onConfirm={() => copydatahandler(data)}
									onCancel={cancel}
									okText="Confirm Clone"
									cancelText="Don't Clone"
								>
									<a><i className="fa-regular fa-clone"></i>Clone</a>
								</Popconfirm>

							</li>

							<li>
								{
									(data.status === 'Inactive') ?
										<Popconfirm Popconfirm title="Inactive exam"
											description="Please confirm if you would like to proceed with changing the status of this exam."
											onConfirm={() => examStatusChangeHandler(data.id, 'Active', data.name)}
											onCancel={cancel}
											okText="Confirm Update"
											cancelText="Don't Update"
										>
											<a><i class="fa-solid fa-pen-fancy"></i>Active</a>
										</Popconfirm>
										: <Popconfirm Popconfirm title="Clone exam"
											description="Please confirm if you would like to proceed with changing the status of this exam."
											onConfirm={() => examStatusChangeHandler(data.id, 'Inactive', data.name)}
											onCancel={cancel}
											okText="Confirm Update"
											cancelText="Don't Update"
										>
											<a><i class="fa-solid fa-pen-fancy"></i>Inactive</a>
										</Popconfirm>}


							</li>

							<Popconfirm
								title="Do you really want to delete this exam?"
								description={getFirstFiveWords(data.name)}
								onConfirm={() => { confirmdelete(data) }}
								onCancel={canceldelete}
								okText="Confirm Delete"
								cancelText="Don't Delete"
							>
								<li><a ><i className="fa-solid fa-trash-can"></i>Delete</a></li>
							</Popconfirm>


						</ul>
					</div>
				</>
			),

			width: "10%",
		},
	];

	//fourth function
	const handleCategoryWeitage = (index, e) => {
		inputFields[index]['cate_weightage'] = parseInt(e.target.value);
	}
	const handleInputKeyDown = (e) => {
		const enteredText = e.target.value;
		// Check if the user has entered a separator (e.g., comma or space)
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			const newLocation = enteredText.trim(); // Remove leading/trailing spaces
			if (newLocation !== '') {
				setLocations([...locations, newLocation]);
				e.target.value = ''; // Clear the input field
			}
		}
	};

	const handleInputKeyDownCenter = (e) => {
		const enteredText = e.target.value;
		// Check if the user has entered a separator (e.g., comma or space)
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			const newCenter = enteredText.trim(); // Remove leading/trailing spaces
			if (newCenter !== '') {
				setCenters([...centers, newCenter]);
				e.target.value = ''; // Clear the input field
			}
		}
	};

	const handleCenterRemove = (centerToRemove) => {
		setCenters(prevCenters =>
			prevCenters.filter(center => center !== centerToRemove)
		);
	};
	const handleLocationRemove = (locationToRemove) => {
		setLocations(prevLocations => prevLocations.filter(location => location !== locationToRemove));
	};

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const onSelectChange = (newSelectedRowKeys) => {

		console.log('selectedRowKeys changed: ', newSelectedRowKeys);

		setSelectedRowKeys(newSelectedRowKeys);

	};

	const rowSelection = {

		selectedRowKeys,

		onChange: onSelectChange,

	};

	const hasSelected = selectedRowKeys.length > 0;

	const filterHander = (e) => {
		setFilterTab(e);
		let filterTable;
		if (e === 'active') {
			filterTable = activeExams;

		} else {
			filterTable = inActiveExams;

		}

		setFilterTable(filterTable);
	}

	return (
		<>
			<div className="board_title_data d-flex align-items-center justify-content-between gap-3">
				<div className="board_title d-flex align-items-center gap-3">
					<h2>Exams List</h2>
				</div>
				<div className="board_form_filter d-flex gap-3 align-items-center">
					<div className="mobile_all_filters d-block d-sm-none">
						<div class="borad_more_filters dropdown remove_cret">
							<a class="more_filters dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<img src={filter_icon} alt="filters" />
							</a>
							<ul class="dropdown-menu">
								<li class="mobile_serch">
									<div className="borad_srch_form">
										<Input.Search placeholder="Search" enterButton onChange={handleSearch} />

									</div>
								</li>
							</ul>
						</div>
					</div>
					<Link className="btn_cs sky_bg_btn" to="/addexamtype"><i className='bx bx-plus-circle'></i>&nbsp;Add New</Link>
				</div>
			</div>
			<div className="main_wraper_pages p-15 pt-0">
				<div className="tabs_srch_form d-flex justify-content-between">
					<div className="data_tabs_pagination d-flex justify-content-between align-items-center border_bt_gray w-100">
						<nav>
							<div className="data_ac_tabs" id="data_ac_tabs" role="tablist">
								<a className="tab_item active" data-bs-toggle="tab" data-bs-target="#active_data" aria-controls="nav-home" aria-selected="true" onClick={() => filterHander('active')}>Active</a>
								<a className="tab_item" data-bs-toggle="tab" data-bs-target="#inactive_data" onClick={() => filterHander('inactive')}>Inactive</a>
							</div>
						</nav>
						<div className="record_search_fm_totl m-0">
							<span className="total_record">	{hasSelected ? `Selected items ${selectedRowKeys.length} ` : ''}</span>
							<span className="total_record">Total: {filterTable ? filterTable.length : activeExams.length}</span>
							<div className="borad_srch_form d-none d-sm-block">
								<Input.Search placeholder="Search" enterButton onChange={handleSearch} />
							</div>
						</div>
					</div>

					<div className="tab-content" id="data-tabContent">
						<div className="tab-pane show active" id="active_data" role="tabpanel">

						</div>

					</div>
				</div>

				<div className="tab-content filter_custom" id="data-tabContent">
					<div className="tab-pane show active" id="active_data" role="tabpanel">
						<div className="data_table same_table_als exm_lst mt-3">
							<Table rowSelection={rowSelection}
								columns={columns}
								dataSource={filterTab === 'active' ? filteredActiveExams : filteredInActiveExams} pagination={{
									onChange(current) {
										setPage(current);
									}
								}} onChange={handleChangetable}
							/>
						</div></div></div>


				{/* Model edit */}

				<Modal
					title="Edit Exam"
					open={isEditModalOpen}
					onOk={handleOkEdit}
					cancelText="Cancel"
					okText="Submit"
					onCancel={handleCancelEdit}
					width={800}
				>
					<Form method="post" action="" id="editExamId">
						<div className="signup_form add_question_form popup_form">
							<div className="qs_form">
								<div className="form_fields" style={{ "max-width": "100%" }}>
									<label htmlFor="title" className="form_label">
										Name
									</label>

									<input
										type="text"
										key={DataInfoEdit.name}
										name="exam_name"
										defaultValue={DataInfoEdit.name}
										onChange={handleChange2}
									/>

									<input
										type="hidden"
										name="exam_id"
										defaultValue={DataInfoEdit.id}
									/>
								</div>

								<div className="form_fields" style={{ "max-width": "100%" }}>
									<label htmlFor="title" className="form_label">
										Passcode
									</label>

									<input
										type="text"
										key={DataInfoEdit.passcode}
										name="exam_passcode"
										defaultValue={DataInfoEdit.passcode}
										onChange={handleChange2}
									/>
								</div>

								<div className="form_fields" style={{ "max-width": "100%" }}>
									<label className="show_result_label">
										Show result
										<p className="show_result_p">(to the candidate)</p>
									</label>

									<select
										name="result_exam"
										onChange={(e) => setShowresult(e.target.value)}
									>
										<option key="0" value="">
											--Please choose one--
										</option>

										<option
											key="true"
											value="true"
											selected={
												DataInfoEdit.result_show === true ? true : false
											}
										>
											Yes
										</option>

										<option
											key="false"
											value="false"
											selected={
												DataInfoEdit.result_show === false ? true : false
											}
										>
											No (Hide)
										</option>
									</select>
								</div>

								<div className="form_fields" style={{ "max-width": "100%" }}>
									<label>Instructions :</label>

									<Editor
										editorState={ContentView}
										defaultEditorState={DataInfoEdit.content}
										onEditorStateChange={(e) => handleEditorChange(e)}
									/>
								</div>

								<div className="form_fields form_fields">
									<label htmlFor="title" className="form_label">
										Exam time
									</label>

									<select
										name="exam_time"
										title={(DataInfoEdit.examresultdata != "") ? 'Edit Disabled - One Exam Already Submitted' : ''}

										className={(DataInfoEdit.examresultdata != "") ? 'disable_custom' : ''}
										//disabled={DataInfoEdit.examresultdata != "" ? true : false}
										defaultValue={Examtime}
										onChange={(e) => setExamtime(e.target.value)}
									>
										<option key="0" value="">
											{" "}
											--Please choose an exam time--
										</option>

										<option
											key="10"
											value="10"
											selected={Examtime === 10 ? true : false}
										>
											10 minutes
										</option>

										<option
											key="20"
											value="20"
											selected={Examtime === 20 ? true : false}
										>
											20 minutes
										</option>

										<option
											key="30"
											value="30"
											selected={Examtime === 30 ? true : false}
										>
											30 minutes
										</option>

										<option
											key="40"
											value="40"
											selected={Examtime === 40 ? true : false}
										>
											40 minutes
										</option>

										<option
											key="50"
											value="50"
											selected={Examtime === 50 ? true : false}
										>
											50 minutes
										</option>

										<option
											key="60"
											value="60"
											selected={Examtime === 60 ? true : false}
										>
											60 minutes
										</option>

										<option
											key="70"
											value="70"
											selected={Examtime === 70 ? true : false}
										>
											70 minutes
										</option>

										<option
											key="80"
											value="80"
											selected={Examtime === 80 ? true : false}
										>
											80 minutes
										</option>

										<option
											key="90"
											value="90"
											selected={Examtime === 90 ? true : false}
										>
											90 minutes
										</option>

										<option
											key="100"
											value="100"
											selected={Examtime === 100 ? true : false}
										>
											100 minutes
										</option>

										<option
											key="110"
											value="110"
											selected={Examtime === 110 ? true : false}
										>
											110 minutes
										</option>

										<option
											key="120"
											value="120"
											selected={Examtime === 120 ? true : false}
										>
											120 minutes
										</option>

										<option
											key="130"
											value="130"
											selected={Examtime === 130 ? true : false}
										>
											130 minutes
										</option>

										<option
											key="140"
											value="140"
											selected={Examtime === 140 ? true : false}
										>
											140 minutes
										</option>

										<option
											key="150"
											value="150"
											selected={Examtime === 150 ? true : false}
										>
											150 minutes
										</option>

										<option
											key="160"
											value="160"
											selected={Examtime === 160 ? true : false}
										>
											160 minutes
										</option>

										<option
											key="170"
											value="170"
											selected={Examtime === 170 ? true : false}
										>
											170 minutes
										</option>

										<option
											key="180"
											value="180"
											selected={Examtime === 180 ? true : false}
										>
											180 minutes
										</option>
									</select>
								</div>

								<div className="form_fields form_fields">
									<label htmlFor="title" className="form_label">
										Passing percentage(%)
									</label>

									<select
										name="percentage"
										defaultValue={Percentage}
										onChange={(e) => setPercentage(e.target.value)}
										title={(DataInfoEdit.examresultdata != "") ? 'Edit Disabled - One Exam Already Submitted' : ''}

										className={(DataInfoEdit.examresultdata != "") ? 'disable_custom' : ''}
									>
										{percentageArr.map((item, index) => (
											<option
												key={index}
												value={item}
												selected={Percentage === item ? true : false}
											>
												{item}%
											</option>
										))}
									</select>
								</div>

								{
									<>

										{inputFields.map((inputField, index) => (
											<Fragment key={`${inputField}~${index}`}>

												<div className="form_fields weightage_divclass">
													<label htmlFor="category_name">Category</label>
													<select
														title={(DataInfoEdit.examresultdata != "") ? 'Edit Disabled - One Exam Already Submitted' : ''}

														className={(DataInfoEdit.examresultdata != "") ? 'disable_custom' : ''}
														id={inputField.category_id} name={`exam_categories[${index}][category_id]`}
														onChange={(e) => handleChangeCats(index, e)}
													>
														<option value="">
															--Please choose an category--
														</option>

														{CategoriesList.map((element) => (

															<option
																totalquestion={element.no_of_question}
																value={element.id}

																selected={

																	inputField.category_id === element.id

																		? true
																		: false
																}
															>
																{element.name} ({element.no_of_question})
															</option>
														))}
													</select>
												</div>
												<div className="form_fields weightage_divclass">

													<div className="">
														<label htmlFor="category_name">Weightage </label>

														<select

															title={(DataInfoEdit.examresultdata != "") ? 'Edit Disabled - One Exam Already Submitted' : ''}

															className={(DataInfoEdit.examresultdata != "") ? 'disable_custom' : ''}

															id={inputField.category_id} name={`exam_categories[${index}][question_weightage]`}
															onChange={(e) => {
																handleCategoryWeitage(index, e)
															}}
														>
															<option value="">
																--Select Weightage--
															</option>
															{/* {CategoriesList.find((c) => c.id === inputField.category_id).categoryWeightage.map((state) => {
																console.log(state)
															})} */}

															{


																CategoriesList.find((c) => c.id == inputField.category_id)?.categoryWeightage.map((state, idx) => {
																	const [key, value] = Object.entries(state)[0];
																	return (
																		//CategoriesList[index].states.map((element) => (
																		//countriesData.find((c) => c.name === country.country)?.states.map((state, idx) => (
																		<option

																			value={key}
																			selected={
																				inputField.cate_weightage == key
																					? true

																					: false

																			}

																		>

																			{`${key} (${value})`}
																		</option>

																	)
																})}
														</select>

													</div>
												</div>
												<div className="form_fields edit_qus_ad_mn weightage_divclass">
													<div className="fld">
														<label htmlFor="number_of_ques">
															Number of question
														</label>
														<input
															type="number"
															title={(DataInfoEdit.examresultdata != "") ? 'Edit Disabled - One Exam Already Submitted' : ''}

															className={(DataInfoEdit.examresultdata != "") ? 'disable_custom' : ''}
															maxLength={2}


															id={index}

															name={`exam_categories[${index}][number_of_questions]`}

															defaultValue={inputField.category_number == null ? '' : inputField.category_number}

															onChange={(e) => handleChange(index, e)}

															min='1'

															max={inputField.category_number}

														/>

													</div>

													<div className="fld">

														<div className="fld_btns">

															<label>&nbsp;</label>

															<button
																type="button"

																disabled={index === 0}

																onClick={() => handleRemoveFields(index)}

															><i className="fa-regular fa-square-minus"></i></button>

														</div>

														<div className="fld_btns">

															<label>&nbsp;</label>

															<button

																className=""

																type="button"

																onClick={() => handleAddFields()}

															><i className="fa-regular fa-square-plus"></i></button>

														</div>

													</div>



												</div>



											</Fragment>
										))}
									</>

								}

								<input type="hidden" name="total_marks" value={totalMarks}></input>
								<input type="hidden" name="total_questions" value={totalQuestions}></input>
								<div className="form_fields weightage_divclass" >
								</div>
								<div className="form_fields weightage_divclass" >
									<label>Total marks =</label> {totalMarks}
								</div>

								<div className="form_fields weightage_divclass" >
									<label>Total questions =</label>{totalQuestions}
								</div>

								<div className="form_fields">
									<label htmlFor="title" className="form_label">
										Banner
									</label>

									<div className="App">
										<ImageUploading
											multiple
											value={Image}
											onChange={handleChangeImage}
											maxNumber="2"
											dataURLKey="data_url"
											name="image"
											acceptType={["jpg"]}
										>
											{({
												onImageUpload,

												onImageRemove,

												isDragging,

												dragProps,
											}) => (
												// write your building UI

												<div className="upload__image-wrapper">
													<span
														className="upload-section"
														style={isDragging ? { color: "red" } : null}
														onClick={onImageUpload}
														{...dragProps}
													>
														<UploadOutlined />
														<span className="choose-file"> Upload a File</span>
													</span>

													{Image ? (
														<>
															<span className="imagewithclose">
																<img src={Image} alt="" width="100" />
																<a onClick={() => onImageRemove(0)}>
																	<CloseOutlined
																		style={{ fontSize: "30px", color: "red" }}
																	/>
																</a>
															</span>
														</>
													) : (
														""
													)}
												</div>
											)}
										</ImageUploading>
									</div>
								</div>

								<div className="form_fields">
									<label htmlFor="status" className="form_label">
										{" "}
										Status{" "}
									</label>

									<select
										className="form-select"
										name="status"
										value={status}
										onChange={(e) => setStatus(e.target.value)}
									>
										<option
											selected={DataInfoEdit.status === "Active" ? true : false}
											value="Active"
										>
											Active
										</option>

										<option
											selected={
												DataInfoEdit.status === "Inactive" ? true : false
											}
											value="Inactive"
										>
											Inactive
										</option>
									</select>
								</div>

								<div className="form_fields">
									<label>Location</label>
									<input
										type="text"
										onKeyDown={handleInputKeyDown}
										placeholder="Enter locations"
										autoFocus // Optional: autofocus on the input field
									/>
									<div>
										{locations.map((location, index) => (
											<span key={index} className="locations">
												{location}
												<button type="button" onClick={() => handleLocationRemove(location)}>&times;</button>
											</span>
										))}
									</div>
								</div>
								<div className="form_fields">
									<label>Centers</label>
									<input
										type="text"
										onKeyDown={handleInputKeyDownCenter}
										placeholder="Enter centers"
										autoFocus // Optional: autofocus on the input field
									/>
									<div>
										{centers?.map((center, index) => (
											<span key={index} className="centers">
												{center}
												<button type="button" onClick={() => handleCenterRemove(center)}>&times;</button>
											</span>
										))}
									</div>
								</div>
							</div>
						</div>
					</Form>
				</Modal>

				<Modal
					Modal
					title="Exam Questions Report"
					open={isModalOpenreport}
					footer=""
					onOk={handleOkReport}
					onCancel={handleCancelReport}
					width={1000}
				>
					<table
						className="result_data_table"
						width="100%"
						cellPadding="0"
						cellSpacing="0"
					>
						<tr>
							<th>Questions</th>

							<th>Correct Answer</th>

							<th>Incorrect Answer</th>

							<th>Total</th>
						</tr>

						{datatest?.map((value, key) => {
							return (
								<tr>
									<td>
										{key + 1}. &nbsp; {value.Question}
									</td>

									<td align="center">{value.RightAnswer}</td>

									<td align="center">{value.WrongAnswer}</td>

									<td align="center">{value.Total}</td>
								</tr>
							);
						})}
					</table>

					<button
						className=" btn btn-dark "
						onClick={(e) => exportExamReportHandel(setexportId, exportName)}
						icon={<DownloadOutlined />}
						size={"medium"}
					>
						Export
					</button>
				</Modal>
			</div >
		</>
	);
};

export default ExamTypeList;
