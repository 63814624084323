import userContext from "./UserContext";
import { useState } from "react";
const UserState = (props) => {
  const UsersInitial = [];
  const [Users, setUsers] = useState(UsersInitial);
  const [userList, setUsersList] = useState(UsersInitial);
  const [UsersInactive, setUsersInActive] = useState(UsersInitial);
  // Get Users

  const getUsers = async () => {
    const response = await fetch(`/api/user/fetchallusers`,

      {

        method: "GET",

        headers: {

          "Cache-Control": "no-cache",

          "Content-Type": "application/json",

          "auth-token": sessionStorage.getItem("token"),

        },

      }

    );

    const json = await response.json();
    setUsersInActive(json.usersInActive)
    setUsers(json.usersActive);

  };

  // Edit a Users

  const editUsers = async (

    id,

    name,

    firstname,

    lastname,

    email,

    location,

    center,

    status,



  ) => {

    // API Call



    const response = await fetch(`/api/user/updateuser/${id}`, {

      method: "PUT",

      headers: {

        "Content-Type": "application/json",

        "auth-token": sessionStorage.getItem("token"),

      },

      body: JSON.stringify({

        name,

        firstname,

        lastname,

        email,

        location,

        center,

        status,

      }),

    });

    const json = await response.json();

    //console.log(json, "UPDATE");

    await getUsers();

  };


  // Delete a Note

  const deleteUser = async (id) => {

    // API Call



    const response = await fetch(`/api/user/deleteUser/${id}`, {

      method: "DELETE",

      headers: {

        "Content-Type": "application/json",

        "auth-token": sessionStorage.getItem("token"),

      },

    });

    const json = response.json();

    getUsers();



  };


  const getUsersList = async () => {
    const response = await fetch(`/api/user/fetchalluserslist`,

      {

        method: "GET",

        headers: {

          "Cache-Control": "no-cache",

          "Content-Type": "application/json",

          "auth-token": sessionStorage.getItem("token"),

        },

      }

    );

    const json = await response.json();
    console.log(json.usersList)
    setUsersList(json.usersList)


  };


  return (

    <userContext.Provider

      value={{
        Users,
        UsersInactive,
        getUsers,
        deleteUser,
        editUsers,
        getUsersList,
        userList
      }}

    >

      {props.children}

    </userContext.Provider>

  );

};

export default UserState;

