import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const SmtpSettings = (props) => {
  let navigate = useNavigate();
  const [Settings, setSettings] = useState({});
  const [errors, setErrors] = useState({});

  const getSMTPSettingsData = async () => {
    const response = await fetch(
      `/api/general_settings/settingsdata/smtp-settings`,
      {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'auth-token': sessionStorage.getItem('token'),
        },
      }
    );

    const json = await response.json();
    setSettings(json.settings.data);
  };

  const editGeneralSettingsSMTPData = async (
    id,
    email,
    auth_email,
    password,
    port,
    host
  ) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('auth_email', auth_email);
    formData.append('password', password);
    formData.append('port', port);
    formData.append('host', host);

    axios
      .put('/api/general_settings/settingsupdate/' + id, formData)
      .then((res) => {
        if (res.data.success === true) {
          props.showAlert(res.data.message, 'success');
        } else {
          props.showAlert(res.data.message, 'danger');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateFields = () => {
    let tempErrors = {};
    if (!Settings.email) tempErrors.email = 'Email is required';
    if (!Settings.auth_email) tempErrors.auth_email = 'Auth email is required';
    if (!Settings.password) tempErrors.password = 'Password is required';
    if (!Settings.port) tempErrors.port = 'Port is required';
    if (!Settings.host) tempErrors.host = 'Host is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleClick = (e) => {
    if (validateFields()) {
      editGeneralSettingsSMTPData(
        (Settings['_id'] = 'smtp-settings'),
        Settings.email,
        Settings.auth_email,
        Settings.password,
        Settings.port,
        Settings.host
      );
    } else {
      props.showAlert('Please fill all required fields', 'danger');
    }
  };

  const onChange = (e) => {
    setSettings({ ...Settings, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('token') === null ||
      sessionStorage.getItem('user-type') !== 'admin'
    ) {
      navigate('/');
    } else {
      getSMTPSettingsData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Email SMTP settings</h2>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div className="row">
            <div className="col-md-12">
              <div className="form_fields mb-4">
                <label htmlFor="email" className="form_label">
                  Email (To be used to send out results)
                </label>
                <input
                  type="text"
                  name="email"
                  onChange={onChange}
                  value={Settings.email || ''}
                  required
                  placeholder="Email"
                  className={errors.email ? 'border-color-red-error' : ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="auth_email" className="form_label">
                  Auth Email
                </label>
                <input
                  type="text"
                  name="auth_email"
                  onChange={onChange}
                  value={Settings.auth_email || ''}
                  required
                  placeholder="Email"
                  className={errors.auth_email ? 'border-color-red-error' : ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="password" className="form_label">
                  Auth Password
                </label>
                <input
                  type="password"
                  name="password"
                  onChange={onChange}
                  value={Settings.password || ''}
                  required
                  placeholder="Password"
                  className={errors.password ? 'border-color-red-error' : ''}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="port" className="form_label">
                  Port
                </label>
                <input
                  type="text"
                  name="port"
                  onChange={onChange}
                  value={Settings.port || ''}
                  required
                  placeholder="Port"
                  className={errors.port ? 'border-color-red-error' : ''}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="host" className="form_label">
                  Host
                </label>
                <input
                  type="text"
                  name="host"
                  onChange={onChange}
                  value={Settings.host || ''}
                  required
                  placeholder="Host"
                  className={errors.host ? 'border-color-red-error' : ''}
                />
              </div>
            </div>
            <div className="col-md-12 cancel_btn">
              <Link className="btn_cs sky_border_btn" to="/home">
                Cancel
              </Link>
              <div className="add_qus_submit d-flex gap-3 justify-content-end">
                <button
                  type="submit"
                  className="btn_cs sky_bg_btn"
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmtpSettings;
