import React, { Component } from "react";
import ImageUploading from 'react-images-uploading';
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import { Navigate, Link } from "react-router-dom";
import axios from "axios";

class AddExamType extends React.Component {

	constructor(props) {

		super(props)

		this.state = {
			weightageList: {}
		};

		this.state = {
			formValues: [{ category_id: '', question_weightage: '', number_of_questions: '' }],
			exam: '',
			passcode: '',
			show_result: '',
			status: '',
			percentage: '',
			examtime: '',
			image: '',
			previewUrl: '',
			previewImageName: '',
			imageName: '',
			ContentView: EditorState.createEmpty(),
			content: '',
			categorieslist: [],
			ExamSubmit: "",
			weightage: [],
			currentSelectedCat: "",
			TotalMarks: 0,
			TotalQuestion: 0,
			countries: [{ id: 1, country: '', state: '' }],
			locations: [],
			centers: [],
			inputText: '',
			inputCenter: '',
			formErrors: {},
			// categoriesData: [
			//   { id: '6516d087d0d63e631b24bd05', name: 'Math', states: ['1', '2', '4'] },
			//   { id: '651bda2c28797018b9421853', name: 'Logic', states: ['2', '5', '8'] },
			//   { id: '651bd9cc28797018b942184c', name: 'Comunication', states: ['3', '2', '1'] },
			//   // Add more countries and states as needed
			// ]
		};

		this.handleSubmit = this.handleSubmit.bind(this)


	}

	componentDidMount() {
		this.getCategoryData();

	}


	handleCountryChange = (index, event) => {
		const selectedCountry = event.target.value;
		const updatedCountries = [...this.state.countries];
		updatedCountries[index].country = selectedCountry;
		updatedCountries[index].state = ''; // Reset state when country changes
		this.setState({ countries: updatedCountries });
	};

	handleStateChange = (index, event) => {
		const selectedState = event.target.value;
		const updatedCountries = [...this.state.countries];
		updatedCountries[index].state = selectedState;
		this.setState({ countries: updatedCountries });
	};

	addCountry = () => {
		const newId = this.state.countries.length + 1;
		this.setState(prevState => ({
			countries: [...prevState.countries, { id: newId, country: '', state: '' }]
		}));
	};

	removeCountry = (id) => {
		const updatedCountries = this.state.countries.filter((country) => country.id !== id);
		this.setState({ countries: updatedCountries });
	};

	handleChange = (i, e) => {
		if (Number(e.target.max) === 0) {
			this.props.showAlert('Add category and weightage first to add no. of questions.', "danger");
		} else {
			if (e.target.value == 0) {
				this.props.showAlert('Please enter a value greater than 0', "danger");
			} else if (e.target.value > Number(e.target.max)) {
				this.props.showAlert(`Please enter a value less than or equal to ${e.target.max}`, "danger");
			} else {
				let formValues = this.state.formValues;
				formValues[i][e.target.name] = e.target.value;
				this.setState({ formValues });
				let Totalmarks = 0;
				let TotalQuestions = 0;
				formValues.forEach(x => {
					Totalmarks += (x.question_weightage * x.number_of_questions);
					TotalQuestions += Number(x.number_of_questions);
					this.setState({
						TotalMarks: Totalmarks,
						TotalQuestion: TotalQuestions
					});
				});
			}
		}
	};

	handleChangeWeightage(i, e) {

		let MaxQuestion = e.target.options[e.target.selectedIndex].getAttribute('totalquestion');

		document.getElementById(i).setAttribute("max", MaxQuestion);

		let formValues = this.state.formValues;

		formValues[i][e.target.name] = e.target.value;

		this.setState({ formValues });

	}

	handleChangeCats = async (i, e) => {

		let formValues = this.state.formValues;

		formValues[i][e.target.name] = e.target.value;

		formValues[i]['number_of_questions'] = '';

		//console.log(formValues[i]['number_of_questions'])

		this.setState({ formValues });

		//console.log(formValues);

	}

	handleChangeoption(e) {

		//console.log(e.target.value)

		this.setState({ [e.target.name]: e.target.value });

		//console.log(this.state)

	}

	handleChange2(e) {



		this.setState({ [e.target.name]: e.target.value });



	}

	// On file select (from the pop up)
	onFileChange = (event) => {
		// Update the state
		//console.log(event.target.files[0])
		this.setState({
			image: event.target.files[0],
		});
		this.setState({
			previewUrl: URL.createObjectURL(event.target.files[0])
		})
		this.setState({
			previewImageName: event.target.files[0].name
		})
	};

	handleChangeimage(imageList) {
		if (imageList[0]['file']) { this.setState({ imageName: imageList[0]['file']['name'] }) }

		this.setState({ image: imageList[0]['data_url'] })

	}

	handleEditorChange(content) {

		this.setState({ ContentView: content });

		let check_content = convertToHTML(content.getCurrentContent());

		this.setState({ content: check_content });

	};

	addFormFields() {

		this.setState(({

			formValues: [...this.state.formValues, { category_id: "", number_of_questions: "" }]

		}))

	}

	removeFormFields(i) {

		let formValues = this.state.formValues;

		formValues.splice(i, 1);

		this.setState({ formValues });

		let Totalmarks = this.state.TotalMarks;
		let TotalQuestions = this.state.TotalQuestion;
		formValues.forEach(x => {

			Totalmarks = Totalmarks - x.question_weightage * x.number_of_questions
			TotalQuestions = TotalQuestions - x.number_of_questions;
			this.setState(prevState => ({
				TotalMarks: Totalmarks,
				TotalQuestion: TotalQuestions
			}));
		});

	}

	getCategoryData = async (e) => {
		const response = await fetch("/api/categories/getCategoryListWithWeightage", {
			method: "GET",
			headers: {
				"Cache-Control": "no-cache",
				"Content-Type": "application/json",
				"auth-token": sessionStorage.getItem("token"),
			},
		}
		);

		const json = await response.json();
		this.setState({ categorieslist: json.data })
	}

	validate = () => {
		const errors = {};
		const { formValues, exam } = this.state;

		let check2 = true;
		this.state.formValues.forEach((element) => {
			if (element.category_id === '' || element.number_of_questions === '') {
				check2 = false;
			}
		});

		const currentPackage = parseInt(sessionStorage.getItem('adminPlanId'));

		if (currentPackage === 1) {
			if (!exam) {
				errors.exam = 'Name is required';
			}
			if (!this.state.passcode) {
				errors.passcode = 'Passcode is required';
			}
			if (!this.state.content) {
				errors.content = 'Content is required';
			}
			if (!this.state.image) {
				errors.image = 'Image is required';
			}
			if (!this.state.examtime) {
				errors.examtime = 'Exam time is required';
			}
			if (!this.state.percentage) {
				errors.percentage = 'Percentage is required';
			}
			if (!this.state.status) {
				errors.status = 'Status is required';
			}
			if (!check2) {
				errors.category = 'Category is required';
			}
		} else {
			if (!exam) {
				errors.exam = 'Name is required';
			}
			if (!this.state.passcode) {
				errors.passcode = 'Passcode is required';
			}
			if (!this.state.content) {
				errors.content = 'Content is required';
			}
			if (!this.state.image) {
				errors.image = 'Image is required';
			}
			if (!this.state.examtime) {
				errors.examtime = 'Exam time is required';
			}
			if (!this.state.percentage) {
				errors.percentage = 'Percentage is required';
			}
			if (!this.state.status) {
				errors.status = 'Status is required';
			}
			if (!check2) {
				errors.category = 'Category is required';
			}
			if (!this.state.locations || this.state.locations.length === 0) {
				errors.locations = 'Locations are required';
			}
			if (!this.state.centers || this.state.centers.length === 0) {
				errors.centers = 'Centers are required';
			}
			if (!this.state.show_result) {
				errors.show_result = 'Show result is required';
			}
		}

		return errors;
	};


	handleSubmit = async (event) => {
		event.preventDefault();

		const errors = this.validate();
		console.log(errors)
		this.setState({
			formErrors: errors,
			isSubmitted: true
		});

		if (Object.keys(errors).length > 0) {
			return;
		}

		const PassCodeValideString = /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

		if (this.state.passcode.match(PassCodeValideString)) {
			this.props.showAlert('Passcode should be without special characters. Please use only letters, numbers and underscore.', "danger");
			return;
		}

		const formData = new FormData();
		formData.append('b1', JSON.stringify(this.state.formValues));
		formData.append('b2', this.state.exam);
		formData.append('b3', this.state.status);
		formData.append('b4', this.state.percentage);
		formData.append('b5', this.state.examtime);
		formData.append('file', this.state.image);
		formData.append('b7', this.state.content);
		formData.append('b8', this.state.passcode);
		formData.append('b9', this.state.show_result);
		formData.append('b10', this.state.TotalMarks);
		formData.append('b11', this.state.TotalQuestion);
		formData.append('b12', JSON.stringify(this.state.locations));
		formData.append('b13', JSON.stringify(this.state.centers));
		formData.append('b14', parseInt(sessionStorage.getItem('adminPlanId')));

		axios.post('/api/exam/add-exam', formData)
			.then(res => {
				if (res.data.Success) {
					this.props.showAlert(res.data.Success, "success");
					this.setState({ ExamSubmit: 'Done' });
				} else {
					this.props.showAlert(res.data.Error, "danger");
				}
			});
	};


	handleInputChange = (e) => {
		this.setState({ inputText: e.target.value });
	};

	handleInputKeyDown = (e) => {
		const { inputText, locations } = this.state;
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			const newLocation = inputText.trim();
			if (newLocation) {
				this.setState({
					locations: [...locations, newLocation],
					inputText: ''
				});
			}
		}
	};

	handleLocationRemove = (locationToRemove) => {
		this.setState({
			locations: this.state.locations.filter(location => location !== locationToRemove)
		});
	};


	handleInputCenterChange = (e) => {
		this.setState({ inputCenter: e.target.value });
	};
	handleCenterKeyDown = (e) => {
		const { inputCenter, centers } = this.state;
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			const newCenter = inputCenter.trim();
			if (newCenter) {
				this.setState({
					centers: [...centers, newCenter],
					inputCenter: ''
				});
			}
		}
	};

	handleCenterRemove = (centerToRemove) => {
		this.setState({
			centers: this.state.centers.filter(center => center !== centerToRemove)
		});
	};


	render() {
		const { locations, inputText, centers, inputCenter, formErrors } = this.state;

		let minuesval = 0;
		const currentPackage = parseInt(sessionStorage.getItem('adminPlanId'));
		let colmd = '4';
		if (currentPackage === 1) {
			colmd = 6;
		}
		return (
			<>
				<div className="board_title_data d-flex align-items-center justify-content-between gap-3">
					<div className="board_title d-flex align-items-center gap-3">
						{(this.state.ExamSubmit != "") ?
							< Navigate to="/exams-list" replace={true} /> : ""}
						<h2>Add New Exam</h2>
					</div>
				</div>
				<div className="addExamType_main_wrap mb-5">
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<div className="">
									<div className="data_wraper_pages_outer mb-4">
										<div className="data_wraper_pages">
											<div className="board_title ">
												<h2>Exam details</h2>
											</div>
											<div className="row">
												<div className={`col-lg-6 col-md-12`}>
													<div className="form_fields mb-4">
														<label className="form_label">Name</label>
														<input type="text" name="exam" className={formErrors.exam ? 'border-color-red-error' : ''} placeholder="Name" onChange={e => this.handleChange2(e)} />
													</div>
												</div>
												<div className={`col-lg-2 col-md-12`}>
													<div className="form_fields mb-4">
														<label>Status</label>
														<select className={formErrors.status ? 'border-color-red-error' : ''} name="status" onChange={e => this.handleChangeoption(e)} >
															<option value="">-- Select one --</option>
															<option value="Active">Active</option>
															<option value="Inactive">Inactive</option>
														</select>
													</div>
												</div>
												<div className={`col-lg-2 col-md-12`}>
													<div className="form_fields mb-4">
														<label>Total marks</label>
														<span className="fld_sm_hity">{this.state.TotalMarks}</span>
													</div>
												</div>
												<div className={`col-lg-2 col-md-12`}>
													<div className="form_fields mb-4">
														<label>Total questions</label>
														<span className="fld_sm_hity">{this.state.TotalQuestion}</span>
													</div>
												</div>
												<div className={`col-lg-6 col-md-12`}>
													<div className="form_fields mb-4">
														<label className="form_label">Passcode</label>
														<input className={(formErrors.passcode) ? 'border-color-red-error' : ''} type="text" name="passcode" placeholder="Passcode" onChange={e => this.handleChange2(e)} />
													</div>
												</div>
												{currentPackage !== 1 &&
													<div className="col-lg-2 col-md-12">
														<div className="form_fields mb-4">
															<label className="form_label">Show result</label>
															<select className={(formErrors.show_result) ? 'border-color-red-error' : ''} name="show_result" onChange={e => this.handleChangeoption(e)} >
																<option value="">-- Select one --</option>
																<option value="true">Yes</option>
																<option value="false">No (Hide)</option>
															</select>
														</div>
													</div>
												}

												<div className="col-lg-2 col-md-12">
													<div className="form_fields mb-4">
														<label className="form_label">Exam Time</label>
														<select className={(formErrors.examtime) ? 'border-color-red-error' : ''} name="examtime" onChange={e => this.handleChangeoption(e)} >
															<option value="">-- Select one --</option>
															<option value="10">10 minutes</option>
															<option value="20">20 minutes</option>
															<option value="30">30 minutes</option>
															<option value="40">40 minutes</option>
															<option value="50">50 minutes</option>
															<option value="60">60 minutes</option>
															<option value="70">70 minutes</option>
															<option value="80">80 minutes</option>
															<option value="90">90 minutes</option>
															<option value="100">100 minutes</option>
															<option value="110">110 minutes</option>
															<option value="120">120 minutes</option>
															<option value="130">130 minutes</option>
															<option value="140">140 minutes</option>
															<option value="150">150 minutes</option>
															<option value="160">160 minutes</option>
															<option value="170">170 minutes</option>
															<option value="180">180 minutes</option>
														</select>
													</div>
												</div>
												<div className="col-lg-2 col-md-12">
													<div className="form_fields mb-4">
														<label className="form_label">Passing percentage(%)</label>
														<select className={(formErrors.percentage) ? 'border-color-red-error' : ''} name="percentage" onChange={e => this.handleChangeoption(e)} >
															<option value="">-- Select one --</option>
															<option value="05">05%</option>
															<option value="10">10%</option>
															<option value="15">15%</option>
															<option value="20">20%</option>
															<option value="25">25%</option>
															<option value="30">30%</option>
															<option value="35">35%</option>
															<option value="40">40%</option>
															<option value="45">45%</option>
															<option value="50">50%</option>
															<option value="55">55%</option>
															<option value="60">60%</option>
															<option value="65">65%</option>
															<option value="70">70%</option>
															<option value="75">75%</option>
															<option value="80">80%</option>
															<option value="85">85%</option>
															<option value="90">90%</option>
															<option value="95">95%</option>
															<option value="100">100%</option>
														</select>
													</div>
												</div>

											</div>
										</div>
									</div>
									<div className="data_wraper_pages_outer mb-4">
										<div className="data_wraper_pages">
											<div className="board_title ">
												<h2>Exam start page</h2>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="form_fields mb-4">
														<label className="form_label">Instructions</label>
														<Editor wrapperClassName={(formErrors.content) ? 'border-color-red-error' : ''} toolbar={{
															inline: { inDropdown: true },
															list: { inDropdown: true },
															textAlign: { inDropdown: true },
															link: { inDropdown: true },
															history: { inDropdown: true },
														}} editorState={this.state.ContentView} onEditorStateChange={e => this.handleEditorChange(e)} />
													</div>
												</div>



												<div className="col-lg-6 col-md-6">
													<div className="form_fields mb-4">
														<label >Banner</label>

														<div className="banner_custom_div ">
															<input className={(formErrors.image) ? 'border-color-red-error noborder' : 'noborder'}
																type="file" accept=".png, .jpg, .jpeg" name="photo"

																onChange={this.onFileChange}
															/>

															<div className="periveimage">
																{this.state.previewUrl ? (
																	<>
																		<span className="imagewithclose">

																			<img src={this.state.previewUrl} alt="" width="100" />
																			{/* <a onClick={() => onImageRemove(0)}>
																			<CloseOutlined
																				style={{ fontSize: "30px", color: "red" }}
																			/>
																		</a> */}
																		</span>
																	</>
																) : (
																	""
																)}
																<div className="imagewithclose">
																	{this.state.previewImageName ? this.state.previewImageName : ''}
																</div>
															</div>
														</div>

													</div>
												</div>

												<div className="col-lg-6 col-md-6"></div>
												{currentPackage !== 1 &&
													<div className="col-lg-6 col-md-12">
														<div className="form_fields mb-4">
															<label>Locations</label>
															<input className={(formErrors.locations) ? 'border-color-red-error' : ''} type="text" value={inputText} onChange={this.handleInputChange} onKeyDown={this.handleInputKeyDown} placeholder="Enter location, separated by commas" />
															<div className="location_wraps">
																{locations.map((location, index) => (
																	<span key={index} className="location_result">
																		{location}
																		<button type="button" onClick={() => this.handleLocationRemove(location)}><i class="fa-solid fa-xmark"></i></button>
																	</span>
																))}
															</div>
														</div>
													</div>
												}
												{currentPackage !== 1 &&
													<div className="col-lg-6 col-md-12">
														<div className="form_fields mb-4">
															<label>Center</label>
															<input
																className={(formErrors.centers) ? 'border-color-red-error' : ''}
																type="text"
																value={inputCenter}
																onChange={this.handleInputCenterChange}
																onKeyDown={this.handleCenterKeyDown}
																placeholder="Enter center, separated by commas"
															/>
															<div className="location_wraps">
																{centers.map((center, index) => (
																	<span key={index} className="location_result">
																		{center}
																		<button type="button" onClick={() => this.handleCenterRemove(center)}><i class="fa-solid fa-xmark"></i></button>
																	</span>
																))}
															</div>
														</div>
													</div>
												}
											</div>
										</div>
									</div>
									<div className="data_wraper_pages_outer">
										<div className="data_wraper_pages">
											<div className="board_title ">
												<h2>Set question types</h2>
											</div>
											<div className="row">
												<div className="fields_plus_minus">

													{this.state.countries.map((country, index) => (
														<div className="fields_add_remv" key={country.id}>

															<div className="row">
																<div className="col-lg-6 col-md-12">
																	<div className="form_fields mb-4">
																		<label className="form_label">Category (Total Questions)</label>
																		<select className={(formErrors.category) ? 'border-color-red-error' : ''} id="category_id" name="category_id"
																			value={country.country}
																			onChange={(e) => {
																				this.handleCountryChange(index, e);
																				this.handleChangeCats(index, e);
																			}}
																		>
																			<option value="">Select Category</option>
																			{this.state.categorieslist.map((c) => (
																				<option key={c.id} value={c.id} disabled={c.no_of_question < 1 ? 'true' : ''}>
																					{c.name} ({c.no_of_question})
																				</option>
																			))}
																		</select>
																	</div>
																</div>
																<div className="col-lg-3 col-md-12">
																	<div className="form_fields mb-4">
																		<label className="form_label">Weightage wise questions available</label>
																		<select
																			value={country.state}
																			onChange={(e) => { this.handleStateChange(index, e); this.handleChangeWeightage(index, e) }}
																			// disabled={!country.country}
																			id="question_weightage" name="question_weightage"
																		>
																			<option value="">Select Weightage</option>
																			{country.country &&
																				this.state.categorieslist.find((c) => c.id === country.country)?.categoryWeightage.map((state, idx) => {
																					const [key, value] = Object.entries(state)[0];
																					return (<option totalquestion={value} key={idx} value={key}>
																						{`${key} (${value} questions)`}
																					</option>)
																				})}
																		</select>
																	</div>
																</div>
																<div className="col-lg-3 col-md-12">
																	<div className="form_fields mb-4">
																		<label>No. of questions to add</label>
																		<div className="remove_cat_wrap d-flex gap-2">
																			<input
																				// disabled={!country.state} 
																				type="number" name="number_of_questions" id={index} min={1} onChange={e => this.handleChange(index, e)} />
																			{(this.state.countries.length - 1 === index) &&
																				<button className="add-exam-option-button add_remv_btn btn_cs sky_bg_btn add_option" type="button" onClick={() => { this.addCountry(); this.addFormFields(); }}>
																					<i className="fa-solid fa-plus"></i>
																				</button>
																			}
																			{(this.state.countries.length > 1) &&
																				<button type="button" className="add-exam-option-button add_remv_btn remve" onClick={() => { this.removeCountry(country.id); this.removeFormFields(index) }}>
																					<i className="fa-solid fa-minus"></i>
																				</button>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													))}

												</div>

												<div class="col-lg-12 col-md-12">
													<div class="add_qus_submit d-flex gap-3 justify-content-end">
														<Link className="btn_cs sky_border_btn" to="/exams-list">CANCEL</Link>

														<button className="btn_cs sky_bg_btn" type="submit">SUBMIT</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</form >
				</div >
			</>
		);
	}
}

export default AddExamType;