import React, { useEffect, useRef, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import catesContext from "../context/cats/CatesContext";
import { Table, Input, Select, Modal, Popconfirm } from 'antd';
import filter_icon from "../more-filters.png";
import moment from "moment";

const CategoriesList = (props) => {
  let navigate = useNavigate();
  let currentPackage = 0;
  const context = useContext(catesContext);
  const [DataInfo, setDataInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { getCates, editCates, addCates, catesActive, catesInActive } = context;
  const [filterTable, setFilterTable] = useState(null);
  const [filterTab, setFilterTab] = useState('active');
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (
      sessionStorage.getItem("token") === null ||
      sessionStorage.getItem("user-type") === "admin"
    ) {
      getCates();
    } else {
      navigate("/");
    }

  }, []);
  const [catess, setCatess] = useState({

    id: "",

    name: "",

    status: "",

  });
  currentPackage = parseInt(sessionStorage.getItem('adminPlanId'));
  const editdatahandler = (data) => {
    setIsModalOpen(true);
    setDataInfo(data);
    setCatess(data);
  }

  // Delete a Note
  const deleteCates = async (id) => {
    const response = await fetch(`/api/categories/deleteCates/${id}`, {

      method: "DELETE",

      headers: {

        "Content-Type": "application/json",

        "auth-token": sessionStorage.getItem("token"),

      },

    });
    const json = await response.json();
    if (json.status === "success") {
      props.showAlert(json.data.message, "success");
    } else {
      props.showAlert(json.error.message, "danger");
    }
    getCates()
  };
  const deletecategoryhandeler = (data) => {
    deleteCates(data.id);
  }

  const canceldelete = (e) => {

  };

  const onChange = (e) => {
    setCatess({ ...catess, [e.target.name]: e.target.value });
  };
  const handleChange = (value) => {
    setCatess({ ...catess, status: value });
  };

  const handleOkEdit = () => {
    if (catess.name === '' || catess.status === '') {
      props.showAlert('Oops! Looks like you forgot something. Please fill out the all fields.', 'danger');
      return false;
    }
    editCates(
      catess.id,
      catess.name,
      catess.status,
    ).then(function (data) {
      if (data['response_msg'] === 'duplicate_data') {
        props.showAlert("Oops! It seems that the category you're trying to add already exists. Please provide a unique name to continue.", "danger");
      } else {
        setIsModalOpen(false);
        props.showAlert("Category updated successfully!", "success");
      }
    }
    );

  }
  const handleCancel = () => {

    setIsModalOpen(false);

  };
  const handleAddModelOpen = () => {

    setIsAddModalOpen(true);

  }
  const handleAddCancel = () => {

    setIsAddModalOpen(false);

  }
  const handleOkAdd = () => {



    if (catess.name === '' || catess.status === '') {

      props.showAlert('Oops! Looks like you forgot something. Please fill out the required field.', 'danger');

      return false;

    }



    addCates(

      catess.name,

      catess.status,

      currentPackage,

    ).then(function (data) {

      //console.log(data['response_msg'])



      if (data['response_msg'] === 'duplicate_data') {



        props.showAlert("Oops! It seems that the category you're trying to add already exists. Please provide a unique name to continue.", "danger");

      } else if (data['response_msg'] === 'plan_error') {
        props.showAlert("Please upgrade your plan you have exceeded your max category limit", "danger");
      } else {

        setIsAddModalOpen(false);

        props.showAlert("Category added successfully!", "success");

      }

    }



    );



  }

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChangetable = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };


  const onTableSearch = (e) => {
    // console.log(filterTab);
    let filterTable;
    if (filterTab === 'active') {
      filterTable = catesActive.filter((o) =>

        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {

      filterTable = catesInActive.filter((o) =>

        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }


    setFilterTable(filterTable);
  };


  const columns = [

    {
      title: 'S.No',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },

    {
      title: 'Category name',
      dataIndex: 'name',
      key: 'name',
      width: '46%',
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },

    {
      title: 'Total questions',
      dataIndex: 'totalQuestions',
      key: 'name',
      width: '20%',
      filteredValue: filteredInfo.totalQuestions || null,
      onFilter: (value, record) => record.totalQuestions.includes(value),
      sorter: (a, b) => a.totalQuestions.length - b.totalQuestions.length,
      sortOrder: sortedInfo.columnKey === 'totalQuestions' ? sortedInfo.order : null,
      ellipsis: true,
    },



    {

      title: 'Date Created',
      dataIndex: 'date',
      key: 'date',
      filteredValue: filteredInfo.date || null,
      onFilter: (value, record) => record.date.includes(value),
      sorter: (a, b) => moment(a.date, 'DD-MM-YYYY').unix() - moment(b.date, 'DD-MM-YYYY').unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      ellipsis: true,
      width: '20%',
    },

    {

      title: 'Action',
      align: '-webkit-center',
      button: true,

      render: (data) => (<>
        <div className="action_dropdown dropdown dropstart ">
          <a className="action_toggle dropdown-toggle " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </a>
          <ul className="dropdown-menu">

            {/* <li><a onClick={() => viewdatahandler(data)}><i className="fa-regular fa-eye" ></i>View</a></li> */}

            <li><a onClick={() => editdatahandler(data)}><i className="fa-regular fa-pen-to-square"></i>Edit</a></li>


            <li>
              <Popconfirm
                title="Do you really want to delete this category?"
                description={getFirstFiveWords(data.name)}
                onConfirm={() => { deletecategoryhandeler(data) }}
                onCancel={canceldelete}
                okText="Confirm Delete"
                cancelText="Don't Delete"
              >


                <a ><i className="fa-solid fa-trash-can"></i>Delete</a>
              </Popconfirm>
            </li>
          </ul>
        </div>



      </>),

      width: '10%',

    },

  ];


  const filterHander = (e) => {
    setFilterTab(e);
    let filterTable;
    if (e === 'active') {
      filterTable = catesActive;
      // let filterTable = catesActive.filter((cat) => {
      //   if (cat['status'].toLowerCase() === e.toLowerCase()) {
      //     return true;
      //   }
      //   return false;
      // });
    } else {
      filterTable = catesInActive;

    }

    setFilterTable(filterTable);
  }


  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {

    console.log('selectedRowKeys changed: ', newSelectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);

  };

  const rowSelection = {

    selectedRowKeys,

    onChange: onSelectChange,

  };

  let hasSelected = selectedRowKeys.length > 0;

  function getFirstFiveWords(input) {
    if (!input) return '';
    const words = input.split(' ');
    return words.slice(0, 5).join(' ');
  }
  return (

    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Categories</h2>
        </div>
        <div className="board_form_filter d-flex gap-3 align-items-center">
          <div className="mobile_all_filters d-block d-sm-none">
            <div class="borad_more_filters dropdown remove_cret">
              <a class="more_filters dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={filter_icon} alt="filters" />
              </a>
              <ul class="dropdown-menu">
                <li class="mobile_serch">
                  <div className="borad_srch_form">
                    <Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <a className="btn_cs sky_bg_btn" onClick={handleAddModelOpen} name="add_category" href="#"><i className='bx bx-plus-circle'></i>&nbsp;Add New</a>
        </div>
      </div>
      <div className="main_wraper_pages p-15 pt-0">
        <div className="tabs_srch_form d-flex justify-content-between">
          <div className="data_tabs_pagination d-flex justify-content-between align-items-center border_bt_gray w-100">
            <nav>
              <div className="data_ac_tabs" id="data_ac_tabs" role="tablist">

                <a className="tab_item active" data-bs-toggle="tab" data-bs-target="#active_data" aria-controls="nav-home" aria-selected="true" onClick={() => filterHander('active')}>Active</a>
                <a className="tab_item" data-bs-toggle="tab" onClick={() => filterHander('inactive')}>Inactive</a>

              </div>
            </nav>
            <div className="record_search_fm_totl m-0">
              <span className="data_total total_record">	{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>
              <span className="data_total total_record">Total: {filterTable ? filterTable.length : catesActive.length}</span>
              <div className="borad_srch_form d-none d-sm-block">
                <Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
              </div>
            </div>
          </div>
          <div className="tab-content" id="data-tabContent">
            <div className="tab-pane show active" id="active_data" role="tabpanel">

            </div>

          </div>
        </div>


        <div className="tab-content filter_custom" id="data-tabContent">
          <div className="tab-pane show active" id="active_data" role="tabpanel">
            <div className="data_table same_table_als mt-3">

              <Table rowSelection={rowSelection} columns={columns} dataSource={(filterTable) ? filterTable : catesActive} onChange={handleChangetable} pagination={{
                onChange(current) {
                  setPage(current);
                }
              }} />
            </div></div></div>
      </div>
      {/* Edit category model */}

      <Modal title="Edit Category" open={isModalOpen} onOk={handleOkEdit} okText="Submit" cancelText="Close" onCancel={handleCancel}>
        <div className="signup_form add_question_form popup_form">
          <div className="qs_form">
            <div className="form_fields mb-2" style={{ 'max-width': '100%' }}>
              <label htmlFor="title" className="form_label">
                {" "}
                Name{" "}
              </label>
              <input
                type="text"
                key={DataInfo.name}
                name="name"
                onChange={onChange}
                defaultValue={DataInfo.name}
              />
            </div>
            <div className="form_fields" style={{ 'max-width': '100%' }}>
              <label htmlFor="title" className="form_label">
                {" "}
                Status{" "}
              </label>
              <Select
                defaultValue={DataInfo.status}
                onChange={handleChange}
                className="form_label"
                name="status"
                key="status"
                style={{
                  width: 450,
                }}
                options={[{ value: 'Active', label: 'Active' }, { value: 'Inactive', label: 'Inactive' }]}
              />
            </div>
          </div>
        </div>
      </Modal>



      {/* Add category model */}



      <Modal title="Category Details" open={isAddModalOpen} onOk={handleOkAdd} okText="Submit" cancelText="Close" onCancel={handleAddCancel}>

        <div className="signup_form add_question_form popup_form">

          <div className="qs_form">

            <div className="form_fields mb-2" style={{ 'max-width': '100%' }}>

              <label htmlFor="title" className="form_label">

                {" "}

                Name{" "}

              </label>

              <input

                type="text"

                key='name'

                name="name"

                onChange={onChange}

                defaultValue=''

              />





            </div>



            <div className="form_fields" style={{ 'max-width': '100%' }}>

              <label htmlFor="title" className="form_label">

                {" "}

                Status{" "}

              </label>

              <Select

                defaultValue=''

                onChange={handleChange}

                name="status"

                className="form_label"

                style={{

                  width: 450,

                }}

                key="status"

                options={[{ value: '', label: '--Please choose an Status--' }, { value: 'Active', label: 'Active' }, { value: 'Inactive', label: 'Inactive' }]}

              />

            </div>



          </div>

        </div>

      </Modal>









    </>

  );

};

export default CategoriesList;