import React, { useEffect, useRef, useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import quesContext from "../context/ques/QuesContext";
import Highlighter from 'react-highlight-words';
import jsPDF from "jspdf";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { Button, Table, Input, Space, DatePicker } from 'antd';
//import { icons } from "antd/es/image/PreviewGroup";
import filter_icon from "../more-filters.png";

const ResultList = ({ apiData, fileName }) => {

  const fileType =

    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dataInfo, setDataInfo] = useState({})

  //let cls = 'fail';

  //const ref = useRef(null);

  //const refClose = useRef(null);

  const context = useContext(quesContext);

  const { result, getResult, StudentResults, getStudentResults } = context;

  let navigate = useNavigate();

  // const showModal = () => {

  //   setIsModalOpen(true);

  // }

  const handleOk = () => {

    const report = new jsPDF('portrait', 'pt', 'a4');

    report.html(document.querySelector('#currentuserinfopdf')).then(() => {

      report.save('report.pdf');

    });

  };



  const handleCancel = () => {

    setIsModalOpen(false);

  };

  useEffect(() => {
    if (
      sessionStorage.getItem("token") != null || sessionStorage.getItem("user-type") === "admin"

    ) {

      getResult();

      //getStudentResults();

    } else {

      navigate("/admin-login");

    }



  }, []);



  // const viewdatahandler = (data) => {

  //   setDataInfo(data)

  //   setIsModalOpen(true)

  // }



  const exportToCSV = (apiData, fileName, fieldsToRemove = [], customHeaders = {}, fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8', fileExtension = '.xlsx') => {
    // Remove specified fields from data
    const filteredData = apiData.map(item => {
      const newItem = { ...item };
      fieldsToRemove.forEach(field => delete newItem[field]);
      return newItem;
    });

    // Replace headers if customHeaders are provided
    const finalData = filteredData.map(item => {
      const newItem = {};
      for (const [originalKey, customKey] of Object.entries(customHeaders)) {
        if (item[originalKey] !== undefined) {
          newItem[customKey] = item[originalKey];
        }
      }
      return newItem;
    });

    // Convert the final data to a worksheet
    const ws = XLSX.utils.json_to_sheet(finalData);

    // Create a workbook with the worksheet
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    // Write the workbook to a buffer
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the buffer
    const data = new Blob([excelBuffer], { type: fileType });

    // Save the Blob as an Excel file
    FileSaver.saveAs(data, fileName + fileExtension);

  };

  const [searchText, setSearchText] = useState('');

  const [searchedColumn, setSearchedColumn] = useState('');

  const searchInput = useRef(null);

  const [filterTable, setFilterTable] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {

    confirm();

    setSearchText(selectedKeys[0]);

    setSearchedColumn(dataIndex);

  };

  const handleReset = (clearFilters) => {

    clearFilters();

    setSearchText('');

  };

  const onTableSearch = (value) => {
    let filterTable = result.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())
      )
    );
    setFilterTable(filterTable);

  };

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChangetable = (pagination, filters, sorter) => {
    //console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'no',
      width: '5%',
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: 'Name',
      key: 'name',
      width: '20%',
      render: (data) => <Link to={`/studentdetails/${data.user_id}`}>{data.name}</Link>,
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      filteredValue: filteredInfo.email || null,
      onFilter: (value, record) => record.email.includes(value),
      sorter: (a, b) => a.email.length - b.email.length,
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      filteredValue: filteredInfo.phone || null,
      onFilter: (value, record) => record.phone.includes(value),
      sorter: (a, b) => a.phone.length - b.phone.length,
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Total exam taken',
      dataIndex: 'total_exam',
      key: 'total_exam',
      width: '15%',
      filteredValue: filteredInfo.total_exam || null,
      onFilter: (value, record) => record.total_exam.includes(value),
      sorter: (a, b) => parseInt(a.total_exam, 10) - parseInt(b.total_exam, 10),
      sortOrder: sortedInfo.columnKey === 'total_exam' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Pass',
      dataIndex: 'pass',
      width: '10%',
      key: 'pass',
      filteredValue: filteredInfo.pass || null,
      onFilter: (value, record) => record.pass.includes(value),
      sorter: (a, b) => parseInt(a.pass, 10) - parseInt(b.pass, 10),
      sortOrder: sortedInfo.columnKey === 'pass' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Fail',
      dataIndex: 'fail',
      width: '10%',
      key: 'fail',
      filteredValue: filteredInfo.fail || null,
      onFilter: (value, record) => record.fail.includes(value),
      sorter: (a, b) => parseInt(a.fail, 10) - parseInt(b.fail, 10),
      sortOrder: sortedInfo.columnKey === 'fail' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '10%',
      filteredValue: filteredInfo.date || null,
      onFilter: (value, record) => record.date.includes(value),
      sorter: (a, b) => moment(a.date, 'DD-MM-YYYY').unix() - moment(b.date, 'DD-MM-YYYY').unix(),
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Action',
      button: true,
      align: '-webkit-center',
      render: (data) => (
        <div className="action_dropdown dropdown dropstart">
          <a className="action_toggle dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link to={`/studentdetails/${data.user_id}`}>
                <i className="fa-regular fa-eye" aria-hidden="true"></i>View details
              </Link>
            </li>
          </ul>
        </div>
      ),
      width: '10%',
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {

    console.log('selectedRowKeys changed: ', newSelectedRowKeys);

    setSelectedRowKeys(newSelectedRowKeys);

  };

  const rowSelection = {

    selectedRowKeys,

    onChange: onSelectChange,

  };

  const hasSelected = selectedRowKeys.length > 0;

  const customHeaders = {

    no: 'No',

    name: 'Name',

    phone: 'Phone',

    email: 'Email',

    marks_Obtained: 'Marks obtained',

    totalAttempted: 'Total attempted',

    max_marks: 'Max marks',

    score: 'Score',

    percentage: 'Percentage',

    location: 'Location',

    center: 'Center',

    result: 'Result',

    exam_name: 'Exam Name',

    date: 'Exam date'

  };

  const fieldsToRemove = ['id', 'key', 'exam_categories', 'user_id', 'user_percentage'];

  return (

    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>User Result List</h2>
        </div>
        <div className="board_form_filter d-flex gap-3 align-items-center">
          <div className="mobile_all_filters d-block d-sm-none">
            <div class="borad_more_filters dropdown remove_cret">
              <a class="more_filters dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={filter_icon} alt="filters" />
              </a>
              <ul class="dropdown-menu">
                <li class="mobile_serch">
                  <div className="borad_srch_form">
                    <Input.Search placeholder="Search" enterButton onSearch={onTableSearch} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="borad_srch_form d-none d-sm-block">
            <button className="btn_cs sky_bg_btn" onClick={(e) => exportToCSV(apiData, fileName, fieldsToRemove, customHeaders)}><i class="fa-solid fa-file-export"></i>&nbsp;Export</button>
          </div>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div className="record_search_fm_totl justify-content-end">

            <span className="total_record">	{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>

            <span className="total_record">Total: {filterTable ? filterTable.length : result.length}</span>
            <div className="borad_srch_form d-none d-sm-block">
              <Input.Search placeholder="Search" enterButton onChange={onTableSearch} />
            </div>
          </div>
          <div className="data_table same_table_als">
            <Table pagination={{
              onChange(current) {
                setPage(current);
              }
            }} columns={columns} rowSelection={rowSelection} dataSource={(filterTable) ? filterTable : result} onChange={handleChangetable} />
            {/* <table width="100%" cellpadding="0" cellspacing="0" border="0">
              <thead>
                <tr>
                  <th bgcolor="#f9f9f9" width="2%">
                    <div className="tHead_label thead_checkbox justify-content-center">
                      <input type="checkbox" id="all_check" />
                      <label for="all_check"></label>
                    </div>
                  </th>
                  <th bgcolor="#f9f9f9" width="4%">
                    <div className="tHead_label justify-content-center">
                      <label>S.No</label>
                    </div>
                  </th>
                  <th bgcolor="#f9f9f9" width="24%">
                    <div className="tHead_label">
                      <label>Name</label>
                      <div className="data_sorting">
                        <span><i class='bx bx-chevron-up'></i></span>
                        <span><i class='bx bx-chevron-down'></i></span>
                      </div>
                    </div>
                  </th>
                  <th bgcolor="#f9f9f9" width="30%">
                    <div className="tHead_label">
                      <label>Email</label>
                      <div className="data_sorting">
                        <span><i class='bx bx-chevron-up'></i></span>
                        <span><i class='bx bx-chevron-down'></i></span>
                      </div>
                    </div>
                  </th>
                  <th bgcolor="#f9f9f9" width="17%">
                    <div className="tHead_label justify-content-center">
                      <label>Phone</label>
                      <div className="data_sorting">
                        <span><i class='bx bx-chevron-up'></i></span>
                        <span><i class='bx bx-chevron-down'></i></span>
                      </div>
                    </div>
                  </th>
                  <th bgcolor="#f9f9f9" width="13%">
                    <div className="tHead_label justify-content-center">
                      <label>Date</label>
                      <div className="data_sorting">
                        <span><i class='bx bx-chevron-up'></i></span>
                        <span><i class='bx bx-chevron-down'></i></span>
                      </div>
                    </div>
                  </th>
                  <th bgcolor="#f9f9f9" width="10%">
                    <div className="tHead_label justify-content-center">
                      <label>Action</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="">
                    <div className="tHead_label thead_checkbox justify-content-center">
                      <input type="checkbox" id="all_check1" />
                      <label for="all_check1"></label>
                    </div>
                  </td>
                  <td data-label="S.No" align="center">01</td>
                  <td data-label="Name">Abhishek pathak</td>
                  <td data-label="Email">abhishek.transfunnel@gmail.com</td>
                  <td data-label="Phone" align="center">9571419062</td>
                  <td data-label="Date" align="center">04-04-2024</td>
                  <td data-label="Action" align="center">
                    <div className="action_dropdown dropdown dropstart">
                      <a className="action_toggle dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></a>
                      <ul className="dropdown-menu">
                        <li><a href="#"><i class="fa-regular fa-eye"></i>View</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td data-label="">
                    <div className="tHead_label thead_checkbox justify-content-center">
                      <input type="checkbox" id="all_check1" />
                      <label for="all_check1"></label>
                    </div>
                  </td>
                  <td data-label="S.No" align="center">02</td>
                  <td data-label="Name">sanskar singh</td>
                  <td data-label="Email">sanskargh@gmail.com</td>
                  <td data-label="Phone" align="center">7752908763</td>
                  <td data-label="Date" align="center">14-03-2024</td>
                  <td data-label="Action" align="center">
                    <div className="action_dropdown dropdown dropstart">
                      <a className="action_toggle dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></a>
                      <ul className="dropdown-menu">
                        <li><a href="#"><i class="fa-regular fa-eye"></i>View</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
      </div>


    </>

  )

}

export default ResultList;