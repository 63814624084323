import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const GeneralSettings = (props) => {
  let navigate = useNavigate();
  // const [Settings, setSettings] = useState({});
  const [savedImageName, setSavedImageName] = useState();

  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewName, setPreviewName] = useState(null);
  const getEmailTemplateSettings = async (id) => {
    try {
      const response = await axios.get(`/api/settings/settingsdata/${id}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'auth-token': sessionStorage.getItem('token'),
        },
      });

      const { settings } = response.data;
      //setSettings(settings.data);

      let imageUrl = settings.data.image;

      // Normalize the path to handle both backslashes and forward slashes
      const normalizedPath = imageUrl.replace(/\\/g, '/');

      // Extract the image name from the normalized path
      const nameT = normalizedPath.substring(
        normalizedPath.lastIndexOf('/') + 1
      );

      // Set the extracted name
      setSavedImageName(nameT);
    } catch (error) {
      console.error('Error fetching email template settings:', error);
    }
  };

  const editSettings = async (
    id,

    image
  ) => {
    const formData = new FormData();
    if (image) formData.append('image', image);

    axios
      .put('/api/settings/settingsupdate/' + id, formData)
      .then((res) => {
        if (res.data.success === true) {
          props.showAlert(res.data.message, 'success');
        } else {
          props.showAlert(res.data.message, 'danger');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!image) newErrors.image = 'Image is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = (e) => {
    if (!validateForm()) {
      props.showAlert('Please fill in all fields', 'danger');
      return;
    }

    editSettings('settings', image);
  };

  // const onChange = (e) => {
  //   setSettings({ ...Settings, [e.target.name]: e.target.value });
  // };

  const onFileChange = (info) => {
    const file = info.file.originFileObj;
    const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (validTypes.includes(file.type)) {
      setImage(file);
      setPreviewName(file.name);
      setPreviewUrl(URL.createObjectURL(file));
      setErrors((prevErrors) => ({ ...prevErrors, image: null }));
    } else {
      setImage(null);
      setPreviewName(null);
      setPreviewUrl(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: 'Only jpg, jpeg, and png files are allowed',
      }));
    }

    // setImage(info.file.originFileObj);
    // setPreviewName(info.file.name);
    // setPreviewUrl(URL.createObjectURL(info.file.originFileObj));
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('token') === null ||
      sessionStorage.getItem('user-type') !== 'admin'
    ) {
      navigate('/');
    }

    getEmailTemplateSettings('settings');
  }, [navigate]);

  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>General Settings</h2>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div className="row">
            <div className="col-md-6">
              <div className="">
                <label htmlFor="image" className="form_label">
                  Logo:
                </label>
                <div className="banner_custom_div ">
                  <Upload
                    className={errors.image ? 'border-color-red-error' : ''}
                    name="image"
                    listType="picture"
                    showUploadList={false}
                    onChange={(e) => onFileChange(e)}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  {errors.image && <p className="error">{errors.image}</p>}
                  <div class="periveimage">
                    <div class="preimage">
                      {previewUrl ? (
                        <img
                          src={previewUrl}
                          style={{
                            width: '100px',
                            border: '1px solid #012840',
                          }}
                        ></img>
                      ) : savedImageName != undefined ? (
                        <img
                          src={'/logo_image/' + savedImageName}
                          style={{
                            width: '100px',
                            border: '1px solid #012840',
                          }}
                        ></img>
                      ) : (
                        ''
                      )}
                    </div>
                    <div class="imagename">{previewName}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 cancel_btn">
              <Link className="btn_cs sky_border_btn" to="/home">
                Cancel
              </Link>
              <div className="add_qus_submit d-flex gap-3 justify-content-end">
                <button
                  type="submit"
                  className="btn_cs sky_bg_btn"
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettings;
