import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import logo from './../ia-logo-white.png';
import user_pic from './../user.png';

import axios from 'axios';

const Navbar = () => {
  let currentPackage = 0;
  let show_hide = 'test';
  let navigator = useNavigate();
  let location = useLocation();
  const [logoUrl, setLogoUrl] = useState(null);
  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user-type');
    navigator('/login');
  };

  const fetchLogoUrl = async (id) => {
    try {
      const response = await axios.get(`/api/settings/settingsdata/${id}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'auth-token': sessionStorage.getItem('token'),
        },
      });

      const { settings } = response.data;
      let imageUrl = settings.data.image;

      // Normalize the path to handle both backslashes and forward slashes
      const normalizedPath = imageUrl.replace(/\\/g, '/');

      // Extract the image name from the normalized path
      const nameT = normalizedPath.substring(
        normalizedPath.lastIndexOf('/') + 1
      );
      setLogoUrl(`/logo_image/${nameT}`);
    } catch (error) {
      console.error('Error fetching logo URL:', error);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('token') === null) {
      navigator('/login');
      show_hide = 'custom_hide';
    }
    fetchLogoUrl('settings');
  }, []);

  currentPackage = parseInt(sessionStorage.getItem('adminPlanId'));
  if (sessionStorage.getItem('token') == null) {
    show_hide = 'custom_hide';
  }

  return (
    <>
      <header
        className={`sp-sticky main_header_al make-small ${show_hide}`}
        id="demo-header"
      >
        <div className="top_navBar">
          <div className="container-fluid">
            <div className="top_navBar_flex">
              <div className="brandLogo">
                {sessionStorage.getItem('user-type') == 'user' ? (
                  <Link className="navbar-brand" to="/student-dashboard">
                    <img className="logoc" src={logoUrl} alt="logo" />
                  </Link>
                ) : (
                  <Link className="navbar-brand p-0" to="/home">
                    <img className="logoc" src={logoUrl} alt="logo" />
                  </Link>
                )}
              </div>
              <div className="profile_settings">
                <div className="header_settings">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="bx bx-cog"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="header_user dropdown">
                  <div
                    className="header_user_info"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>{sessionStorage.getItem('user-name')}</span>
                    <img
                      src={user_pic}
                      alt={sessionStorage.getItem('user-name')}
                    />
                  </div>
                  <div className="dropdown-menu">
                    <ul>
                      <li className="showUserInfo">
                        <div className="d-flex align-items-center showUserInfo_drop">
                          <figure className="user-img mb-0 me-3">
                            <img
                              src={user_pic}
                              alt={sessionStorage.getItem('user-name')}
                            />
                          </figure>
                          <figcaption>
                            <span>{sessionStorage.getItem('user-name')}</span>
                            <span>{sessionStorage.getItem('user-email')}</span>
                          </figcaption>
                        </div>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <Link
                          to="/admin-profile-edit"
                          className="dropdown-item"
                        >
                          My Profile
                        </Link>
                      </li>
                      <li>
                        {' '}
                        <Link
                          className="dropdown-item"
                          to="/admin_change_password"
                        >
                          Change Password
                        </Link>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        <a className="dropdown-item" onClick={handleLogout}>
                          Log Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="main_header navbar navbar-expand-lg navbar-dark bg-white">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {sessionStorage.getItem('token') ? (
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown active">
                    <Link
                      className={`nav-link dropdown-toggle ${
                        location.pathname === '/' ? 'active' : ''
                      }`}
                      aria-current="page"
                      to="/home"
                      id="homeDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Questions
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="homeDropdown"
                    >
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === '/home' ? 'active' : ''
                          }`}
                          aria-current="page"
                          to="/home"
                        >
                          Questions List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === '/addquestions'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/addquestions"
                        >
                          Add Questions
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      id="ExmasDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Exams
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="ExmasDropdown"
                    >
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === '/exams-list' ? 'active' : ''
                          }`}
                          aria-current="page"
                          to="/exams-list"
                        >
                          Exams List
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === '/addexamtype' ? 'active' : ''
                          }`}
                          aria-current="page"
                          to="/addexamtype"
                        >
                          Add Exam
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        location.pathname === '/categories-list' ? 'active' : ''
                      }`}
                      aria-current="page"
                      to="/categories-list"
                    >
                      Categories
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      id="ResultDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Results
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="ResultDropdown"
                    >
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === ' /users-result-list'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/users-result-list"
                        >
                          User Report
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === ' /userwiseallexamreport'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/userwiseallexamreport"
                        >
                          UserWise- AllExamsReport
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === ' /userexamdetailedreport'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/userexamdetailedreport"
                        >
                          User Exam Detailed Report
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === ' /examwiseresult'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/examwiseresult"
                        >
                          Exam Report
                        </Link>
                      </li>

                      {currentPackage === 3 ? (
                        <li>
                          <Link
                            className={`dropdown-item ${
                              location.pathname === ' /incomplete_exams'
                                ? 'active'
                                : ''
                            }`}
                            aria-current="page"
                            to="/incomplete_exams"
                          >
                            Incomplete exams
                          </Link>
                        </li>
                      ) : (
                        ''
                      )}
                      {/* <li>
                    <Link
                      className={`dropdown-item ${location.pathname === " /allexams" ? "active" : ""
                        }`}

                      aria-current="page"

                      to="/allexams"

                    >All Exam Report
                    </Link>
                  </li> */}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        location.pathname === ' /userslist' ? 'active' : ''
                      }`}
                      aria-current="page"
                      to="/userslist"
                    >
                      Users
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      id="ResultDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Settings
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="SettingDropdown"
                    >
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === ' /generalsettings'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/generalsettings"
                        >
                          General Settings
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === ' /resultemailtemplatesetting'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/resultemailtemplatesetting"
                        >
                          Result Email Template
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname ===
                            ' /useremailloginotptemplatesetting'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/useremailloginotptemplatesetting"
                        >
                          User Login otp email Template
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`dropdown-item ${
                            location.pathname === ' /resultemailtemplate'
                              ? 'active'
                              : ''
                          }`}
                          aria-current="page"
                          to="/smtp_settings"
                        >
                          SMTP Settings
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                ''
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Navbar;
