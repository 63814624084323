import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import axios from 'axios';
import { Divider, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const UserEmailLoginOtpTemplate = (props) => {
  let navigate = useNavigate();
  const [Settings, setSettings] = useState({});
  const [Content, setContent] = useState();
  const [savedImageName, setSavedImageName] = useState();
  const [ContentView, setContentView] = useState(() =>
    EditorState.createEmpty()
  );
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewName, setPreviewName] = useState(null);

  const [imageLogo, setImageLogo] = useState(null);
  const [previewUrlLogo, setPreviewUrlLogo] = useState(null);
  const [previewNameLogo, setPreviewNameLogo] = useState(null);
  const [savedImageNameLogo, setSavedImageNameLogo] = useState();
  const getEmailTemplateSettings = async (id) => {
    try {
      const response = await axios.get(
        `/api/emailsettings/emailtemplatesettingsdata/${id}`,
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'auth-token': sessionStorage.getItem('token'),
          },
        }
      );

      const { settings } = response.data;
      setSettings(settings.data);
      let imageUrl = settings.data.image;

      // Normalize the path to handle both backslashes and forward slashes
      const normalizedPath = imageUrl.replace(/\\/g, '/');

      // Extract the image name from the normalized path
      const nameT = normalizedPath.substring(
        normalizedPath.lastIndexOf('/') + 1
      );
      setSavedImageName(nameT);
      setSavedImageNameLogo(settings.data.imageLogo);
      //console.log(settings.data);
      const htmlContent = settings.data.body;
      const contentBlock = htmlToDraft(htmlContent);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setContent(settings.data.body);
      setContentView(EditorState.createWithContent(contentState));
    } catch (error) {
      console.error('Error fetching email template settings:', error);
    }
  };

  const editEmailTemplateSettings = async (
    id,
    subject,
    body,
    note,
    link,
    heading,
    image,
    imageLogo,
    x_position,
    y_position
  ) => {
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('note', note);
    formData.append('link', link);
    formData.append('heading', heading);
    formData.append('x_position', x_position);
    formData.append('y_position', y_position);
    if (image) {
      formData.append('image', image);
    }
    if (image) {
      formData.append('imageLogo', imageLogo);
    }

    axios
      .put('/api/emailsettings/emailtemplateupdate/' + id, formData)
      .then((res) => {
        if (res.data.success === true) {
          props.showAlert(res.data.message, 'success');
        } else {
          props.showAlert(res.data.message, 'danger');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditorChange = (content) => {
    setContentView(content);
    let check_content = convertToHTML(content.getCurrentContent());
    setContent(check_content);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!Settings.subject) newErrors.subject = 'Subject is required';
    if (!Settings.heading) newErrors.heading = 'Heading is required';
    if (!Settings.link) newErrors.link = 'Link is required';
    if (!Settings.note) newErrors.note = 'Note is required';
    if (!Content) newErrors.body = 'Body content is required';
    if (!image) newErrors.image = 'Image is required';
    if (!Settings.x_position) newErrors.x_position = 'X Position is required';
    if (!Settings.y_position) newErrors.y_position = 'Y Position is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = (e) => {
    console.log(Content);
    if (!validateForm()) {
      props.showAlert('Please fill in all fields', 'danger');
      return;
    }

    editEmailTemplateSettings(
      'admin-result-template',
      Settings.subject,
      Content,
      Settings.note,
      Settings.link,
      Settings.heading,
      image,
      imageLogo,
      Settings.x_position,
      Settings.y_position
    );
  };

  const onChange = (e) => {
    setSettings({ ...Settings, [e.target.name]: e.target.value });
  };

  const onFileChange = (info) => {
    setImage(info.file.originFileObj);
    setPreviewName(info.file.name);
    setPreviewUrl(URL.createObjectURL(info.file.originFileObj));
  };
  const onFileChangeLogo = (info) => {
    setImageLogo(info.file.originFileObj);
    setPreviewNameLogo(info.file.name);
    setPreviewUrlLogo(URL.createObjectURL(info.file.originFileObj));
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('token') === null ||
      sessionStorage.getItem('user-type') !== 'admin'
    ) {
      navigate('/');
    }

    getEmailTemplateSettings('admin-result-template');
  }, [navigate]);

  return (
    <>
      <div className="board_title_data d-flex align-items-center justify-content-between gap-3">
        <div className="board_title d-flex align-items-center gap-3">
          <h2>Result Email Template</h2>
        </div>
      </div>
      <div className="main_wraper_pages mb-5">
        <div className="data_wraper_pages">
          <div className="row">
            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="subject" className="form_label">
                  Subject:
                </label>
                <input
                  className={errors.subject ? 'border-color-red-error' : ''}
                  type="text"
                  name="subject"
                  onChange={onChange}
                  value={Settings.subject || ''}
                  required
                  placeholder="Subject"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="heading" className="form_label">
                  Marksheet Heading/Institute Name:
                </label>
                <input
                  className={errors.heading ? 'border-color-red-error' : ''}
                  type="text"
                  name="heading"
                  onChange={onChange}
                  value={Settings.heading || ''}
                  required
                  placeholder="Marksheet Heading/Institute Name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="link" className="form_label">
                  Website Link:
                </label>
                <input
                  className={errors.link ? 'border-color-red-error' : ''}
                  type="text"
                  name="link"
                  onChange={onChange}
                  value={Settings.link || ''}
                  required
                  placeholder="Website Link"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="note" className="form_label">
                  Note:
                </label>
                <textarea
                  className={errors.note ? 'border-color-red-error' : ''}
                  name="note"
                  onChange={onChange}
                  value={Settings.note || ''}
                  required
                  placeholder="Note"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="body" className="form_label">
                  Body:
                </label>
                <Editor
                  className={errors.body ? 'border-color-red-error' : ''}
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                  editorState={ContentView}
                  onEditorStateChange={handleEditorChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <label htmlFor="image" className="form_label">
                  Logo:
                </label>
                <div className="banner_custom_div ">
                  <Upload
                    className={errors.imageLogo ? 'border-color-red-error' : ''}
                    name="imageLogo"
                    listType="picture"
                    showUploadList={false}
                    onChange={(e) => onFileChangeLogo(e)}
                  >
                    <Button icon={<UploadOutlined />}>Upload Logo</Button>
                  </Upload>
                  <div class="periveimage">
                    <div class="preimage">
                      {previewUrlLogo ? (
                        <img
                          src={previewUrlLogo}
                          style={{ width: '100px' }}
                        ></img>
                      ) : savedImageNameLogo ? (
                        <img
                          src={'/certificate_image/' + savedImageNameLogo}
                          style={{ width: '100px' }}
                        ></img>
                      ) : (
                        ''
                      )}
                    </div>
                    <div class="imagename">{previewNameLogo}</div>
                  </div>
                </div>
              </div>
            </div>
            <Divider orientation="left" plain>
              Certificate Configuration
            </Divider>

            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="heading" className="form_label">
                  X Position
                </label>
                <input
                  className={errors.x_position ? 'border-color-red-error' : ''}
                  type="text"
                  name="x_position"
                  onChange={onChange}
                  value={Settings.x_position || ''}
                  required
                  placeholder="X Position"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_fields mb-4">
                <label htmlFor="heading" className="form_label">
                  Y Position
                </label>
                <input
                  className={errors.y_position ? 'border-color-red-error' : ''}
                  type="text"
                  name="y_position"
                  onChange={onChange}
                  value={Settings.y_position || ''}
                  required
                  placeholder="Y Position"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <label htmlFor="image" className="form_label">
                  Certificate:
                </label>
                <div className="banner_custom_div ">
                  <Upload
                    className={errors.image ? 'border-color-red-error' : ''}
                    name="image"
                    listType="picture"
                    showUploadList={false}
                    onChange={(e) => onFileChange(e)}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <div class="periveimage">
                    <div class="preimage">
                      {previewUrl ? (
                        <img src={previewUrl} style={{ width: '100px' }}></img>
                      ) : savedImageName ? (
                        <img
                          src={'/certificate_image/' + savedImageName}
                          style={{ width: '100px' }}
                        ></img>
                      ) : (
                        ''
                      )}
                    </div>
                    <div class="imagename">{previewName}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 cancel_btn">
              <Link className="btn_cs sky_border_btn" to="/home">
                Cancel
              </Link>
              <div className="add_qus_submit d-flex gap-3 justify-content-end">
                <button
                  type="submit"
                  className="btn_cs sky_bg_btn"
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEmailLoginOtpTemplate;
